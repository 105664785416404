import InitLocations from "../services/InitLocations.json";
import { PostData, GetData } from "../services/SendData";
import { initSessionData, setElementVal, clone, VerticallyCenteredModal } from "../services/Helper";
import { useState } from 'react';
import UseFetchCart from "../Hook/useFetchCart";



// https://api.postalpincode.in/postoffice/Gopalpur
// https://api.postalpincode.in/pincode/712513





function SearchLocation(props) {
    //const userData = props.userData;
    //const configData = props.configData;
    const server = props.server;
    const fetchCart = UseFetchCart();
    const [modalShow, setModalShow] = useState(false);
    const [patSelectedData, setpatSelectedData] = useState(InitLocations.Default);
    const [locationData, setLocationData] = useState(InitLocations);
    const [myModal, setMyModal] = useState({ show: false, message: '', fnCallback: null });
    const [userInput, setUserInput] = useState({
        pincode: "",
        validation: {
            isValid: false,
            className: {
                pincode: 'form-control',
            }
        }
    });



    function lnkUpdateLocation(event) {
        event.preventDefault();
        //event.stopPropagation();
        var pincode = event.target.getAttribute("data-pincode");
        var location = event.target.getAttribute("data-location");
        var apiSegment = '/genulabhome/updatepatientpincode';

        //console.log(props.server.base_url, apiSegment);

        PostData(apiSegment, { patient_pincode: pincode, patient_location: location }, props).then((result) => {
            let jResponse = result;
            var objPatientLocation = [{}];
            objPatientLocation[0].id = "elePatientLocation";


            if (jResponse.status) {
                pincode = jResponse.patientpincode;
                location = jResponse.patientlocation;

                //   console.log(pincode,location);

                objPatientLocation[0].html = '<i class="icon-location"></i> ' + location + ' <i class="icon-crosshair left6"></i>';
                objPatientLocation[0].attr = [{}, {}];
                objPatientLocation[0].attr[0].name = 'data-patient_pincode';
                objPatientLocation[0].attr[0].value = pincode;
                objPatientLocation[0].attr[1].name = 'data-patient_location';
                objPatientLocation[0].attr[1].value = location;
                setElementVal(objPatientLocation);

                var objPatSelectedData = { "patient_pincode": pincode, "patient_location": location };
                setpatSelectedData(objPatSelectedData);
                document.getElementById(objPatientLocation[0].id).click(); //toggle

                var objInitData = initSessionData();
                objInitData.popularPackages.isDarty = true;
                window.sessionStorage.setItem('initData', JSON.stringify(objInitData));
                if (document.getElementById('eleBestPackages')) {
                    document.getElementById('eleBestPackages').click();
                }

                if (document.getElementById('btnSearchTestAndPackages')) {
                    document.getElementById('btnSearchTestAndPackages').click();
                }

                if (document.getElementsByClassName('genuinner-search-fetchCart')) {
                    // document.getElementsByClassName('genuinner-search-fetchCart')[0].click();
                    fetchCart();
                }

                if (window.location.href.search("\/cart") !== -1) {
                    window.location.reload(true);
                }


            } else {
                // setModalShow(true);
                // setElementVal([{ id: "VerticallyCenteredModal-message", html: jResponse.message }]);
                setMyModal({ show: true, message: (jResponse.message || 'Service is currently not available in this location'), fnCallback: null });
            }

            // document.getElementById(objPatientLocation[0].id).click(); //toggle



        });

    }



    function searchLocationByPincode(obj) {
        //console.log('obj', obj);
        if (obj.validation.isValid) {
            // var apiSegment = '/' + obj.pincode;
            var apiSegment = '/genulabhome/pincode/' + obj.pincode;



            var configs = clone(props);
            // configs.server.base_url = server.api_pincode;

            //console.log(configs.server.base_url, apiSegment);

            GetData(apiSegment, configs, true).then((result) => {
                let jResponse = result;

                //sessionStorage.setItem('userDataEXXXX',JSON.stringify(jResponse));
                //console.log('jResponse', jResponse);
                if (typeof (jResponse[0]) !== 'undefined' && jResponse[0].Status && jResponse[0].Status === "Success" && jResponse[0].PostOffice.length > 0) {
                    //alert( jResponse[0].PostOffice.length);
                    let objLocation = { TopCities: [], OtherCities: [], SearchResults: jResponse[0].PostOffice }

                    setLocationData(objLocation);

                } else {
                    setLocationData(InitLocations);
                }



            });


        }
    }


    function onChangeUserInput(e) {
        let pass = { pincode: false };
        let pincodeFormat = /^[1-9]\d{5}$/;
        let obj = {
            pincode: "",
            validation: {
                isValid: false,
                className: {
                    pincode: 'form-control',
                }
            }
        };

        obj.pincode = userInput.pincode;
        obj.validation.isValid = userInput.validation.isValid;
        obj.validation.className.pincode = userInput.validation.className.pincode;


        obj[e.target.name] = e.target.value;



        if (obj.pincode && obj.pincode.match(pincodeFormat)) {
            obj.validation.className.pincode = 'form-control is-valid is-valid2';
            pass.pincode = true;
        } else if (obj.pincode === '') {
            obj.validation.className.pincode = 'form-control';
            pass.pincode = false;
            setLocationData(InitLocations);
        } else {
            obj.validation.className.pincode = 'form-control is-invalid';
            pass.pincode = false;
        }



        obj.validation.isValid = pass.pincode; // (pass.mobile && pass.pwd);
        setUserInput(obj);
        //console.log(obj, userInput);
        if (obj.validation.isValid) {
            document.getElementById('txtPincode').blur();
            //console.log('2', obj, userInput);

            window.setTimeout(function () { searchLocationByPincode(obj); }, 100);
        }

        //return false;
    }


    function getCities(data, locSelected) {
        var lnkId = 0;
        var selected = typeof (locSelected) === 'undefined' ? { patient_pincode: '', patient_location: 'Not Selected' } : locSelected;
        const filteredData = data.filter(item => item.DeliveryStatus === "Delivery");

        // <i className="icon-location"></i> Kolkata <i className="icon-crosshair left6"></i>
        /*  
        if(selected.patient_pincode.length===6){
                    document.getElementById('elePatientLocation').setAttribute('data-patient_location', selected.patient_pincode);
                    document.getElementById('elePatientLocation').setAttribute('data-patient_location', selected.patient_pincode);
                    document.getElementById('elePatientLocation').innerHTML='<i className="icon-location"></i> '+selected.patient_location+' <i className="icon-crosshair left6"></i>';
                }
        */

        return (
            <ul className="user-lan-list cities">
                {

                    filteredData.length > 0 && filteredData.map(item => {
                        lnkId++;

                        let dist = "(" + item.District + ")";
                        let nm = item.Name.replace(dist, '');
                        nm += nm !== item.District ? " " + dist : '';

                        //let link = "?pincode=" + item.Pincode + "&amps;city=" + nm;
                        let clsNmLnk = "lnkUpdateLocation lnk" + lnkId;
                        let clsNmLi = selected.patient_location === nm ? ' active' : '';
                        return (
                            <li key={item.Pincode + '_' + lnkId} className={clsNmLi} >
                                <a href={server.base_url}
                                    data-location={nm} data-pincode={item.Pincode}
                                    onClick={lnkUpdateLocation} className={clsNmLnk} >{nm}
                                </a>
                            </li>
                        )

                    }
                    )}
            </ul>
        );
    }




    return (
        <div>
            <div className="language-header">
                <div className="language-heading">FIND YOUR LOCATION</div>
                <div className="input-group searchlocation">
                    <input id="txtPincode" name="pincode" onChange={onChangeUserInput} type="text" pattern="[0-9]*" className={userInput.validation.className.pincode} placeholder="Enter  Pincode" maxLength="6" />
                    <button className="btn btn-location" type="button" value="Search"><i className="icon-focus-lens"></i></button>
                </div>
            </div>

            <div className="overflow-block">
                <div className="cities-block-scroll">

                    {false && locationData.TopCities.length > 0 ? <div className="cities-list">TOP CITIES</div> : ''}

                    {false && locationData.TopCities.length > 0 ? getCities(locationData.TopCities, patSelectedData) : ''}


                    {false && locationData.OtherCities.length > 0 ? <div className="cities-list">OTHER CITIES</div> : ''}
                    {false && locationData.OtherCities.length > 0 ? getCities(locationData.OtherCities, patSelectedData) : ''}

                    {locationData.SearchResults.length > 0 ? getCities(locationData.SearchResults, patSelectedData) :
                        <div className="text-center h6"><small>Pincode for Sample Collection</small></div>
                    }

                </div>
            </div>

            <VerticallyCenteredModal
                show={myModal.show}
                message={myModal.message}
                onHide={() => {
                    if (typeof (myModal.fnCallback) === 'function') {
                        myModal.fnCallback();
                    } else {
                        setMyModal({ show: false, message: '', fnCallback: null });
                    }
                }
                }
            />
        </div>

    )
}

export default SearchLocation;
