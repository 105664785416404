import './../assets/scss/Other.scss';
import ProfileMenu from './../site/ProfileMenu';
import { Container } from "react-bootstrap";
import { NavLink } from "react-router-dom";

function NotificationDetails() {
  return (
    <div className="MyProfile">
    <section className="breadcrumb-section">
    <Container>
    <div className="breadcrumb-section-inner">
    <nav aria-label="breadcrumb" className="breadcrumb-nav">
    <ol className="breadcrumb mt-0">
    <li className="breadcrumb-item"><NavLink to="/"><i className="icon-home1" /> Home</NavLink></li>
    <li className="breadcrumb-item"><NavLink to="">Notifications</NavLink></li>
    </ol>
    </nav>
    </div>
    </Container>
    </section>
    <Container>
    <ProfileMenu/>

    <div className="notification-tab-block">
    <div className="left-panel">
     <div className="notification-back-next">
    <NavLink to="/notifications" className="backto"><i className="icon-left-arrow"></i> Back to Notifications</NavLink>
     <div className="previous-next">
         <div className="pn-btn"><i className="icon-left-arrow"></i> Previous Message</div>
         <div className="pn-btn">Next Message <i className="icon-right-arrow"></i></div>
     </div>
     </div>

     <div className="notification-details">
     <div className="patient-name"> <span className="firsttitle">To:</span>Mr. Joydeep Chakraborthy,<small>Male, 77 years old</small></div>
     <div className="clr height20"/>
     <div className="firsttitle">Today, 9:30 AM</div>
     <div className="notifi-sub"><span className="firsttitle">Subject:</span> <b className="text-blue">GENU Order PO070937693 is placed</b> <i className="icon-dastbin-close close-notifi"></i> </div>
     <div className="clr height10"/>
    <p>Order Placed: We are pleased to inform you that your GENU Path Labs Order PO07122562966217 is placed successfully. You'll be notified
    through a SMS and your email ID about the confirmation of this Order Placement. Your order details are mentioned below. If you find any
    discrepancy or want to modify your order you can cancel your booking and place another order or you can call to your HelpDesk
    representative at 080-6950-6950</p>
    <p>Order No: <b>PO070937693</b><br/>
    Total Amount: <b>₹ 2300</b></p>
    <div className="firsttitle">Tests Ordered:</div>
    <p><b>Lipid Profile Test, Haemoglobin Complete, Liver Function Test, Thyroid Profile & Kidney Function Test.</b></p>
    <p>Our Phlebotomist will contact you on your registered mobile number to find your location and reach you with convenience. The Phone
    number of the phlebotomist will be shared with you over an SMS. You can call him to render an appropriate time or any modification to the
    schedule of collection of the blood.</p>
    <p>Regards<br/>GENU Team.</p>
     </div>



    <div className="clr height20"/>
    <div className="notification-footer">

<a href={'#'} className="btn pn-btn">Prev</a>
<a href={'#'} className="btn pn-btn">Next</a>
<div className="clr height20"/>

<NavLink to="/notifications" className="backto"><i className="icon-left-arrow"></i> Back to Notifications</NavLink>

<div className="clr height20"/>
<p>All notifications and SMS are generated based on the details that are resulted from the GENU test reports and the necessary details of the customer that
you have shared with us. All such communications are solely established in a timely manner to keep you aware or to notify you for any test event that is
requested or a future test event GENU Path labs considers important for you.</p>
   </div>
      </div>
      <div className="right-panel">
        <div className="articles-block">
          <h3 className="article-heading">ARTICLES</h3>
          <ul className="article-list">
            <li><a href={'#'}><img src="/images/articles/article-01.jpg" alt="" /></a></li>
            <li><a href={'#'}><img src="/images/articles/article-02.jpg" alt="" /></a></li>
            <li><a href={'#'}><img src="/images/articles/article-03.jpg" alt="" /></a></li>
            <li><a href={'#'}><img src="/images/articles/article-04.jpg" alt="" /></a></li>
          </ul>
          <a href={'#'} className="showmore">Show More</a>
        </div>

      </div>
    </div>
    </Container>
    
        </div>
  )
}

export default NotificationDetails