import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { settings2 } from "../HomeConstants";
import { getNewPermalink } from "../../../services/Helper";
import useFetch from "../../../Hook/useFetch";
import { HomePageApiUrl } from "../HomeApiConfig";
import SectionTitle from "./SectionTitle";

function HomePopularTests({ server, subtitle, title, viewAllLink }) {
  const { popularTest } = HomePageApiUrl;
  const { data: { data: popularTests } = {} } = useFetch(popularTest);

  return (
    <Container>
      <SectionTitle subtitle={subtitle} title={title} viewAllLink={viewAllLink} />
      <Slider {...settings2} className="slick-arrows-style categories">
        {popularTests?.length > 0
          ? popularTests?.map((item) => (
              <div key={item.ServiceId} className="item">
                <div className="carouselblock">
                  <div className="blockstyle">
                    <Link to={getNewPermalink(item, true, "self")}>
                      <img src={item.icon250ImageUrl} alt={item.ServiceName} />
                    </Link>
                  </div>
                  <p>
                    <Link to={getNewPermalink(item, true, "self")} className="smallLink">
                      {item.ServiceName}
                    </Link>
                  </p>
                </div>
              </div>
            ))
          : [...Array(9)].map((item, i) => <LoadingBlock key={i} server={server} />)}
      </Slider>
    </Container>
  );
}

export default HomePopularTests;

const LoadingBlock = ({ server }) => {
  return (
    <div className="item fuzzy">
      <div className="carouselblock">
        <div className="blockstyle">
          <img
            width="250"
            src={server.cdn_url.replace("live/customer_V2", "images/test_n_packages") + "/icon250x250/Tsh.png"}
            alt="Abo Group & RH Type, Edta Whole Blood"
          />
        </div>
        <p>Abo Group &amp; RH Type, Edta Whole Blood</p>
      </div>
    </div>
  );
};
