import { NavLink } from "react-router-dom";
function PageNotFound() {
    return (
      <>
      <section className="container">
        <div className="PageNotFound">
          <h2 className="e-heading">OOPS !!!</h2>
          <div className="clr height20" />
          <p className="t-off">Error Code: 404</p>
          <h2 className="t-off">We cannot seem to find the <br/> page you are looking for.</h2>
          <div className="clr height30" />
          <p className="t-make">Make sure you typed in the page address correctly or go back to the Previous page</p>
          <div className="clr height30" />
          <NavLink to="/" className="btn btn-return">RETURN TO HOME PAGE</NavLink>
        </div>
      </section>
      </>
    );
  }
  
export default PageNotFound;