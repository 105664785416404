import { GetData } from '../services/SendData';
import { useState } from 'react';


export function WhyGenu(props) {

    var objInitBookCountData = {}; //clone(initData);


    let jsinitBookCounts = window.sessionStorage.getItem('initBookCounts');
    if (jsinitBookCounts) {
        //console.log('App.session jsInitData', jsInitData);
        objInitBookCountData = JSON.parse(jsinitBookCounts);

        // setUserData( JSON.parse(jsUserData) );
    } else {
        objInitBookCountData = {
            LabTestBooked: '1,20,000',
            SatisfiedCustomer: '28,000+',
            Locations: '100+',
            Offers: '100+',
            TestCount: '1500+',
            isUpdated: false
        }
    }



    const [bookingCounts, setBookingCounts] = useState(objInitBookCountData);


    function getBookingCounts() {

        var apiSegment = '/genulabhome/customerBookingCounts';
        GetData(apiSegment, props).then((result) => {
            let jResponse = result;
            jResponse.data.isUpdated = true;
            window.sessionStorage.setItem('initBookCounts', JSON.stringify(jResponse.data));
            setBookingCounts(jResponse.data);
        });
        return false;
    }

    // ------------- call to load initial data : start --------------
    if (!bookingCounts.isUpdated) {
        getBookingCounts(); // call it now
    }
    // ------------- call to load inital data : end ---------------

    return (

        <div className="why-choose Why-Choose-GENU-Pathlab">
            <h1 className="bloodtest text-center" >Book Online Blood Test</h1>
            <h2 className="heading text-center">Why Choose <span className="underline">GENU Path Labs?</span></h2>
            <ul className="choose-list">
                <li>
                    <i className="icon-nabl" />
                    <div className="txtline">NABL  Accreditation</div>
                </li>
                <li>
                    <i className="icon-crosshair" />
                    <div className="txtline">Accurate Report</div>
                </li>
                <li>
                    <i className="icon-best-offers" />
                    <div className="txtline">Best Offers &amp; Affordable Prices</div>
                </li>
                {/* <li>
                    <i className="icon-free-doctors" />
                    <div className="txtline">Free Doctor's Consultation</div>
                </li> */}
                <li>
                    <i className="icon-online-report" />
                    <div className="txtline">Online Reports on all Devices</div>
                </li>
            </ul>
            <div className="clr" />
            <div className="cus-lab">{bookingCounts.SatisfiedCustomer} Satisfied Customers <span className="mline">|</span> {bookingCounts.LabTestBooked} Lab Tests Booked</div>
            <div className="clr" />
            <ul className="tab-off-location">
                <li>{bookingCounts.TestCount} Tests</li>
                <li>{bookingCounts.Offers} Offers</li>
                <li>{bookingCounts.Locations} Locations</li>
            </ul>
        </div>

    )
}