import React from 'react'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'

function HomeRadiologyTestBanner({server}) {
  return (
    <Container>
    <div className="clr height20" />
    <Link to="radiology-test"  ><img src={server.banner_url + "/homepage-radiology-banner-080523-1920x338.jpg"} alt="" className="img-fluid rounded-4" /></Link>
  </Container>
  )
}

export default HomeRadiologyTestBanner;