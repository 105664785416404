import { NavLink, useLocation } from "react-router-dom";
import { scroller } from "react-scroll";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useEffect } from "react";
import { useState } from "react";




function SeoContent() {
    const { pathname } = useLocation();
    const [seoInfo, setSeoInfo] = useState({ pageTitle: null, metaDescription: null });

    const capatilize = (s) => s.charAt(0).toUpperCase() + s.slice(1);
    const pageName = () => {
        var pn = "";
        var pathnames = pathname
            .replace(/^\//, "")
            .replace("-", " ")
            .replace("_", " ")
            .replace("/", " - ")
            .split(" ");
        pathnames.map((name) => {
            pn += (pn && pn !== "" ? " " : "") + capatilize(name);
        });

        return pn;
    };

    const getSeoInfo = () => {
        let seo = { pageTitle: null, metaDescription: null };
        // console.log('pathname 2 :',pathname.replace(/(^\/|\/$)/, '').toLowerCase())
        switch (pathname.replace(/(^\/|\/$)/, '').toLowerCase()) {
            case 'diagnostic-test':
                seo.pageTitle = 'Book diagnostic test at Genu Path Labs'
                seo.metaDescription = 'Book your diagnostic test at home from Genu Path Labs. Diagnostic tests for dengue in addition to many other diagnoses are done here at a reasonable price.'
                break;
            case 'health-packages':
                seo.pageTitle = 'Book Health Checkup Packages at Genu Path Labs';
                seo.metaDescription = 'Genu Path Labs provides different health checkup packages at highly affordable prices for people of all age groups with a home collection facility.';
            default:
                seo.pageTitle = `${pageName()} | Genu Path Labs`;
                seo.metaDescription = `${pageName()}  - Genu Path Labs Ltd. is a SastaSundar Group initiative to provide high quality Diagnostic Services at affordable cost.`;
                break;
        }
        setSeoInfo(seo);
    }


    // console.log('SeoInfo:',seoInfo)

    useEffect(() => {
        getSeoInfo();
    }, [pathname])

    return (
        <Helmet>
            <meta charSet="utf-8" />
            {seoInfo.metaDescription && <meta
                name="description"
                content={seoInfo.metaDescription}
            ></meta>}
            <meta property="og:image" data-rh="true" content={'https://res.genupathlabs.com/genu_path_lab/live/customer_V2/images/genu-logo.png'} />
            <meta name="twitter:card" data-rh="true" content={'https://res.genupathlabs.com/genu_path_lab/live/customer_V2/images/genu-logo.png'} />
            {seoInfo.pageTitle && <title>{seoInfo.pageTitle}</title>}
            <link rel="canonical" href={"https://www.genupathlabs.com" + (pathname !== "" && pathname !== "/" ? pathname : "")}
            />
        </Helmet>
    );
}

export default SeoContent;
