import React, { useState, useContext } from 'react';
import { Container, Accordion } from "react-bootstrap";
import { Link, NavLink, Navigate } from "react-router-dom";
import BreadCrumb from './BreadCrumb';
import './../assets/scss/catalouge.scss';
import TestAndPackageHead from "../sections/TestAndPackages"
import InitSearchTest from "../services/InitSearchTest.json";
import { getConfigData, clone, IsThisItemSelected, countCartItems, getNewPermalink } from "../services/Helper";
import { cartContext } from '../services/userHandler';
import { GetData, PostData } from '../services/SendData';

var initData = {
    TopOffers: {
        tests: { items: [], totalCount: 0, totalPages: 0, pageSize: 10, currentPage: 1, start: 0, end: 0 },
        packges: { items: [], totalCount: 0, totalPages: 0, pageSize: 10, currentPage: 1, start: 0, end: 0 },
        isDarty: true
    }

}
const configData = getConfigData();
var queryStarted = {
    getTopOffers: { list: false, timeout: null },
    initCall: true
}

function TopOffers() {

    const isPermalinkNew = (document.getElementById('commonField') && document.getElementById('commonField').getAttribute('permalink_new') === '1') || false;
    const [myModal, setMyModal] = useState({ show: false, message: '' });
    const { cartState, cartDispatch } = useContext(cartContext);
    const [topOffers, setTopOffers] = useState(initData.TopOffers);
    const [userHandler, setUserHandler] = useState({
        // ------------ defalut inputs ------
        inputs: {
            pageNumber: 1,
            heatltCondition: ''
        },

        showList: false,
        // ----------------- data -----------
        data: {},
        // ------------ clases ------------
        cls: {
            div: '',

        },
        // ------------ enable / disable controls ------------
        disabled: {
            btnNext: true,
            pagination: false,
        },
        messages: [], // [{successed:true,text:''}]
        // --------------------- manage event -------------------
        targetName: null,
        showTopOffers: ((document.getElementById('commonField') && document.getElementById('commonField').getAttribute('show_topoffers') === '1') || false)
    });

    const handelHealthCondition = (event) => {
        //    console.log('Top-offers' ,'handelHealthCondition', event.selectedHC);
        let healthCodition = [];
        if (event.selectedHC && event.selectedHC.length > 0) {
            event.selectedHC.map(item =>
                healthCodition.push(item.hcname)
            );
        }
        //healthCoditions
        getTopOffers({ force: true, showLoder: true, pageNumber: 1, healthCodition: healthCodition });
    }


    const handelCustomerLocation = (event) => {
        setMyModal({ show: false, message: '' });
        document.getElementById("elePatientLocation").click();
    }


    function getTopOffers(param) {


        //fnFeedBack, showLoder, force
        // ------------ initialization ------------
        param = typeof (param) !== 'undefined' ? param : {};
        param.showLoder = typeof (param.showLoder) !== 'undefined' ? param.showLoder : false;
        param.force = typeof (param.force) !== 'undefined' ? param.force : false;
        param.fnFeedBack = typeof (param.fnFeedBack) !== 'undefined' ? param.fnFeedBack : null;
        if (!topOffers.isDarty && !param.force) {
            return false;
        }
        //  listBooking, setListBooking
        // ------------ haldeling force &&  ------------
        if (queryStarted.getTopOffers.timeout) {
            window.clearTimeout(queryStarted.getTopOffers.timeout);
        }

        if (queryStarted.getTopOffers.list) {
            if (param.force) {
                queryStarted.getTopOffers.timeout = window.setTimeout(function () {
                    getTopOffers(param);
                }, 2000);
            }
            return false;
        }


        let objUH = clone(userHandler);
        objUH.targetName = null;
        objUH.disabled.pagination = true;
        //objUH.inputs.bookingNo = '';
        //objUH.inputs.bookingData = bookingData;
        setUserHandler(objUH);

        var apiSegment = '/offers/topOfferList';
        //apiSegment += '?page=' + param.pageNumber;
        //apiSegment += (param.healthCodition.length > 0 ? '&healthCodition=' + param.healthCodition.join('|') : '');
        queryStarted.getTopOffers.list = true;
        GetData(apiSegment, configData, param.showLoder).then((result) => {
            var objOffers = clone(topOffers);
            objOffers = clone(result);
            objOffers.isDarty = false;
            objOffers.altMessage = objOffers.isAvailable ? '' : 'No Offers is available in your area';
            setTopOffers(objOffers);

            if (typeof (param.fnFeedBack) === 'function') {
                param.fnFeedBack();
            }

            window.setTimeout(function () {
                queryStarted.getTopOffers.list = false;
                let objUH = clone(userHandler);
                objUH.showTopOffers = true; // typeof (objOffers.showTopOffers) !== 'undefined' ? objOffers.showTopOffers : false;
                objUH.disabled.pagination = false;
                setUserHandler(objUH);
            }, 1000);

        });
        return false;
    }



    function lnkBookATest(event) {
        event.preventDefault();
        //event.stopPropagation();
        var apiSegment = null;
        var arrHref = [];
        var actionType = null;
        if (event.target.getAttribute("href").search('addToCart') !== -1) {
            actionType = 'addToCart';
            arrHref = event.target.getAttribute("href").split('addToCart/');
            apiSegment = '/loadData/submitPage/addToCart';
        } else if (event.target.getAttribute("href").search('removeFromCart') !== -1) {
            actionType = 'removeFromCart';
            arrHref = event.target.getAttribute("href").split('removeFromCart/');
            apiSegment = '/loadData/submitPage/delCart';
        } else {
            alert("Error");
            return false;
        }
        // var location = event.target.getAttribute("data-location");
        //console.log('Top-offers' ,'arrHref',arrHref);
        var dataHref = arrHref[1].split('/');
        let LabId = parseInt(dataHref[0]);
        let ServiceId = parseInt(dataHref[1]);
        let Fees = parseFloat(dataHref[2]);
        //let VisitType = dataHref[3];
        //let ndxItem = dataHref[4];
        //let pktItem = typeof (dataHref[5]) !== 'undefined' ? dataHref[5] : 'Item';
        //console.log('Top-offers' ,'LabId:', LabId, 'ServiceId:', ServiceId, 'VisitType:', VisitType, 'ndxItem', ndxItem);
        if (ServiceId === 0) {
            return false;
        }

        // checking patient pincode : data-patient_pincode
        // let patient_pincode = document.getElementById('elePatientLocation').getAttribute('data-patient_pincode');
        // if (typeof (patient_pincode) === 'undefined' || !patient_pincode || patient_pincode === '') {
        //     setMyModal({ show: true, message: 'Please select your location for Sample Collection', fnCallBack: function () { handelCustomerLocation(); } });
        //     return false;
        // }

        //POST >> http://localhost:8081/loadData/submitPage/addToCart >> {senddata: {ServiceId: 5,
        // Fees: 600, LabId: "2"}}

        //var apiSegment = '/loadData/submitPage/addToCart';

        PostData(apiSegment, {
            senddata: {
                LabId: LabId,
                ServiceId: ServiceId,
                Fees: Fees
            }
        }, configData)
            .then((result) => {
                let jResponse = (actionType === 'addToCart') ? result.response : result;
                //console.log('Top-offers' ,actionType, jResponse);
                if (jResponse.status && jResponse.status === 1) {
                    let resData = (actionType === 'removeFromCart') ? jResponse.data.data : jResponse.data;
                    // -- update CartData (show selected ) and update cart-count 
                    var objCartList = typeof (resData.Cart[0]) !== 'undefined' ? clone(resData.Cart[0]) : InitSearchTest.CartList;
                    if (resData.CartItem && resData.CartItem.length > 0) {
                        objCartList.CartItem = clone(resData.CartItem);
                        objCartList.TotalCartItems = countCartItems(resData.CartItem); // resData.CartItem.length;
                    }
                    //console.log('Top-offers' ,actionType, objCartList);
                    cartDispatch({ type: 'CART', payload: objCartList });
                } else if (jResponse.message) {
                    setMyModal({ show: true, message: jResponse.message });
                    if (jResponse.status === -5) {
                        window.setTimeout(function () {
                            window.location.reload(true);
                        }, 5000)
                    }
                }
                return true;
            });
    }



    function callOnInitialization(force) {

        //alert(userHandler.showTopOffers);


        force = typeof (force) !== 'undefined' ? force : false;
        queryStarted.initCall = false;
        window.setTimeout(function () {
            //console.log('Top-offers', 'callOnInitialization', queryStarted, { force: force, showLoder: true });
            getTopOffers({ force: force, showLoder: true });
        }, 500);
    }


    // -------------------- INITIALIZATION -------------
    //console.log('Top-offers', queryStarted);
    if (!userHandler.showTopOffers) {
        return (<Navigate from={'/top-offers'} to='/health-packages' />);
    }

    callOnInitialization(queryStarted.initCall);


    return (
        <>
            <section className="breadcrumb-section">
                <Container>
                    <BreadCrumb />
                </Container>
            </section>

            <Container>
                <TestAndPackageHead listType="top-offers" showTopOffers={userHandler.showTopOffers} onChangeHealthCondition={handelHealthCondition} />
            </Container>
            <div className='minheight400'>
                {topOffers.limitedOffers && topOffers.limitedOffers.items && topOffers.limitedOffers.items.length > 0 ? <>
                    <Container className='offer-block'>
                        <div className='height10 clr' />
                        <h4 className='heading-offer'>{topOffers.limitedOffers.OfferHead}</h4>
                        <Accordion defaultActiveKey="0" className='accordion-style2'>
                            {/*         
                                <Accordion.Item eventKey="0"><a href="{'#'}" className='btn viewall'>View all Tests</a>
                                <Accordion.Header><i className='icon-top-offers'/> 31% OFF on Tests Booking

                                </Accordion.Header>
                                <Accordion.Body>
                                <Row>
                                <div className='col-lg-8 mt-1'>Showing <b>16 Offers</b></div>
                                <div className='col-lg-4'>
                                <div className="input-group mb-3 search-style">
                                <input type="text" className="form-control" placeholder="Search" aria-label="Recipient's username" aria-describedby="button-addon2"/>
                                <button className="btn btn-outline-secondary" type="button" id="button-addon2"><i className='icon-focus-lens'/></button>
                                </div>
                                </div>
                                </Row>
                                <div className='test-list'>
                                <ul>
                                <li>
                                <div className='pro-name'>CHOLESTEROL</div>
                                <div className='price-name'>
                                <del>₹2700</del>
                                <span className='mrp'>₹1199</span>
                                <button className='btn order-now'>Book Now</button>
                                </div>
                                </li>

                                <li>
                                <div className='pro-name'>SGOT(AST)</div>
                                <div className='price-name'>
                                <del>₹2700</del>
                                <span className='mrp'>₹1199</span>
                                <button className='btn order-now'>Book Now</button>
                                </div>
                                </li>
                                <li>
                                <div className='pro-name'>TRIGLYCERIDE</div>
                                <div className='price-name'>
                                <del>₹2700</del>
                                <span className='mrp'>₹1199</span>
                                <button className='btn order-now'>Book Now</button>
                                </div>
                                </li>
                                <li>
                                <div className='pro-name'>GLUCOSE FASTING</div>
                                <div className='price-name'>
                                <del>₹2700</del>
                                <span className='mrp'>₹1199</span>
                                <button className='btn order-now'>Book Now</button>
                                </div>
                                </li>

                                </ul>

                                </div>

                                <div className='clr height10'/>
                                <nav aria-label="..." className='float-end float-mob' >
                                <ul className="pagination pagination-style mb-0">
                                <li className="page-item disabled">
                                <span className="page-link">Previous</span>
                                </li>
                                <li className="page-item"><a className="page-link" href="#">1</a></li>
                                <li className="page-item active" aria-current="page">
                                <span className="page-link">2</span>
                                </li>
                                <li className="page-item"><a className="page-link" href="#">3</a></li>
                                <li className="page-item">
                                <a className="page-link" href="#">Next</a>
                                </li>
                                </ul>
                                </nav>

                                <div className='clr'/>
                                </Accordion.Body>
                                </Accordion.Item> */}



                            {topOffers.limitedOffers.items.map((item, key) =>
                                <Accordion.Item key={key} eventKey={key}>
                                    <Accordion.Header><i className='icon-top-offers' />{item.PromoDesc}</Accordion.Header>
                                    <Accordion.Body>
                                        <p>
                                            {item.PromoText}
                                        </p>
                                        {item.IsCouponPromo ? <>
                                            <NavLink to="/diagnostic-test" className='btn order-now' >Get It Now</NavLink>
                                        </> : <></>}
                                    </Accordion.Body>
                                </Accordion.Item>)}
                        </Accordion>
                    </Container>
                </> : <></>
                }

                {topOffers.regularOffers && topOffers.regularOffers.items && topOffers.regularOffers.items.length > 0 ? <>
                    <Container className='offer-block'>
                        <div className='height20 clr' />
                        <h4 className='heading-offer'>{topOffers.regularOffers.OfferHead}</h4>
                        <Accordion className='accordion-style3'>
                            {topOffers.regularOffers.items.map((item, key) =>
                                <Accordion.Item key={key} eventKey={key} className={(IsThisItemSelected(item, cartState) ? (" selected" + (IsThisItemSelected(item, cartState) === 2 ? " included" : "")) : "")} >
                                    <div className='offer-heading'>
                                        <div className='heading-block'>
                                            <Link to={getNewPermalink(item, isPermalinkNew, 'self')} className='heading'>{item.ServiceName}</Link>
                                            {item.DiscPercent ? <span className="off-per">{item.DiscPercent}% Off</span> : (item.DiscountPercent ? <span className="off-per">{item.DiscountPercent}% Off</span> : (
                                                item.DiscAmount ? <span className="off-per">₹{item.DiscAmount} Off</span> : ''
                                            ))}
                                        </div>
                                        <div className='price-name'>
                                            <del>₹{item.Fees}</del>
                                            <span className='mrp'>₹{item.OfferFees}</span>

                                            <a href={IsThisItemSelected(item, cartState) ? "removeFromCart/" + item.LabId + "/" + item.ServiceId + "/0.00/A/-1" : "addToCart/" + item.LabId + "/" + item.ServiceId + "/" + item.OfferFees + "/" + (item.IsHomeCollectionAvailable ? "A" : "L") + "/" + key + (item.IsPackage ? '/allPackages' : '/allDiagnosticTests')}
                                                onClick={lnkBookATest}
                                                className="btn booktest" >{IsThisItemSelected(item, cartState) ? (IsThisItemSelected(item, cartState) === 1 ? "SELECTED" : <span>You have already added a test of this package</span>) : "Book Now"}</a>
                                            {/* <button className='btn order-now'>Book Now</button> */}
                                        </div>
                                    </div>
                                    <Accordion.Header>
                                        <span className='btn view-all-click'>View Details</span>
                                        &nbsp;</Accordion.Header>
                                    <Accordion.Body>
                                        {item.IsPackage ? <>
                                            <h4 className='text-orange'>Package details:</h4>
                                            <></>
                                            <p>The Health Check Package consists of :
                                                <ul className='pkglist'>
                                                    {item.PackageServices.length > 0 && item.PackageServices.map((services, sk) =>
                                                        <li key={sk}>{services.ServiceName}</li>
                                                    )}
                                                </ul>
                                            </p>
                                        </> : <></>}
                                        {item.ServiceText && item.ServiceText !== '' ? <p>{item.ServiceText}</p> : <></>}
                                        <h4 className='text-orange'>Preparation:</h4>
                                        <p>{item.ServicePreparation}</p>
                                        <h4 className='text-orange'>Report Delivery:</h4>
                                        <p>{item.ReportText}</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                            )}
                        </Accordion>
                    </Container>
                </> : <></>}
            </div>
        </>
    )
}

export default TopOffers;