import { userContext } from '../services/userHandler';
import { GetData } from "../services/SendData";
import { getConfigData, getNewPermalink, initSessionData } from "../services/Helper";
import { Link, NavLink } from "react-router-dom";
import { useState, useContext } from 'react';

var queryStarted = {
  popularTest: false,
  popularPackages: false,
  healthConditions: false,
  homePageBanners: false,
  allPackages: false
}


export function PopularTests(props) {

  const server = props.server;
  const initData = props.initData;
  const [popularTests, setPopularTests] = useState(initData.popularTests);
  const isPermalinkNew = (document.getElementById('commonField') && document.getElementById('commonField').getAttribute('permalink_new') === '1') || false;


  function getPopularTestsData() {
    var apiSegment = '/genulabhome/PopularTest';
    if (queryStarted.popularTest) {
      return false;
    }

    queryStarted.popularTest = true;
    GetData(apiSegment, props)
      .then((result) => {
        //console.log('queryStarted2: ',queryStarted);
        let jResponse = result;

        if (jResponse?.Item?.length > 0) {
          jResponse.Item.forEach(function (currentValue, index, arr) {
            let permalink = isPermalinkNew && currentValue.PermalinkNew ? currentValue.PermalinkNew : currentValue.permalink;
            permalink = permalink.replace("https://staging.genupathlabs.com/", "").replace("https://www.genupathlabs.com/", "");
            arr[index].permalink = server.base_url + "/" + permalink;
          });
        }


        //https://staging.genupathlabs.com/services/73/ABO-GROUP-&-RH-TYPE-EDTA-WHOLE-BLOOD

        var objInitData = initSessionData(); //clone(initData);
        objInitData.popularTests = jResponse;
        window.sessionStorage.setItem('initData', JSON.stringify(objInitData));
        setPopularTests(objInitData.popularTests);
        //console.log('initData:', initData);
        window.setTimeout(function () { queryStarted.popularTest = false; }, 5000);


      });

  }


  function getInitData() {
    if ((popularTests?.Item?.length || 0) === 0 && !queryStarted.popularTest) {
      //  queryStarted.popularTest=true;
      getPopularTestsData();
      //console.log('Query Started : popularTests ', initData.popularTests.Item.length, queryStarted.PopularTest);
    }
  }

  window.setTimeout(function () { getInitData(); }, 80);

  return (
    <>
      <li><a href={"#"}>Popular Tests</a>
        <div className="submenu">
          {popularTests?.Item?.length && <ul className="PopularTestsSubmenu" >
            {popularTests.Item.map(item =>
              <li key={item.ServiceId}><Link to={getNewPermalink(item, false, 'self')} >{item.ServiceName} <span>({item.Cnt})</span></Link></li>
            )}
          </ul> || <></>}
          <div className="view-allblock"><NavLink to="/diagnostic-test" className="btn btn-viewall">VIEW ALL TESTS</NavLink></div>
        </div>
      </li>
    </>
  );
}

export function PopularPackages(props) {
  const server = props.server;
  return (
    <li><a href={server.base_url + '/diagnosis/list/Popular-Packages'} >Popular Packages</a></li>
  );
}

export function HealthConditions(props) {

  const server = props.server;
  const initData = props.initData;
  const [healthConditions, setHealthConditions] = useState(initData.healthConditions);


  function getHelthConditionData() {
    var apiSegment = '/loadData/healthConditionList';

    queryStarted.healthConditions = GetData(apiSegment, props)
      .then((result) => {
        let jResponse = result;
        var objInitData = initSessionData(); //clone(initData);
        objInitData.healthConditions = jResponse;
        window.sessionStorage.setItem('initData', JSON.stringify(objInitData));
        setHealthConditions(objInitData.healthConditions);
        //console.log('initData:healthConditions', initData);
      });

  }



  function getInitData() {


    if (healthConditions.Items && healthConditions.Items.length === 0 && !queryStarted.healthConditions) {
      //queryStarted.healthConditions=true;
      getHelthConditionData();
    }


  }

  window.setTimeout(function () { getInitData(); }, 80);
  //  console.log('healthConditions.Items',healthConditions.Items?.slice(0,20));
  return (
    <li  ><a href={'#'}>Health Conditions</a>
      <div className="submenu">
        <ul>
          {healthConditions.Items && healthConditions.Items.length > 0 && healthConditions.Items?.slice(0, 12).map((item, ndx) =>
            <li key={'healthConditions-item-' + item.HCId.toString()} >
              <Link to={'/health-condition/' + item.PermaWord} >{item.HealthCondition}</Link></li>
          )}

        </ul>
        <div className="view-allblock">
          <NavLink to={'/health-condition'} className="btn btn-viewall">VIEW ALL</NavLink>
        </div>
      </div>
    </li>
  )
}
export function Reports(props) {

  const { userState, userDispatch } = useContext(userContext);
  const server = props.server;


  return (

    <li key={'reports'} ><a href={server.base_url + "/dashboard/bookedservicelist"} >Reports</a>
      {/*
      <div className="submenu m-fullwidth">
        <div className="r-heading">
          Download Reports
        </div>
        <div className="r-download-list">
          <div className>Arthritis Profile</div>
          <a  href={'#'} className="btn-bownload">DOWNLOAD</a>
        </div>
        <div className="r-download-list">
          <div className>Senior Citizen Package</div>
          <a href={'#'} className="btn-bownload">DOWNLOAD</a>
        </div>
        <ul>
          <li><a href={'#'} >Health Records</a></li>
          <li><a href={'#'} >Reports History</a></li>
          <li><a href={'#'} >Notifications</a></li>
        </ul>
      </div>
  */}
    </li>
  );

}


export function Social() {
  return (
    <span> &nbsp; {/*<li className="social">
      <a href={'#'} ><i className="icon-facebook2" /></a>
      <a href={'#'} ><i className="icon-twitter" /></a>
      <a href={'#'} ><i className="icon-instagram" /></a>
  </li>*/}</span>
  );
}

export function BookByPrescription(props) {
  //const { userState, userDispatch } = useContext(userContext);
  return (
    <li><NavLink to="/book-by-prescription">Book By Prescription</NavLink></li>
  );
}

export function Loader(props) {
  props = typeof (props) !== 'undefined' ? props : {};
  const configData = getConfigData();
  const server = configData.server;

  var loaderId = typeof (props.loaderId) !== 'undefined' ? props.loaderId : 'loader' + Math.random();
  const mystyle = {
    backgroundColor: "rgba(255, 255, 255, 0.4)",
    width: "100%",
    height: "100%",
    position: 'fixed',
    display: 'block',
    zIndex: 102,
    display: "grid",
    placeItems: "center"

  };

  return (
    <div className='genuloader' id={loaderId} style={mystyle} >
      <img src={server.cdn_url + "/images/testLoad.gif"} width={40} alt="" />
    </div>

    // <div className='genuloader' id={loaderId} style={mystyle} >
    //   <div className="genuinner">
    //     <img src="images/testLoad.gif" alt="" />
    //   </div>
    //   <div className='genuloaderstyle'  ></div>
    // </div>
  );
}


