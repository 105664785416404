import Slider from "react-slick";
import { Container } from "react-bootstrap";
import { WhyGenu } from "../../../sections/WhyGenu";
import { settings1 } from "../HomeConstants";
import useFetch from "../../../Hook/useFetch";
import { HomePageApiUrl } from "./../HomeApiConfig";

function HomePageBannersArea({ configData, server }) {
  const { webBannerImages } = HomePageApiUrl;
  const { data: { data: homePageBanners } = {} } = useFetch(webBannerImages);

  return (
    <section className="bannersection">
      <Container className="relative-block">
        <div className="row pad10">
          <div className="col-lg-6 pad0">
            <Slider {...settings1} className="genu-slider">
              {homePageBanners?.length > 0 ? (
                homePageBanners.map((item, key) => (
                  <div className={"item i" + (key + 1)} key={key + 1}>
                    <a href={item.ActionUrl}>
                      <img src={item.BannerUrl} className="w-100 r-corner" alt="Banner" />
                    </a>
                  </div>
                ))
              ) : (
                <div className="item i0" key="0">
                  <a href={"-1"}>
                    <img src={server.banner_url + "/react_banner-732x238v2.png"} className="w-100 r-corner" alt="" />
                  </a>
                </div>
              )}
            </Slider>
            <div className="clr" />
          </div>
          <div className="col-lg-6 pad5">
            <WhyGenu configData={configData} server={server} />
          </div>
        </div>
      </Container>
    </section>
  );
}

export default HomePageBannersArea;
