import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import {  getUserData} from '../services/SendData';
import { setElementVal, getConfigData} from '../services/Helper';
import { userContext } from '../services/userHandler';
import { useContext } from 'react';

export default function ScrollToTop(props) {
  const { pathname } = useLocation();
  const { userState, userDispatch } = useContext(userContext);
  const configData =getConfigData();

  function clearSearchResult() {

    setElementVal([{ id: "txtTestDataSearch", value: '' }]);
    //setSearchTestItems(InitSearchTest.SearchList);
    document.getElementById("showSearchDiv").style.display = "none";
  }

  function verifyLogin() {
    let  myPromise = getUserData(configData).then((objUserData) => {
      // console.log('objUserData::>>', objUserData);
      userDispatch({ type: 'USER', payload: objUserData });
    });
  }




  useEffect(
    () => {
      window.scrollTo(0, 0);

      if (document.getElementsByClassName('PageNotFound').length > 0) {
        document.getElementsByClassName('search-section')[0].classList.add("d-none");
      } else if (document.getElementsByClassName('search-section d-none').length > 0) {
        document.getElementsByClassName('search-section d-none')[0].classList.remove("d-none");
        window.setTimeout(function () {
          window.location.reload(true);
        }, 1000);
        
      } else {
        //alert("hime");
        verifyLogin();
      }
      //fetchCart();
      clearSearchResult();

    },
    [pathname]
  );

  return null;
}

