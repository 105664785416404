import { hideDropdown } from "../services/Helper";
import { userContext } from '../services/userHandler';
import { Dropdown } from "react-bootstrap";
import { useContext } from 'react';
import { NavLink } from "react-router-dom";


function UserAccount(props) {

    const { userState } = useContext(userContext);
    const server = props.server;

    if (userState.isLoggedIn) { //userData.isLoggedIn
        return (
            <Dropdown>
                <Dropdown.Toggle className="block-location userlogin">
                    <i className="icon-account usericon" /><span className="username">Hi {userState.displayName}</span>
                </Dropdown.Toggle>

                <Dropdown.Menu className="d_user_account d-menu maxwidth3 user-account">
                    <h4 className="user-heading">Welcome  <span className="user-name">{userState.displayName}</span></h4>
                    <ul className="user-lan-list">
                        <li><a href={server.base_url + "/dashboard/bookedservicelist"} >My Booking</a></li>
                        {userState.IsSmartReportExists ?
                            <li><NavLink to="/smart-report" onClick={function () { hideDropdown('d_user_account d-menu'); }}  >Health Analysis Report</NavLink></li>
                            : <></>}
                        <li><a href={server.base_url + "/my-profile"} >My Profile</a></li>
                        <li  ><a href={server.base_url + "/genulabhome/logout"} className='Logout' id='user_logout'  >Log Out</a></li>
                    </ul>
                    <div className="d-footer"></div>
                </Dropdown.Menu>
            </Dropdown>
        );
    } else {
        return (
            <NavLink to="/login" className="login"><i className="icon-account" /> Login</NavLink>
            // <li>
            //     <NavLink to="/login" className="login"><i className="icon-account" /> Login</NavLink>
            // </li>
        );
    }

}

export default UserAccount;