import { NavLink } from "react-router-dom";
import { Container, Row } from "react-bootstrap";

function HeaderCareer() {
  return (
<>
<div className="header-career">
<Container>
<Row>
    <div className="col-lg-6">
<div className="header-part">
    <div className="logo-block">
<div className="logo">
<NavLink to="/" className="genulogo" ><img src="/images/genu-logo.png" className="img-fluid" alt="" /></NavLink>
</div>
<div className="ctxt">Career</div>
</div>
<div className="headerclr"></div>    
</div>
</div>
<div className="col-lg-6 text-end">
    <div className="share d-none">Share this Job
    <div className="social">
        <a href="{'#'}"><i className="icon-facebook"></i></a>
        <a href="{'#'}"><i className="icon-twitter"></i></a>
        <a href="{'#'}"><i className="icon-linkedin"></i></a>
        <a href="{'#'}"><i className="icon-envelope"></i></a>
    </div>
    </div>
</div>
</Row>
</Container>

</div>
</>
  )
}

export default HeaderCareer;