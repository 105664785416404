import React from "react";
import { useLocation } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";

const BreadCrumb = () => {
  const location = useLocation();
  const { pathname } = location;
  // const pathnames = pathname.replaceAll('/dashboard/', '/my-profile/')
  //   .split("/").filter((item) => item);
  const pathnames = pathname.split("/").filter((item) => item);
  const capatilize = (s) => s.charAt(0).toUpperCase() + s.slice(1);
  return (
    <Breadcrumb>
      <Breadcrumb.Item key={"bread-home-0"} data-key={"bread-home-0"}
        href={pathnames.length > 0 ? "/" : null} active={pathnames.length === 0} >
        <i className="icon-home1" /> Home
      </Breadcrumb.Item>
      {pathnames.length > 0 && pathnames.filter(item => (item != 0 && item != 1 && item != 2 )).map((name, index) => {
        const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
        const isLast = index === (pathnames.length - 1);
        return <Breadcrumb.Item key={"bread-item-" + index} data-key={"bread-item-" + index}
          href={!isLast ? routeTo : null} active={isLast}
        >{capatilize(name
          .replaceAll('bookedservicelist', 'my-bookings')
          .replaceAll('smart-report', 'Health-Analysis-Report')
          .replaceAll('-', ' '))}
        </Breadcrumb.Item>
      })}
    </Breadcrumb>
  );
}

export default BreadCrumb;
