import { Container } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import './../assets/scss/Other.scss';
function Equipments() {
  return (
    <section className="Equipments ">

      <section className="breadcrumb-section">
        <Container>
          <div className="breadcrumb-section-inner">
            <nav aria-label="breadcrumb" className="breadcrumb-nav">
              <ol className="breadcrumb mt-0">
                <li className="breadcrumb-item"><NavLink to="/"><i className="icon-home1" /> Home</NavLink></li>
                <li className="breadcrumb-item"><NavLink to="">Automated Equipments</NavLink></li>

              </ol>
            </nav>
          </div>
        </Container>
      </section>

      <Container>
        <div className="clr height10" />
        <div className="smallertex">TEST EQUIPMENTS AT GENU PATHLABS</div>
        <h2 className="heading-style">Advanced Automated Equipments</h2>
        <div className="clr height20" />

        <ul className="equipments-det">
          <li>
            <div className="equipment-block">
              <div className="equipment-img">
                <img src="../images/equipment-01.png" className="img-fluid" alt="" />
              </div>
              <div className="equipment-content" 
>
                <h3>COBAS Integra 400 plus of Roche</h3>
                <p>Fully automated clinical
                  chemistry analyser with
                  extensive assay menu along
                  with proven measurement
                  technologies and
                  sophisticated software.</p>
              </div>
            </div>
          </li>
          <li>
            <div className="equipment-block">
              <div className="equipment-img">
                <img src="../images/equipment-02.png" className="img-fluid" alt="" />
              </div>
              <div className="equipment-content">
                <h3>COBAS e41 1 of Roche</h3>
                <p>Fully automated immunoassay
                  analyser that uses latest
                  technology providing the most
                  accurate assay results.</p>
              </div>

            </div>
          </li>
          <li>
            <div className="equipment-block">
              <div className="equipment-img">
                <img src="../images/equipment-03.png" className="img-fluid" alt="" />
              </div>
              <div className="equipment-content">
                <h3>D10 of Bio-Rad</h3>
                <p>Works on the principle of HPLC
                  for highly accurate &amp; precise
                  measurement of HBA1C and
                  different fractions of
                  haemoglobin.</p>
              </div>

            </div>
          </li>
          <li>
            <div className="equipment-block">
              <div className="equipment-img">
                <img src="../images/equipment-04.png" className="img-fluid" alt="" />
              </div>
              <div className="equipment-content">
                <h3>Easylyte Plus of Medica</h3>
                <p>Electrolytes (Sodium, Potassium,
                  Chloride) can be tested in less than 60
                  seconds using ISE method.</p>
              </div>

            </div>
          </li>
        </ul>

      </Container>
    </section>
  );
}

export default Equipments;