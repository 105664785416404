import React from 'react'
import { useEffect, useState } from "react";
import ReactPaginate from 'react-paginate';
import { Col, Container, Row } from 'react-bootstrap'
import BreadCrumb from './BreadCrumb';
import { PostData } from '../services/SendData';
import { getConfigData } from '../services/Helper';

export default function DirectoryOfServices() {


  const [directoryOfServicesApi] = ["/genulabhome/directory-of-services"];
  const configData = getConfigData();
  const [reqParams, setReqParams] = useState({ PageNo: 1 });
  const [services, setServices] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [recordPerPage, setRecordPerPage] = useState(0);
  const [searchText, setSearchText] = useState();
  const [pageCount, setPageCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [hasSearched, setHasSearched] = useState(false);

  // const [timeLeft, setTimeLeft] = useState(calculateRemainingTime);


  // RecordPerPage CurrentPage

  // function calculateRemainingTime() {
  //   const year = new Date().getFullYear();


  //   var eventDate = `12/31/${year}` // MM-DD-YYYY (31st Dec)


  //   /* difference holds the time difference from the current date to the second
  //    of the event date. */
  //   const difference = +new Date(eventDate) - +new Date();
  // }



  const fetchDirectoryOfServices = async () => {
    // console.log(configData)
    // alert('HI');
    setHasSearched(false);
    try {

      const response = await PostData(directoryOfServicesApi, reqParams, configData);
      // console.log(response);
      setHasSearched(true);
      setServices(response?.data?.ListData);
      setPageCount(response?.data?.TotalPages);
      setRecordPerPage(response?.data?.RecordPerPage);
      setCurrentPage(response?.data?.CurrentPage);
      setTotalCount(response?.data?.TotalCount);
    } catch (err) {
      console.log(err);
    }
    return false;
  };

  const handlePageClick = (event) => {
    const newOffset = event.selected; // (event.selected * itemsPerPage) % items.length;
    let params = { ...reqParams };
    params.PageNo = (event.selected + 1);
    setReqParams(params);

    console.log(
      `User requested page index ${event.selected} `, params
    );
  }

  useEffect(() => {
    fetchDirectoryOfServices();
  }, [reqParams]);


  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setTimeLeft(calculateRemainingTime());
  //   }, 2000);
  //   return () => clearInterval(timer);
  // }, [timeLeft]);

  useEffect(() => {
    // fetchDirectoryOfServices();
    const timer = setTimeout(() => {
      // setTimeLeft(calculateRemainingTime());
      let params = { ...reqParams };
      params.TestName = searchText;
      params.PageNo = 1;
      setReqParams(params);
      console.log(searchText);
    }, 2000);
    return () => clearInterval(timer);


  }, [searchText]);



  return (
    <>
      <section className="breadcrumb-section">
        <Container>
          <BreadCrumb />
        </Container>
      </section>
      <Container>
        <div className='clr height10' />
        <h2 className='new__heading-style2'>Directory of Services</h2>
        <div className='clr height10' />
        <div className='rwd-table2'>
          <div className='searctablehblock'>
            <Row className='pad10'>
              <Col sm={3} className='pad5'>
                <div className="mb-3 relative-block">
                  <i className='icon-focus-lens isearch'></i>
                  <input type="text" className="form-control searchi" placeholder="Search Tests" onChange={(e) => {
                    setSearchText(e?.target?.value);
                  }} />
                </div>
              </Col>
              <Col sm={2} className='pad5'>
                <div className="mb-2">
                  <select className="form-select" aria-label="Default select example" onChange={(e) => {
                    let params = { ...reqParams };
                    params.CollectionType = e?.target?.value;
                    params.PageNo = 1;
                    setReqParams(params);

                    //console.log(e?.target?.value) 
                  }}>
                    <option value="" selected>All  ..</option>
                    <option value="1">Home Collection </option>
                    <option value="0">Lab Visits </option>
                  </select>
                </div>
              </Col>
              <Col sm={2} className='pad5'>
                <div className="mb-2">
                  <select className="form-select" aria-label="Default select example" onChange={(e) => {
                    let params = { ...reqParams };
                    params.TAT = e?.target?.value;
                    params.PageNo = 1;
                    setReqParams(params);

                    //console.log(e?.target?.value) 
                  }} >
                    <option value="" selected>Select TAT</option>
                    <option value="0">Same Day</option>
                    <option value="1">Next Day</option>
                    <option value="2">2 Days</option>
                    <option value="3">3 to 6 Days</option>
                    <option value="7">A Week or more</option>
                  </select>
                </div>

              </Col>
              <Col sm={2} className='pad5'>
                <div className="mb-2">
                  <select className="form-select" aria-label="Default select example" onChange={(e) => {
                    let params = { ...reqParams };
                    params.IsOutSourceTest = e?.target?.value;
                    params.PageNo = 1;
                    setReqParams(params);

                    //console.log(e?.target?.value) 
                  }} >
                    <option value="" selected>InHouse & OutSource</option>
                    <option value="0">InHouse Tests</option>
                    <option value="1">OutSource Tests</option>
                  </select>
                </div>

              </Col>


              <Col sm={3} className='pad5 text-end mb-2'>
                {/* <a href={'#'} className='btn btn-outline-secondary btnexport'><i className='icon-export' /> Export</a> */}
              </Col>
            </Row>
          </div>
          <table>
            <tbody>
              <tr>
                <td>Sl No.</td>
                <td>Test Code</td>
                <td>Test Name</td>
                <td>Department</td>
                <td>MRP</td>
                <td>Sample Guideline</td>
                <td>Method</td>
                <td>TAT</td>
                <td>Test Schedule</td>
                <td>Home Collection</td>
              </tr>
              {services?.length > 0 && currentPage > 0 && recordPerPage > 0 && services?.map((serv, idx) => <tr key={'service-' + idx}>
                <td data-th="Sl No.">{idx + 1 + ((currentPage - 1) * recordPerPage)}
                </td>
                <td data-th="Test Code">{serv?.TestCode || '--'}</td>
                <td data-th="Test Name">{serv?.TestName || '--'}</td>
                <td data-th="Department">{serv?.Department}</td>
                <td data-th="MRP"><span className='mrpbtn'>₹{serv?.MRP || '###.##'}</span></td>
                <td data-th="Sample Guideline">
                  {serv?.TestSchedule ? <span className='guideline'>{serv?.TestSchedule}</span> : <></>}
                </td>
                <td data-th="Method">{serv?.Method}</td>
                <td data-th="TAT" nowrap={serv?.TAT > 0 ? true : false} >{serv?.TAT > 0 ? <>{serv?.TAT} Day{serv?.TAT > 1 ? 's' : ''}</> : <span className='text-sday'>Same Day</span>}</td>
                <td data-th="Test Schedule">{serv?.TestSchedule}</td>
                <td data-th="Home Collection">{serv?.HomeCollection ? <>Available</> : <>Not Available</>}</td>
              </tr>) || <>
                  {hasSearched && <><tr><td className='text-center' colSpan={10} >No Records found</td></tr> </>}

                </>}



            </tbody>
          </table>
        </div>
        <div className='clr height10' />
        <Row className='pad10'>
          <Col sm={9} className='pad5'>
            {pageCount > 0 && currentPage > 0 ? <ReactPaginate
              className={"pagination pagination-style"}
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item previous-btn"
              previousLinkClassName="page-link"
              nextClassName="page-item next-btn"
              nextLinkClassName="page-link"
              breakClassName="page-item page-break"
              breakLinkClassName="page-link page-break-link"
              breakLabel="..."
              nextLabel="next"
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel="previous"
              renderOnZeroPageCount={false}
              activeClassName={"active"}
              initialPage={parseInt(currentPage) - 1}
            /> : <></>}

          </Col>
          <Col sm={3} className='pad5 text-end'>{totalCount && <span>Total Count: {totalCount} </span> || <></>}</Col>
        </Row>
      </Container>


    </>
  )
}
