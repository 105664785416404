import React from "react";
import { Container } from "react-bootstrap";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { settings2 } from "../HomeConstants";
import useFetch from "../../../Hook/useFetch";
import { HomePageApiUrl } from "../HomeApiConfig";
import SectionTitle from "./SectionTitle";
import { getNewPermalink } from "../../../services/Helper";

function HomeTestByOrgan({ server, subtitle, title, viewAllLink }) {
  const { organsList } = HomePageApiUrl;
  const { data: { data: organs } = {} } = useFetch(organsList);

  return (
    <Container>
      <SectionTitle subtitle={subtitle} title={title} viewAllLink={viewAllLink} />

      <Slider {...settings2} className="slick-arrows-style categories">
        {organs?.length > 0
          ? organs?.map((item) => (
              <div key={item.OrganId} className="item">
                <div className="carouselblock">
                  <div className="blockstyle">
                    <Link to={getNewPermalink({'PermalinkNew':server.base_url + "/diagnostic-test/" + item.OrganName}, true, "self") }>
                      <img src={item.AppOrganImage} alt={item.OrganName} title={item.OrganName} />
                    </Link>
                  </div>
                  <p>
                    <Link to={getNewPermalink({'PermalinkNew':server.base_url + "/diagnostic-test/" + item.OrganName}, true, "self") } className="smallLink">
                      {item.OrganName}
                    </Link>
                  </p>
                </div>
              </div>
            ))
          : [...Array(10)].map((_, i) => <LoadingBlock key={i} server={server} />)}
      </Slider>
      <div className="clr height10" />
    </Container>
  );
}

export default HomeTestByOrgan;

const LoadingBlock = ({ server }) => {
  return (
    <div className="item fuzzy">
      <div className="carouselblock">
        <div className="blockstyle">
          <img
            width="250"
            src={server.cdn_url.replace("live/customer_V2", "images/organ") + "/heart_blur.png"}
            alt="Abo Group & RH Type, Edta Whole Blood"
          />
        </div>
        <p>Heart</p>
      </div>
    </div>
  );
};
