import './../assets/scss/Other.scss';
import ProfileMenu from './../site/ProfileMenu';
import ReportAccuracy from './../sections/ReportAccuracy';
import { Container } from "react-bootstrap";
import { NavLink } from "react-router-dom";


function Notifications() {
  return (
    <div className="MyProfile">
    <section className="breadcrumb-section">
    <Container>
    <div className="breadcrumb-section-inner">
    <nav aria-label="breadcrumb" className="breadcrumb-nav">
    <ol className="breadcrumb mt-0">
    <li className="breadcrumb-item"><NavLink to="/"><i className="icon-home1" /> Home</NavLink></li>
    <li className="breadcrumb-item"><NavLink to="">Notifications</NavLink></li>
    </ol>
    </nav>
    </div>
    </Container>
    </section>

    <Container>
    <ProfileMenu/>
    
    
    <div className="notification-tab-block">
      <div className="left-panel">

<div className="notification-block">
      <h3 className="notifi-heading">Notifications</h3>
      <div className="patient-name">Mr. Joydeep Chakraborthy,<small>Male, 77 years old</small></div>
      <div className="notification-page">
      <span className="page-value">1-20 of 110 Notifications</span>  
      <span className="arrow"><i className="icon-left-arrow"></i></span>
      <span className="arrow"><i className="icon-right-arrow"></i></span>
      </div>
    </div>
<div className="clr height20" />

<div className="tab-style-menu">
  <ul>
    <li><a href={'#'} className="active">ALL</a></li>
    <li><a href={'#'}>PROMOTIONS <span className="n-value">2</span></a></li>
    <li><a href={'#'}>BOOKINGS</a></li>
    <li><a href={'#'}>REPORTS</a></li>
    <li><a href={'#'}>REMINDERS</a></li>
    <li><a href={'#'}>OFFERS</a></li>
  </ul>
</div>

<div className="clr" />



     <div className="table-style table-responsive">
      <table className="table table-bordered">
        <thead>
            <tr>
                <th width="10%"><div className="title-block active">TYPE <i className="icon-arrow-down"></i></div></th>
                <th width="20%"><div className="title-block">SUBJECT <i className="icon-arrow-down"></i></div></th>
                <th width="60%"><div className="title-block">DETAILS <i className="icon-arrow-down"></i></div></th>
                <th width="10%"><div className="title-block">DATE <i className="icon-arrow-down"></i></div></th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td className="new">New</td>
                <td><div className="subject"><NavLink to="/notification-details" className="sub-text">GENU Order PO07584575687687werewr </NavLink><span className="btn-new">NEW</span></div></td>
                <td><div className="placed">Order Placed: Your GENU Path Labs Order PO07122562966217 is placed. We</div></td>
                <td>
                  <div className="date-block">
                    <span className="date">Today, 9:30 AM</span>
                    <i className="delete icon-dastbin-close"></i>
                  </div>
                </td>
     
            </tr>
            <tr>
                <td className="reminder">Reminder</td>
                <td><div className="subject"><span className="sub-text">GENU Order PO07584575687687werewr </span><span className="btn-new">NEW</span></div></td>
                <td><div className="placed">Order Placed: Your GENU Path Labs Order PO07122562966217 is placed. We</div></td>
                <td>
                <div className="date-block">
                    <span className="date">Sunday, 2:30 PM</span>
                    <i className="delete icon-dastbin-close"></i>
                  </div>
                </td>
            </tr>
            <tr>
                <td className="promo">Promo</td>
                <td><div className="subject"><span className="sub-text">GENU Promotions </span><span className="btn-new">NEW</span></div></td>
                <td><div className="placed">Summer Exclusive: Diabetes Full Package Test for 50 years and above at all Genu</div></td>
                <td>
                <div className="date-block">
                    <span className="date">Sunday, 4:30 PM</span>
                    <i className="delete icon-dastbin-close"></i>
                  </div>
                </td>
            </tr>

            <tr>
                <td>Promo</td>
                <td><div className="subject"><span className="sub-text">GENU Promotions </span></div></td>
                <td><div className="placed">Summer Exclusive: 50% off on Full Body Checkup Test from May to June 15th </div></td>
                <td>
                <div className="date-block">
                    <span className="date">Sunday, 4:30 PM</span>
                    <i className="delete icon-dastbin-close"></i>
                  </div>
                </td>
            </tr>
            <tr>
                <td>Reminder</td>
                <td><div className="subject"><span className="sub-text">GENU Lipid Profile test</span></div></td>
                <td><div className="placed">Summer Exclusive: 50% off on Full Body Checkup Test from May to June 15th </div></td>
                <td>
                <div className="date-block">
                    <span className="date">Sunday, 4:30 PM</span>
                    <i className="delete icon-dastbin-close"></i>
                  </div>
                </td>
            </tr>
            <tr>
                <td>Reminder</td>
                <td><div className="subject"><span className="sub-text">GENU Lipid Profile test</span></div></td>
                <td><div className="placed">Summer Exclusive: 50% off on Full Body Checkup Test from May to June 15th </div></td>
                <td>
                <div className="date-block">
                    <span className="date">Sunday, 4:30 PM</span>
                    <i className="delete icon-dastbin-close"></i>
                  </div>
                </td>
            </tr>
            <tr>
                <td>Reminder</td>
                <td><div className="subject"><span className="sub-text">GENU Lipid Profile test</span></div></td>
                <td><div className="placed">Summer Exclusive: 50% off on Full Body Checkup Test from May to June 15th </div></td>
                <td>
                <div className="date-block">
                    <span className="date">Sunday, 4:30 PM</span>
                    <i className="delete icon-dastbin-close"></i>
                  </div>
                </td>
            </tr>
         </tbody>
    </table>
    </div>
    <div className="clr height10"/>
   <div className="notification-footer">

<a href={'#'} className="btn pn-btn">Prev</a>
<a href={'#'} className="btn pn-btn">Next</a>
<div className="clr height20"/>
<p>All notifications and SMS are generated based on the details that are resulted from the GENU test reports and the necessary details of the customer that
you have shared with us. All such communications are solely established in a timely manner to keep you aware or to notify you for any test event that is
requested or a future test event GENU Path labs considers important for you.</p>
   </div>
      </div>
      <div className="right-panel">
        <div className="articles-block">
          <h3 className="article-heading">ARTICLES</h3>
          <ul className="article-list">
            <li><a href={'#'}><img src="/images/articles/article-01.jpg" alt="" /></a></li>
            <li><a href={'#'}><img src="/images/articles/article-02.jpg" alt="" /></a></li>
            <li><a href={'#'}><img src="/images/articles/article-03.jpg" alt="" /></a></li>
            <li><a href={'#'}><img src="/images/articles/article-04.jpg" alt="" /></a></li>
          </ul>
          <a href={'#'} className="showmore">Show More</a>
        </div>

      </div>
    </div>

    </Container>
    <ReportAccuracy/>
    </div>
  )
}

export default Notifications