import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { getNewPermalink } from "../../../services/Helper";
import { settings3 } from "../HomeConstants";
import BookNowButton from "../../../sections/BookNowButton";
import useFetch from "../../../Hook/useFetch";
import { HomePageApiUrl } from "../HomeApiConfig";
import SectionTitle from "./SectionTitle";

function HomeBestPackages({ server, subtitle, title, viewAllLink }) {
  const { popularTest } = HomePageApiUrl;
  const { data: { data: popularPackages } = {} } = useFetch([popularTest], {
    params: { type: 2 },
  });

  return (
    <>
      <Container>
        <SectionTitle subtitle={subtitle} title={title} viewAllLink={viewAllLink} />

        <Slider {...settings3} className="slick-arrows-style bestpackages">
          {popularPackages?.length > 0
            ? popularPackages?.map((item, ndx) => (
                <div key={"popularPackages" + item.ServiceId + ndx} className="item">
                  <div className="packages-block">
                    <div className="packblock">
                      {typeof item.IsYana !== "undefined" && item.IsYana ? (
                        <div className="free-diet">Diet Consultation Included</div>
                      ) : (
                        <></>
                      )}
                      <div className="package-content">
                        <span className="off-per">{item.Discount}% Off</span>
                        <div className="packtitle">
                          <Link to={getNewPermalink(item, true, "self")}>{item.ServiceName}</Link>
                        </div>

                        <div className="price-button-section">
                          <div className="priceblock">
                            <del>₹{item.Fees}</del> <span className="mrp">₹{item.OfferPrice}</span>
                          </div>

                          <BookNowButton product={item} className="btn order-now" />
                        </div>
                        <div className="packoff sameday">{item?.ServiceText && <>&#x2733; {item.ServiceText}</>}</div>
                      </div>
                      <img src={item.tower230ImageUrl} alt="" className="img-heignt" />
                    </div>
                  </div>
                </div>
              ))
            : [...Array(3)].map((item, idx) => <LoadingBlock key={idx} server={server} />)}
        </Slider>
      </Container>
    </>
  );
}

export default HomeBestPackages;

const LoadingBlock = ({ server }) => {
  return (
    <div className="item fuzzy">
      <div className={"packages-block"}>
        <div className="packblock">
          <div className="package-content" style={{ paddingBottom: 30 }}>
            <span className="off-per">10% Off</span>
            <div className="packtitle">Best Package</div>
            <div className="packoff"> &nbsp;</div>
            <div className="priceblock">
              <del>₹2500</del> <span className="mrp">₹2250</span>
            </div>
            <button className="btn order-now">Book Now</button>
          </div>
          <img
            src={
              server.cdn_url.replace("live/customer_V2", "images/test_n_packages") +
              "/tower230x300/General_Health_Check_UP_Package_Home_Collection.png"
            }
            alt=""
            className="img-heignt"
          />
        </div>
      </div>
    </div>
  );
};
