import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./assets/scss/genu-icon.css";
import "./assets/scss/App.scss";

import { Helmet } from "react-helmet";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { useReducer, Suspense, lazy } from "react";

import { getUserData } from "./services/SendData";
import { initSessionData, getConfigData } from "./services/Helper";
import { Loader } from "./sections/NavSections";
import {
    initialState,
    reducer,
    userContext,
    initCartState,
    cartContext,
} from "./services/userHandler";

// import SeoContent from "./site/SEOContent";
import Header from "./site/Header";
import HeaderCareer from "./site/HeaderCareer";
import Footer from "./site/Footer";
import Home from "./pages/Home";
import StaticBlankPage from "./pages/StaticBlankPage";
import Equipments from "./pages/Equipments";
import Login from "./pages/users/Login";
import Logout from "./pages/users/Logout";
//import Registration from './pages/users/Registration';
// import HealthPackages from './pages/HealthPackages';
import Booking from "./pages/Booking";
import TopOffers from "./pages/TopOffers";
import Notifications from "./pages/Notifications";
import NotificationDetails from "./pages/NotificationDetails";

// import Radiology from './pages/Radiology';
// import BookByPrescription from './pages/cart/BookByPrescription';
// import Cart from './pages/cart/Cart';
import PageNotFound from "./pages/PageNotFound";
import ScrollToTop from "./pages/ScrollToTop";
import GoTop from "./pages/GoTop";
import SeoContent from "./site/SeoContent";
import DirectoryOfServices from "./pages/DirectoryOfServices";

const MyBookingList = lazy(() => import("./pages/my_profile/MyBookingList"));
const MyBookingDetails = lazy(() => import("./pages/my_profile/MyBookingDetails"));
const MyBookingStatus = lazy(() => import("./pages/my_profile/MyBookingStatus"));
const MyProfile = lazy(() => import("./pages/MyProfile"));
const ForgotPassword = lazy(() => import("./pages/users/ForgotPassword"));
const Registration = lazy(() => import("./pages/users/Registration"));

const Career = lazy(() => import("./pages/Career"));

const Cart = lazy(() => import("./pages/cart/Cart"));

const OrderSuccess = lazy(() => import("./pages/cart/OrderSuccess"));

const BookByPrescription = lazy(() =>
    import("./pages/cart/BookByPrescription")
);

const Packages = lazy(() => import("./pages/tests-n-packages/Packages"));

const HealthPackage = lazy(() =>
    import("./pages/tests-n-packages/HealthPackage")
);

const DiagnosticTests = lazy(() =>
    import("./pages/tests-n-packages/DiagnosticTests")
);

const RadiologyTests = lazy(() =>
    import("./pages/tests-n-packages/RadiologyTests")
);

const HealthRecords = lazy(() => import("./pages/my_profile/SmartReport"));

const Radiology = lazy(() => import("./pages/Radiology"));

const Feedback = lazy(() => import("./pages/Feedback"));

const TestDetails = lazy(() => import("./pages/tests-n-packages/TestDetails"));

const HealthCondition = lazy(() =>
    import("./pages/tests-n-packages/HealthCondition")
);

const TestByOrgan = lazy(() =>
    import("./pages/tests-n-packages/TestByOrgan")
);

const PayAfterBooking = lazy(() =>
    import("./pages/my_profile/PayAfterBooking")
);

// const Dire

// directory-of-services


// const Contact = lazy(() => import("./pages/Contact"));
// const About = lazy(() => import("./pages/About"));
// const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));
// const TermsCondition = lazy(() => import("./pages/TermsCondition"));
const HealthArticle = lazy(() => import("./pages/HealthArticle"));
const HealthArticleDetails = lazy(() => import("./pages/HealthArticleDetails"));

function App() {
    const configData = getConfigData();
    const server = configData.server;
    const { pathname } = useLocation();
    const [userState, userDispatch] = useReducer(reducer, initialState);
    const [cartState, cartDispatch] = useReducer(reducer, initCartState);
    // ----------------- load session data  ------------
    var jInitData = initSessionData(); // {};

    const initData = jInitData;
    const userData = initialState;
    //const location = useLocation();
    const mobilePages = [
        "/",
        "/login",
        "/health-packages",
        "/diagnostic-test",
        "/my-profile",
        "/privacy-policy",
        "/about",
        "/terms-condition",
        "/dashboard/bookedservicelist",
        "/dashboard/reports",
        "/patients",
        "/add-patients" /*,  '/patient-address', '/add-patient-address'*/,
        "/update-patient",
        "/book-by-prescription",
        "/cart",
    ];
    const mobilePagesEx = [];
    const skipFooter = [
        "/radiology/howrah",
        "/radiology/rajarhat",
        "/contact",
        "/about",
        "/privacy-policy",
        "/terms-condition",
    ];
    skipFooter.map((val, inx, arr) => {
        let pnx = pathname.split("/");
        if (pnx.length >= 2) {
            arr[inx] = val.replace(":any", pnx[2]);
        }
        // console.log('skipFooter', arr[inx]);
    });
    const capatilize = (s) => s.charAt(0).toUpperCase() + s.slice(1);
    const pageName = () => {
        var pn = "";
        var pathnames = pathname
            .replace(/^\//, "")
            .replace("-", " ")
            .replace("_", " ")
            .replace("/", " - ")
            .split(" ");
        pathnames.map((name) => {
            pn += (pn && pn !== "" ? " " : "") + capatilize(name);
        });

        return pn;
    };

    if (isMobile && pathname.search("/login") !== -1) {
        let myPromise = getUserData(configData).then((objUserData) => {
            // console.log('objUserData::>>', objUserData);
            userDispatch({ type: "USER", payload: objUserData });
            document.location.reload(true);
        });
    } else if (
        isMobile &&
        (mobilePages.includes(pathname) ||
            pathname.search(mobilePagesEx[0]) !== -1 ||
            pathname.search(mobilePagesEx[1]) !== -1)
    ) {
        document.location.reload(true);
    }

    if (!skipFooter.includes(pathname) && document.getElementById("additional-page-content")) {
        document.getElementById("additional-page-content").innerHTML = "";
    }

    return (
        <>
            <SeoContent />
            <userContext.Provider value={{ userState, userDispatch }}>
                <cartContext.Provider value={{ cartState, cartDispatch }}>
                    <div>
                        <GoTop />
                        <ScrollToTop configData={configData} server={server} />
                        <Header
                            userData={userData}
                            configData={configData}
                            server={server}
                            initData={initData}
                        />
                        {pathname === "/career" ? <HeaderCareer /> : ""}
                        <div style={!skipFooter.includes(pathname) ? { minHeight: '650px' } : { minHeight: '0px' }}>
                            <Suspense fallback={<Loader />}>
                                <Routes>
                                    <Route
                                        path="/"
                                        element={
                                            <Home
                                                userData={userData}
                                                configData={configData}
                                                server={server}
                                            />
                                        }
                                    />
                                    {/* <Route path="/" element={<Equipments userData={userData} configData={configData} server={server} />} /> */}
                                    <Route
                                        path="login"
                                        element={
                                            <Login
                                                userData={userData}
                                                configData={configData}
                                                server={server}
                                            />
                                        }
                                    />
                                    <Route
                                        path="sign-up"
                                        element={<Registration />}
                                    />
                                    <Route
                                        path="forgot-password"
                                        element={<ForgotPassword />}
                                    />
                                    <Route
                                        path="equipments"
                                        element={
                                            <Equipments
                                                userData={userData}
                                                configData={configData}
                                                server={server}
                                            />
                                        }
                                    />

                                    <Route
                                        path="my-profile"
                                        element={
                                            <Navigate
                                                form={"/my-profile"}
                                                to={"/my-profile/my-family"}
                                            />
                                        }
                                    />
                                    <Route
                                        path="my-profile/*"
                                        element={<MyProfile />}
                                    />


                                    <Route
                                        path="/pay-now/:BookingNo"
                                        element={<PayAfterBooking />}
                                    />
                                    <Route
                                        path="pay-now"
                                        element={
                                            <Navigate
                                                form={"/pay-now"}
                                                to={"/dashboard/bookedservicelist"}
                                            />
                                        }
                                    />

                                    <Route path="booking" element={<Booking />} />
                                    <Route
                                        path="dashboard/bookedservicelist"
                                        element={<MyBookingList />}
                                    />

                                    <Route
                                        path="dashboard/bookedservicelist/:BookingNo"
                                        element={<MyBookingDetails />}
                                    />
                                    <Route
                                        path="dashboard/bookedservicelist"
                                        element={<MyBookingList />}
                                    />

                                    <Route
                                        path="dashboard/booking-status/:BookingNo"
                                        element={<MyBookingStatus />}
                                    />

                                    <Route
                                        path="dashboard/booking-status"
                                        element={
                                            <Navigate
                                                form={"dashboard/booking-status"}
                                                to={"/dashboard/bookedservicelist"}
                                            />
                                        }
                                    />

                                    <Route
                                        path="dashboard"
                                        element={
                                            <Navigate
                                                form={"/dashboard"}
                                                to={"/dashboard/bookedservicelist"}
                                            />
                                        }
                                    />
                                    <Route
                                        path="smart-report"
                                        element={<HealthRecords />}
                                    />

                                    <Route
                                        path="health-packages"
                                        element={<Packages />}
                                    />
                                    <Route
                                        path="health-packages/:parmalink"
                                        element={<HealthPackage />}
                                    />
                                    <Route
                                        path="diagnostic-test"
                                        element={<DiagnosticTests />}
                                    />
                                    <Route
                                        path="radiology-test"
                                        element={<RadiologyTests />}
                                    />
                                    <Route
                                        path="radiology-test/*"
                                        element={<RadiologyTests />}
                                    />
                                    <Route
                                        path="feedback/:BookingId"
                                        element={<Feedback />}
                                    />
                                    <Route
                                        path="bookingdetails/:TransactionId/:PayLater"
                                        element={<OrderSuccess />}
                                    ></Route>
                                    <Route
                                        path="bookingdetails/:TransactionId"
                                        element={
                                            <Navigate
                                                form={"/bookingdetails"}
                                                to={"/dashboard/bookedservicelist"}
                                            />
                                        }
                                    ></Route>
                                    <Route
                                        path="bookingdetails"
                                        element={
                                            <Navigate
                                                form={"/bookingdetails"}
                                                to={"/dashboard/bookedservicelist"}
                                            />
                                        }
                                    ></Route>
                                    <Route
                                        path="test/:parmalink"
                                        element={<TestDetails />}
                                    />

                                    <Route
                                        path="test"
                                        element={
                                            <Navigate
                                                form={"/test"}
                                                to={"/diagnostic-test"}
                                            />
                                        }
                                    />

                                    {/* 
                                // TEMPORARY BLOCKED
                                <Route
                                    path="top-offers"
                                    element={<TopOffers />}
                                /> */}
                                    <Route
                                        path="notifications"
                                        element={<Notifications />}
                                    />
                                    <Route
                                        path="notification-details"
                                        element={<NotificationDetails />}
                                    />
                                    {/*<Route path="about" element={<About userData={userData} configData={configData} server={server} />} />
        <Route path="services" element={<Services userData={userData} configData={configData} server={server} />} />
        <Route path="contact" element={<Contact userData={userData} configData={configData} server={server} />} />
  <Route path="booking" element={<Booking userData={userData} configData={configData} server={server} />} />*/}
                                    <Route path="logout" element={<Logout />} />
                                    <Route
                                        path="book-by-prescription"
                                        element={<BookByPrescription />}
                                    />
                                    <Route path="cart" element={<Cart />} />
                                    <Route path="career" element={<Career />} />
                                    <Route
                                        path="radiology/rajarhat"
                                        element={<StaticBlankPage />}
                                    />
                                    <Route
                                        path="radiology/howrah"
                                        element={<StaticBlankPage />}
                                    />
                                    <Route
                                        path="radiology"
                                        element={<Radiology />}
                                    />
                                    <Route
                                        path="health-condition/"
                                        element={<HealthCondition />}
                                    />
                                    <Route
                                        path="health-condition/:ServiceName"
                                        element={<HealthCondition />}
                                    />
                                    <Route
                                        path="/diagnostic-test/:OrganName"
                                        element={<TestByOrgan />}
                                    />
                                    {/*=========== Pages Converted To React ===========*/}

                                    {/* <Route path="contact" element={<Contact />} /> */}
                                    <Route
                                        path="contact"
                                        element={<StaticBlankPage />}
                                    />
                                    {/* <Route path="about" element={<About />} /> */}

                                    <Route
                                        path="about"
                                        element={<StaticBlankPage />}
                                    />
                                    {/* <Route
                                    path="privacy-policy"
                                    element={<PrivacyPolicy />}
                                /> */}

                                    <Route
                                        path="privacy-policy"
                                        element={<StaticBlankPage />}
                                    />

                                    {/* <Route
                                    path="terms-condition"
                                    element={<TermsCondition />}
                                /> */}

                                    <Route
                                        path="terms-condition"
                                        element={<StaticBlankPage />}
                                    />
                                    <Route
                                        path="payment-confirmation"
                                        element={<StaticBlankPage />}
                                    />

                                    <Route
                                        path="directory-of-services"
                                        element={<DirectoryOfServices />}
                                    />

                                    {/*=========== Pages Converted To React End ===========*/}
                                    <Route
                                        path="health-article"
                                        element={<HealthArticle />}
                                    />
                                    <Route
                                        path="health-article/:ArticleName"
                                        element={<HealthArticleDetails />}
                                    />


                                    <Route path="*" element={<PageNotFound />} />
                                </Routes >
                            </Suspense >
                        </div>
                        {!skipFooter.includes(pathname) ? (
                            <Footer
                                userData={userData}
                                configData={configData}
                                server={server}
                            />
                        ) : (
                            <></>
                        )
                        }
                    </div >
                </cartContext.Provider >
            </userContext.Provider >
        </>
    );
}

export default App;
