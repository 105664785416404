import { getConfigData, setElementVal, clone, VerticallyCenteredModal, redirectUrl, validateMobile } from '../../services/Helper';
import { useState, useContext } from 'react';
import { Navigate, NavLink } from "react-router-dom";
import { Container } from "react-bootstrap";
import { PostData } from '../../services/SendData';
import { userContext } from '../../services/userHandler';
import UseFetchCart from '../../Hook/useFetchCart';

function Login(props) {
  //  const context = useContext(contextValue);
  // ----------------- set common code for session checking : start -------------
  const configData = getConfigData();
  const { userState, userDispatch } = useContext(userContext);
  const [modalShow, setModalShow] = useState(false);
  const fetchCart = UseFetchCart();
  // ----------------- set common code for session checking : end ------------
  const [userInput, setUserInput] = useState({
    MobileNo: "",
    Password: "",
    OTP: "",
    OTPbaselogin: true,
    OTPSent: false,
    validation: {
      isValid: false,
      className: {
        MobileNo: 'form-control',
        Password: 'form-control',
        OTP: 'form-control'
      }
    },
    eleEye: {
      className: 'pass-show pass-hide',
      type: 'password'
    },
    _isSuccessed: false,
    _confirmationMessage: '',
    _confirmationMessage2: ''
  });


  function reSendOTP(event) {
    event.preventDefault();
    document.getElementById("reSendOTP").style.visibility = "hidden";
    const objInput = { 'OTP': parseInt(userInput.OTP.value), 'responseType': 'json' }
    PostData('/genulabhome/resendOtp', objInput, configData).then((result) => {
      window.setTimeout(function () { document.getElementById("reSendOTP").style.visibility = "visible"; }, 10000);

      var objEle = clone(userInput);
      objEle._isSuccessed = result.status === 2000 ? true : false;
      objEle._confirmationMessage = result.message;
      objEle._confirmationMessage2 = '';
      setUserInput(objEle);

    });
  }


  function verifyOtp() {


    const objInput = { 'OTP': parseInt(userInput.OTP), 'responseType': 'json' }
    PostData('/genulabhome/verifyotp', objInput, props).then((result) => {
      document.getElementById("btnLogin").removeAttribute("disabled");
      let jResponse = result;
      let objUserData = clone(userState);

      // console.log('verifyOtp:jResponse', verifyOtp);
      // return false;

      if (jResponse.status) {
        objUserData.mobileNo = jResponse.data.MobileNo;
        objUserData.displayName = jResponse.data.DisplayName;
        objUserData.IsSmartReportExists = (jResponse.data.IsSmartReportExists && parseInt(jResponse.data.IsSmartReportExists) === 1) ? true : false;
        objUserData.hasMinInfo = (jResponse?.data?.HasMinInfo && parseInt(jResponse.data.HasMinInfo) >= 15) ? true : false;
        objUserData.HasMinInfo =  jResponse?.data?.HasMinInfo;
        objUserData.isLoggedIn = true;
        window.sessionStorage.setItem('userData', JSON.stringify(objUserData));
        userDispatch({ type: 'USER', payload: objUserData });
        window.setTimeout(function () { window.location.reload(true); }, 500);
      } else {
        var objEle = clone(userInput);
        objEle._isSuccessed = false;
        objEle._confirmationMessage = jResponse.message;
        objEle._confirmationMessage2 = '';
        setUserInput(objEle);
      }
    });


    return false;
  }

  function passFunction(event) {
    event.preventDefault();
    //Password-show-hide---
    var objEle = clone(userInput);
    if (objEle.eleEye.type === 'password') {
      objEle.eleEye.type = 'text';
      objEle.eleEye.className = 'pass-show';
    } else {
      objEle.eleEye.type = 'password';
      objEle.eleEye.className = 'pass-show pass-hide';
    }
    objEle._confirmationMessage = '';
    setUserInput(objEle);
  }

  function submitUserLogin() {
    if (userInput.validation.isValid) {
      document.getElementById("btnLogin").setAttribute("disabled", "disabled");
      if (userInput.OTPSent) {
        if (userInput.OTP !== "") {
          return verifyOtp();
        } else {

          var objEle = clone(userInput);
          objEle._isSuccessed = true;
          objEle._confirmationMessage = "Enter Verification Code which has been sent to your mobile.";
          objEle._confirmationMessage2 = '';
          setUserInput(objEle);


          document.getElementById("btnLogin").removeAttribute("disabled");
          setElementVal([
            { id: "OTP", value: "" }
          ]);
        }
        return false;
      } else if (userInput.OTPbaselogin) {
        const objInput = { 'MobileNo': userInput.MobileNo, 'OTPbaselogin': 1, 'responseType': 'json' }
        PostData('/genulabhome/verifymobile', objInput, props).then((result) => {
          document.getElementById("btnLogin").removeAttribute("disabled");
          let jResponse = result;
          let objEle = clone(userInput);
          objEle._isSuccessed = false;
          objEle._confirmationMessage = jResponse.message
          objEle._confirmationMessage2 = '';
          if (jResponse.ReturnStatus) {
            objEle.OTPSent = true;
            objEle._isSuccessed = true;
          }
          setUserInput(objEle);
        });
        return false;
      } else {
        const objInput = { 'MobileNo': userInput.MobileNo, 'Password': userInput.Password, 'responseType': 'json' }
        PostData('/genulabhome/login', objInput, props).then((result) => {
          document.getElementById("btnLogin").removeAttribute("disabled");
          let jResponse = result;
          if (jResponse.status === 2000 && jResponse.data.ReturnStatus === 3) {
            let objUserData = clone(userState);
            objUserData.mobileNo = jResponse.data.MobileNo;
            objUserData.displayName = jResponse.data.DisplayName;
            objUserData.IsSmartReportExists = (jResponse.data.IsSmartReportExists && parseInt(jResponse.data.IsSmartReportExists) === 1) ? true : false;
            objUserData.hasMinInfo = (jResponse?.data?.HasMinInfo && parseInt(jResponse.data.HasMinInfo) >= 15) ? true : false;
            objUserData.HasMinInfo =  jResponse?.data?.HasMinInfo;
            objUserData.isLoggedIn = true;
            window.sessionStorage.setItem('userData', JSON.stringify(objUserData));
            userDispatch({ type: 'USER', payload: objUserData });
            window.setTimeout(function () { window.location.reload(true); }, 500);
          } else {
            var objEle = clone(userInput);
            objEle._isSuccessed = false;
            objEle._confirmationMessage = jResponse.message;
            objEle._confirmationMessage2 = jResponse?.data?.msg || jResponse?.data?.OperationDesc;
            if (jResponse.data.ReturnStatus === 2) {
              objEle._isSuccessed = true;
              objEle.OTPbaselogin = true;
              objEle.OTPSent = true;
            }
            setUserInput(objEle);
          }
        });
        return false;
      }
    } else {
      document.getElementById("btnLogin").removeAttribute("disabled");
      var objEle1 = clone(userInput);
      objEle1._isSuccessed = false;
      console.log('userInput', userInput);
      if (userInput.OTPbaselogin) {
        objEle1._confirmationMessage = userInput.MobileNo && userInput.MobileNo !== '' ? 'Enter a valid Mobile Number' : 'Mobile Number cannot be blank'
      } else {

        if (!userInput.MobileNo || userInput.MobileNo === '') {
          objEle1._confirmationMessage = 'Mobile Number cannot be blank';
        } else if (!validateMobile(userInput.MobileNo)) {
          objEle1._confirmationMessage = 'Enter valid Mobile Number'
        } else if (!userInput.Password || userInput.Password === '') {
          objEle1._confirmationMessage = 'Password cannot be blank'
        }
        // objEle1._confirmationMessage = 'Mobile Number and Password cannot be blank';
      }
      setUserInput(objEle1);
    }
    return false;
  }

  function onChangeUserInput(e) {
    let pass = { MobileNo: false, Password: false, OTPbaselogin: false };
    let mibileFormat = /^[1-9]\d{9}$/;
    let obj = clone(userInput);
    //console.log('test', e.target.checked);
    if (e.target.name === 'OTPbaselogin') {
      //console.log('test', e.target.checked);
      obj[e.target.name] = e.target.checked;
      window.setTimeout(function () {
        //console.log('userInput.validation.className.MobileNo', obj.OTPbaselogin, userInput.validation.className.MobileNo);
        if (obj.OTPbaselogin || userInput.validation.className.MobileNo !== 'form-control is-valid') {
          document.getElementById('MobileNo').focus();
        } else {
          document.getElementById('Password').focus();
        }
      }, 500);
    } else {
      obj[e.target.name] = e.target.value;
    }

    if (obj.MobileNo && obj.MobileNo.match(mibileFormat)) {
      obj.validation.className.MobileNo = 'form-control is-valid';
      pass.MobileNo = true;
    } else if (obj.MobileNo === '') {
      obj.validation.className.MobileNo = 'form-control';
      pass.MobileNo = false;

    } else {
      obj.validation.className.MobileNo = 'form-control is-invalid';
      pass.MobileNo = false;
    }

    if (obj.Password !== '') {
      obj.validation.className.Password = 'form-control is-valid';
      pass.Password = true;
    } else if (obj.MobileNo !== '') {
      obj.validation.className.Password = 'form-control is-invalid';
      pass.Password = false;
    } else {
      obj.validation.className.Password = 'form-control';
      pass.Password = false;
    }

    if (!obj.OTPbaselogin) {
      obj.OTPSent = false;
    }

    if (obj.OTPSent && obj.OTP !== '') {
      obj.validation.className.OTP = 'form-control is-valid';
    } else if (obj.OTPSent) {
      obj.validation.className.OTP = 'form-control is-invalid';
    } else {
      obj.validation.className.OTP = 'form-control';
    }

    pass.OTPbaselogin = obj.OTPbaselogin;
    obj.validation.isValid = (pass.MobileNo && (pass.Password || pass.OTPbaselogin));

    //console.log('onChangeUserInput : obj:after', obj);
    obj._confirmationMessage = '';
    setUserInput(obj);
  }

  function actOnEnter(event) {
    event.preventDefault();
    //console.log('actOnEnter:', event.target.name,userInput.validation.className[event.target.name],userInput.validation.isValid);
    if (event.keyCode === 13 && userInput.validation.className[event.target.name] === 'form-control is-valid') {
      switch (event.target.name) {
        case 'MobileNo':
          if (userInput.OTPbaselogin) {
            submitUserLogin();
          } else {
            document.getElementById("Password").focus();
          }
          break;
        case 'OTP':
        case 'Password':
          submitUserLogin();
          break;
        default:
          submitUserLogin();
      }
    }
    return false;
  }


  if (userState.isLoggedIn) {
    let returnUrl = redirectUrl(true);
    if (returnUrl.search(/^\/(dashboard)|(myaccount)|(my-profile)|(myprofile)|(payments)|(patientdetails)|(showfamilymemberform)|(showfamilymemberaddressform)/) !== -1) {
      //alert('hi');
      window.location.href = configData.server.base_url + returnUrl;
    } else {
      window.setTimeout(function () {
        // document.getElementsByClassName('genuinner-search-fetchCart')[0].click();
        fetchCart();
      }, 10);
      return (<Navigate from={'/Login'} to={returnUrl} />);
      // window.location.href = configData.server.base_url + returnUrl;
    }
  }

  return (
    <section className="login">
      <section className="breadcrumb-section">
        <Container>
          <div className="breadcrumb-section-inner">
            <nav aria-label="breadcrumb" className="breadcrumb-nav">
              <ol className="breadcrumb mt-0">
                <li className="breadcrumb-item"><NavLink to="/"><i className="icon-home1" /> Home</NavLink></li>
                <li className="breadcrumb-item"><NavLink to="">Login</NavLink></li>
              </ol>
            </nav>
          </div>
        </Container>
      </section>

      <Container>
        <div className="loginblock">
          <div className="row">
            <div className="col-lg-6">
              <div className="blocklogin">
                <h3>Verify Your Identity</h3>
                <div className="clr height20"></div>
                <div className={'confirm-message' + (userInput?._isSuccessed ? '' : ' error')
                  + (userInput?._confirmationMessage && userInput?._confirmationMessage !== '' ? '' : ' d-none')} >
                  {userInput?._confirmationMessage}
                  {userInput?._confirmationMessage2 && <><br /><strong contentEditable='false' dangerouslySetInnerHTML={{ __html: userInput?._confirmationMessage2.replaceAll('. ', '.<br />') }}></strong></>}
                </div>
                { /* <form action="/action_page.php" onSubmit="return false;" > */}
                {userInput.OTPSent ? (
                  <div className="form-group form-style mb-4">
                    <input name="OTP" id="OTP" type="text" value={userInput.OTP} onKeyUp={actOnEnter} onChange={onChangeUserInput} className={userInput.validation.className.OTP} placeholder="Enter OTP" />
                  </div>
                ) : (
                  <div className="form-group form-style mb-4">
                    <input name="MobileNo" id="MobileNo" type="mobile" value={userInput.MobileNo} onKeyUp={actOnEnter} onChange={onChangeUserInput} className={userInput.validation.className.MobileNo} placeholder="Enter Mobile Number" />
                  </div>
                )}

                {!userInput.OTPbaselogin ? (
                  <div className="form-group form-style mb-3">
                    <div href="#" className={userInput.eleEye.className} id="eyeclick" onClick={passFunction} >
                      <i className="icon-eye-blocked" />
                      <i className="icon-eye" />
                    </div>
                    <input name="Password" id="Password" type={userInput.eleEye.type} onKeyUp={actOnEnter} onChange={onChangeUserInput} className={userInput.validation.className.Password} placeholder="Password" />
                  </div>
                ) : ''}

                <div className="forgot-otp  mb-4">
                  <label className="form-check-label"  >
                    <input name="OTPbaselogin" id="OTPbaselogin" type="checkbox" onChange={onChangeUserInput} onClick={onChangeUserInput} value={'OTPbaselogin'} checked={userInput.OTPbaselogin ? true : false} className="form-check-input" style={{ display: 'none' }} />
                    {userInput.OTPbaselogin ? 'Login with Password' : 'Login with Verification Code'}</label>
                  <span > &nbsp; </span>
                  {userInput.OTPSent ? (
                    <a className="link-in-black" id="reSendOTP" onClick={reSendOTP} >Resend OTP</a>
                  ) : (
                    <NavLink className="link-in-black" to="/forgot-password">Forgot Password?</NavLink>
                  )}
                </div>
                <div className='col70'>
                  <button id="btnLogin" type="button" onClick={submitUserLogin} className="btn btn-login2">LOGIN</button>
                  <div className='clr height15' />
                  <div className='text-center'>OR</div>
                  <div className='clr' />
                  <div className='text-center'>
                    <NavLink to="/sign-up" className="btn btn-link text-signup">Sign Up Here</NavLink>
                  </div>
                  <div className="clr height20" />
                </div>
                { /* </form> */}
                <div style={{ display: 'none' }}>
                  <div className="login-with"><span>OR LOGIN WITH</span></div>
                  <div className="clr height20" />
                  <div className="btn-group-social">
                    <a href={'#'} className="btn btn-social facebook"><i className="icon-facebook" /> Facebook</a>
                    <a href={'#'} className="btn btn-social google"><i className="icon-google-plus" /> GOOGLE</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="login-content ">
                <div className="row">
                  <div className="col-lg-6">
                    <h3 className="heading">New User?</h3>
                    <p><big>A <NavLink className="text-signup" to="/sign-up">Customer Registration</NavLink>  will help us,</big></p>
                    <p>Build a more meaningful and interactive relationship with you.</p>
                    <p>Increased Usability for you.</p>
                    <p>Build a Greater Feedback System.</p>
                    <p>Create a Personalised Experience.</p>
                    <p>Keep track of your Past &amp; Present Test Bookings.</p>
                    <p>Recommend you the best in Market as per your choices and interests.</p>
                    <a href={'#'} className="btn btn-register" style={{ display: 'none' }}>REGISTER HERE</a>
                  </div>
                  <div className="col-lg-6 imageblock"><img src={configData.server.cdn_url + "/images/login-graphics.png"} alt="" className="img-fluid" /></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <VerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </section>
  );
}

export default Login;