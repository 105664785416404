import { useState, useContext, useEffect } from 'react';
import { Link, NavLink, useLocation } from "react-router-dom";
import { Container, Dropdown } from "react-bootstrap";
import { setElementVal, clone, hideDropdown, VerticallyCenteredModal, initSessionData, getConfigData } from "../services/Helper";
import { PostData, GetData } from "../services/SendData";
import { cartContext } from '../services/userHandler';
import { toggleMenu } from "../services/Helper";
//import InitSearchTest from "../services/InitSearchTest.json";

import Menu from './Menu';
import SearchLocation from "../sections/NavLocation";
import SearchTest from "../sections/SearchTest";
import UserAccount from "../sections/UserAccount";
import HeaderCareer from "./HeaderCareer";

var winTimerId;

var queryStarted = {
  knowYourPatientLocation: false,
  knowYourPatientLocationTimeout: null,
  knowYourPatientLocationInitCalled: false
}



function Header(props) {


  const location = useLocation();
  const [myModal, setMyModal] = useState({ show: false, message: '', fnCallBack: false });


  //sticky-----
  const [scroll, setScroll] = useState(false)

  //End-sticky----

  const userData = props.userData;
  const configData = getConfigData();
  const server = configData.server;
  const initData = props.initData;

  //console.log('Config Data:', configData);cdn_url
  //const [moveToHome, setMoveToHome] = useState(false);
  //const [cartList, setCartItems] = useState(InitSearchTest.CartList);
  const [userInput, setUserInput] = useState({
    MobileNo: "",
    validation: {
      isValidMobileNo: false,
      className: {
        MobileNo: 'form-control',
      }
    }
  });

  const { cartState } = useContext(cartContext);





  function addVisitor() {
    if (userInput.validation.isValidMobileNo) {
      const objInput = { 'MobileNo': userInput.MobileNo, 'responseType': 'json' }
      PostData('/contactus/addVisitor', objInput, props).then((result) => {
        let jResponse = result;

        // setModalShow(true);
        // setElementVal([{ id: "VerticallyCenteredModal-message", html: jResponse.message }]);
        setMyModal({ show: true, message: jResponse?.message || 'Thanking for your interest.' });


        var objUserInput = { ...userInput }
        objUserInput.MobileNo = "";
        objUserInput.validation.isValidMobileNo = false;
        objUserInput.validation.className.MobileNo = 'form-control';
        setUserInput(objUserInput);
        //console.log(jResponse);

      });


    } else {
      // setModalShow(true);
      // setElementVal([{ id: "VerticallyCenteredModal-message", html: "<span>Sorry! Your  Mobile Number is not valid</span>" }]);
      setMyModal({ show: true, message: "<span>Please enter your valid  Mobile Number.</span>" });
    }
    return false;
  }



  function onChangeUserInput(e) {

    let pass = { MobileNo: false };
    let mibileFormat = /^[1-9]\d{9}$/;
    let obj = clone(userInput);

    //console.log('onChangeUserInput : obj:before', obj);

    obj[e.target.name] = e.target.value;


    //console.log(e.target.name, e.target.value, obj.MobileNo);

    if (obj.MobileNo && obj.MobileNo.match(mibileFormat)) {
      obj.validation.className.MobileNo = 'form-control is-valid';
      pass.MobileNo = true;
    } else if (obj.MobileNo === '') {
      obj.validation.className.MobileNo = 'form-control';
      pass.MobileNo = false;

    } else {
      obj.validation.className.MobileNo = 'form-control is-invalid';
      pass.MobileNo = false;
    }

    obj.validation.isValidMobileNo = pass.MobileNo;

    //console.log('onChangeUserInput : obj:after', obj);

    setUserInput(obj);
  }

  const refresh = () => window.location.reload(true);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (document.getElementById("showSearchDiv")) {
        document.getElementById("showSearchDiv").classList.add("d-none");
      }
      if (document.getElementById("txtTestDataSearch")) {
        var element = document.getElementById("txtTestDataSearch");
        element.blur();
      }

      setScroll(window.scrollY > 200);
    });

    document.addEventListener("DOMContentLoaded", function (event) {
      //do work
      //alert('DOMContentLoaded');
      document.getElementById("txtTestDataSearch").addEventListener("keyup", function (event) {
        var x = document.getElementById('showSearchDiv');
        //console.log("txtTestDataSearch:event", event.target.value, event);
        if (event.target.value && event.target.value.length >= 2) {
          x.style.display = 'block';

        } else {
          //x.classList.add("d-none");
          // x.style.display = 'none';
          //document.getElementsByClassName('clear-search-result')[0].click();
        }
      });
    });

  }, [])


  if (winTimerId) {
    window.clearTimeout(winTimerId.clear);
    //console.log('TimeOut');
  }

  // if (moveToHome) {
  //   return (<Navigate to={'/'} />);
  // }


  if (!queryStarted.knowYourPatientLocationInitCalled) {
    queryStarted.knowYourPatientLocationTimeout = window.setTimeout(function () { knowYourPatientLocation() }, 100);
    queryStarted.knowYourPatientLocationInitCalled = true;
  }



  return (
    <header className={scroll ? "sticky" : ""} style={location.pathname === "/career" ? { display: 'none' } : {}}>
      <a href={'#'} className='d-none' name="know-your-patient-location" id="know-your-patient-location" onClick={function (e) {
        e.preventDefault();
        knowYourPatientLocation(true);
      }}> &nbsp; </a>
      <button style={{ fontSize: "0.1px" }} className='d-none' id="btn_refresh" onClick={refresh}>Refresh</button>
      <div className="header-bg">
        <Container>
          <div className="header-part">
            <div className="logo">
              <div className="mob-menu" onClick={toggleMenu}><i className="icon-menu" /></div>
              <NavLink target='_self' to="/" className="genulogo" ><img src={server.cdn_url + "/images/genu-logo.png"} className="img-fluid" alt="" /></NavLink>
            </div>
            <div className="menu-part">
              <ul className="h-toplist">
                <li>
                  <Dropdown>
                    <Dropdown.Toggle className="block-location">
                      <span id="elePatientLocation" data-patient_pincode="" data-patient_location="" data-patient_addressid=""   ><i className="icon-location"></i> UPDATE LOCATION <i className="icon-crosshair left6"></i></span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="eleFindLocations d-menu maxwidth4">
                      <SearchLocation userData={userData} configData={configData} server={server} />
                      <div className="d-footer"></div>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>

                <li className="d-none">
                  <Dropdown>
                    <Dropdown.Toggle className="block-location">
                      EN <i className="icon-up-down left6 isize9"></i>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="d-menu maxwidth2">
                      <div className="lan-heading">SELECT YOUR LANGUAGE</div>
                      <div className="cities-block-scroll">
                        <ul className="user-lan-list">
                          <li key='LANGUAGE' className="active"><NavLink>English</NavLink></li>
                          {/*
                        <li><a href={'#'} >हिन्दी</a></li>
                        <li><a href={'#'} >বাংলা</a></li>
                        <li><a href={'#'} >Other1</a></li>
                        <li><a href={'#'} >Other2</a></li>
                        <li><a href={'#'} >Other3</a></li>
                      */}
                        </ul>
                      </div>
                      <div className="d-footer"></div>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>

                <li>
                  <UserAccount userData={userData} configData={configData} server={server} />
                </li>

                <li>
                  <Dropdown>
                    <Dropdown.Toggle className="block-cart"  >
                      <i className="icon-cart-genu"  ></i> <span className="cartvalue" id="total-cart-items"  >{cartState.TotalCartItems} </span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="d_menu_cart_summary d-menu max-width04">

                      {cartState.TotalCartItems === 0 ? <></> : <>
                        <div className="clr height20" />
                        <h4 className="cart-heading">BOOKING SUMMARY <span className="summary-value">{cartState.TotalCartItems} test{cartState.TotalCartItems > 1 ? 's' : ''}</span></h4>
                        <h4 className="cart-heading summary-lab"><span className="summary-value">GENU PATH LABS</span></h4>
                        <div className="clr height5" />
                        <div className="summary-list">
                          <ul >
                            {cartState.CartItem.map(item =>
                              (item.PkgServiceId === null || parseInt(item.PkgServiceId) === 0)
                                ? (<li key={'Service-item' + item.ServiceId.toString()} >
                                  <div className="test-name">{item.ServiceName}</div>
                                  <div className='pricr-block'>
                                    <del className={'prev-price' + (parseInt(item.Fees) <= parseInt(item.OfferFees) ? ' d-none' : '')} >₹{Intl.NumberFormat('en-IN', { maximumSignificantDigits: 10 }).format(parseInt(item.Fees))}</del>
                                    {item.Discount > 0 ? (<small className='poff text-success' >₹{Intl.NumberFormat('en-IN', { maximumSignificantDigits: 10 }).format(parseInt(item.Discount))} Off</small>) : ''}

                                    <div className="test-value">₹{Intl.NumberFormat('en-IN', { maximumSignificantDigits: 10 }).format(parseInt(item.OfferFees))}</div>
                                  </div>
                                </li>)
                                : ''
                            )}
                          </ul>
                        </div>
                      </>


                      }
                      {(cartState.CartItemDiscount || cartState.PromoId || cartState.CouponId || cartState.Collection_Charges) ? (
                        <div className="discount-block">
                          <ul className='summary-discount'>
                            {cartState?.CartItemDiscount > 0 &&
                              <li key={'cartState-CartItemDiscount'} >
                                <span className='test-name'>Service Discount </span>
                                <span>- ₹{cartState.CartItemDiscount}</span>
                              </li>
                            }

                            {cartState?.PromoId &&
                              <li key={'cartState-PromoId'}>
                                <span className='test-name'>{cartState.PromoName} applied ({cartState.PromoDiscountType === 'A' ? '₹' : ''}{cartState.PromoDiscountValue}{cartState.PromoDiscountType === 'P' ? '%' : ''})</span>
                                <span>- ₹{cartState.PromoDiscount}</span>
                              </li>
                            }

                            {cartState?.CouponId &&
                              <li key={'cartState-CouponId'} >
                                <span className='test-name'>{cartState.CouponPromoCode} applied ({cartState.CouponDiscountType === 'A' ? '₹' : ''}{cartState.CouponDiscountValue}{cartState.CouponDiscountType === 'P' ? '%' : ''})</span>
                                <span>- ₹{cartState.CouponDiscount}</span>
                              </li>
                            }

                            {cartState.Collection_Charges > 0 &&
                              <li key={'cartState-Collection-Charges'} >
                                <span className='test-name'>Collection Charges </span>
                                <span>+ ₹{cartState.Collection_Charges}</span>
                              </li>
                            }

                            {cartState.TotalSavings > 0 &&
                              <li key={'cartState-YouSave'}  >
                                <strong className="test-name">You Save </strong>
                                <strong className="text-success">₹{cartState.TotalSavings}</strong>
                              </li>}



                          </ul>
                        </div>
                      ) : ''}

                      {cartState.TotalCartItems > 0 ? <>
                        <div className="total-cart-value">
                          <div className="toral-block">Total</div>
                          <div className="toral-value">₹{Intl.NumberFormat('en-IN', { maximumSignificantDigits: 10 }).format(parseInt(cartState.CartVal))}</div>
                        </div>

                      </> : <>
                        <div className="alert alert-warning alert-flex" style={{ width: '100%' }} ><i className="icon-warning"></i><span>You are yet to Book any test! <br /> Please click on Book Test to proceed.</span></div>

                      </>

                      }

                      <div className="clr" />
                      <div className="d-footer">
                        <div className="checkout-block">
                          {cartState.TotalCartItems === 0 ?
                            <a href={'#'} className="btn checkout"
                              onClick={function (event) {
                                event.preventDefault();
                                hideDropdown('d_menu_cart_summary dropdown-menu');
                                document.getElementById("txtTestDataSearch").focus();
                              }}
                            >Book Test </a>
                            :
                            <Link to="/cart" className={"btn checkout"}
                              onClick={function () { hideDropdown('d_menu_cart_summary dropdown-menu'); }} >
                              CHECKOUT <i className="icon-thin-right" /></Link>
                          }
                        </div>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
              </ul>
              <div className="clr" />

              <Menu userData={userData} configData={configData} server={server} initData={initData} />

            </div>
            <div className="headerclr"></div>
          </div>
          <div className="search-section">

            <SearchTest userData={userData} configData={configData} server={server} />

            <div className="number-block">
              <div className="numberwidth">
                <span>Need Assistance?</span>

                <Dropdown>
                  <Dropdown.Toggle className="jnumber">
                    <i className="icon-old-phone" /> 080-6950-6950
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="d-menu maxwidth4">
                    <div className="call-you-back">
                      <div className="call-heading">Want us to call you back ?</div>
                      <p>Please provide your number below and get a free call from our health advisor in a short while.</p>
                      <div className="clr" />
                      <input type="tel" name="MobileNo" id="MobileNo"
                        maxLength={10}
                        value={userInput.MobileNo} onChange={onChangeUserInput}
                        onKeyDown={(e) => {
                          if (e?.key == 'Enter') {
                            addVisitor();
                          }
                        }}
                        className={userInput.validation.className.MobileNo}
                        placeholder="Mobile *" />
                    </div>
                    <div className="d-footer freecall-block">
                      <a href={'#'} onClick={addVisitor} className="btn btn-freecall">GET A FREE CALL</a>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>

              </div>
            </div>
          </div>
        </Container>
      </div>
      <div className="header-height"></div>
      {/* <div id="back-top" className="wow fadeInRight" ><i className="icon-up-arrow-1"></i></div> */}
      <VerticallyCenteredModal
        show={myModal.show}
        message={myModal.message}
        onHide={() => setMyModal({ show: false, message: '', fnCallBack: false })}

      // setModalShow(false)
      />

    </header>

  );
}

export function knowYourPatientLocation(force) {

  const configData = getConfigData();
  const server = configData.server;
  //alert('force:'+force);
  force = typeof (force) !== 'undefined' ? force : false;

  var apiSegment = "/genulabhome/getcoockieval?cname=all";
  var pincode = document.getElementById("elePatientLocation").getAttribute("data-patient_pincode");
  var location = document.getElementById("elePatientLocation").getAttribute("data-patient_location");
  var addressid = document.getElementById("elePatientLocation").getAttribute("data-patient_addressid");
  //console.log('knowYourPatientLocation step:1', [pincode, location,addressid]);

  if (queryStarted.knowYourPatientLocationTimeout) {
    window.clearTimeout(queryStarted.knowYourPatientLocationTimeout);
  }

  if (queryStarted.knowYourPatientLocation) {
    // if (force) {
    //   queryStarted.knowYourPatientLocationTimeout = window.setTimeout(function () {
    //     knowYourPatientLocation(force);
    //   }, 5000);
    // }
    return false;
  }

  if (pincode === "" || location === "" || force) {
    //alert('1');
    queryStarted.knowYourPatientLocation = true;
    GetData(apiSegment, configData).then((result) => {



      let jResponse = result;
      //console.log('step:2', jResponse);
      if (typeof (jResponse.patientpincode) === 'undefined' || typeof (jResponse.patientlocation) === 'undefined' || jResponse.patientpincode === '' || jResponse.patientlocation === '') {

        //alert('2');
        if (window.location.href === server.base_url + '/' || window.location.href === "http://localhost:3000/") {
          //alert('3');
          //console.log("eleFindLocations show", document.getElementsByClassName("eleFindLocations show").length);
          if (document.getElementsByClassName("eleFindLocations show").length === 0) {
            document.getElementById("elePatientLocation").click();
          }


        }

      } else {
        pincode = jResponse.patientpincode;
        location = jResponse.patientlocation;
        addressid = typeof (jResponse.patientaddressid) !== 'undefined' && jResponse.patientaddressid ? jResponse.patientaddressid : '0';

        var objPatientLocation = [{}];
        objPatientLocation[0].id = "elePatientLocation";
        objPatientLocation[0].html = '<i class="icon-location"></i> ' + location + ' <i class="icon-crosshair left6"></i>';
        objPatientLocation[0].attr = [{}, {}, {}];
        objPatientLocation[0].attr[0].name = 'data-patient_pincode';
        objPatientLocation[0].attr[0].value = pincode;
        objPatientLocation[0].attr[1].name = 'data-patient_location';
        objPatientLocation[0].attr[1].value = location;
        objPatientLocation[0].attr[2].name = 'data-patient_addressid';
        objPatientLocation[0].attr[2].value = addressid;
        setElementVal(objPatientLocation);

        // ----------- reset package and test list :start ---------------
        var objInitData = initSessionData(); //clone(initData);
        objInitData.popularTests.Item = [];
        objInitData.popularPackages.Item = [];
        objInitData.popularPackages.allPackages = [];
        window.sessionStorage.setItem('initData', JSON.stringify(objInitData));
        // ----------- reset package and test list :end ---------------

      }

      window.setTimeout(function () { queryStarted.knowYourPatientLocation = false }, 5999)

    });
  }


}

export default Header;