import { GetData } from "../services/SendData";
import { getConfigData} from "../services/Helper";
import React, { useState } from 'react'
import { Container } from "react-bootstrap";

export function ReportAccuracy() {

  var objInitBookCountData = {}; //clone(initData);
  let jsinitBookCounts = window.sessionStorage.getItem('initBookCounts');
  if (jsinitBookCounts) {
    //console.log('App.session jsInitData', jsInitData);
    objInitBookCountData = JSON.parse(jsinitBookCounts);
    // setUserData( JSON.parse(jsUserData) );
  } else {
    objInitBookCountData = {
      LabTestBooked: '1,20,000',
      SatisfiedCustomer: '28,000+',
      Locations: '100+',
      Offers: '100+',
      TestCount: '1500+',
      isUpdated: false
    }
  }

  const configData = getConfigData();
  const [bookingCounts, setBookingCounts] = useState(objInitBookCountData);

  //console.log('config:' , configData.server.cdn_url);
  return (
    <section className="bg-off">
      <Container>
        <div className="clr height20" />
        <div className="controlreport">
          <h2 className="text-center heading-control">How we control Report Accuracy at GenupathLabs</h2>

          <div className="row">
            <div className="col-lg-3 pad-m5">
              <div className="control-block">
                <i className="icon-quality-control"></i>
                <h3 className="heading">Quality Control</h3>
                <p className="content">We follow quality control
                  to ensure both precision &#38; accuracy
                  of patient result</p>
              </div>
            </div>

            <div className="col-lg-3 pad-m5">
              <div className="control-block">
                <i className="icon-machine-data"></i>
                <h3 className="heading">Machine Data</h3>
                <p className="content">We save patient’s result values directly
                  from machines ensuring no
                  manipulations &#38; no fake values</p>
              </div>
            </div>

            <div className="col-lg-3 pad-m5">
              <div className="control-block">
                <i className="icon-qr-code"></i>
                <h3 className="heading">QR Code</h3>
                <p className="content">QR Code based authenticity check <br />
                  on all its reports</p>
              </div>
            </div>

            <div className="col-lg-3 pad-m5">
              <div className="control-block">
                <i className="icon-equipment"></i>
                <h3 className="heading">Equipment</h3>
                <p className="content">Our Labs are equiped with state-of
                  the-art instruments with cutting
                  edge technology to provide faster
                  &#38; reliable results.
                </p>
              </div>
            </div>

          </div>
        </div>
        <div className="clr height30 " />
        <div className="row pad19 happy-user-section justify-content-center">
          <div className="col-lg-7">
            <h3 className="happy-user"> <span className="no-value">{bookingCounts.SatisfiedCustomer}</span> Happy users who trust GenupathLabs</h3>
            <h3 className="know-text">KNOW ALL ABOUT YOUR HEALTH ON YOUR FINGERTIPS</h3>
            <ul className="list">
              <li>Book &#38; Track your health Tests </li>
              <li>Reports on your phone</li>
              {/* <li>Health Tracker</li>
              <li>Health Articles</li> */}
            </ul>
            <h3 className="download-text">DOWNLOAD GENUPATHLABS APP</h3>
            <a href={configData.app_urls.android} className="download-app"><img src={configData.server.cdn_url + "/images/google-play.png"} className="img-fluid" alt="" /></a>
            {/* <a href={'#'} className="download-app"><img src="/images/app-store.png" className="img-fluid" alt="" /></a> */}

          </div>
          <div className="col-lg-4">
            <img src={configData.server.cdn_url + "/images/genu-mob-app.png"} className="img-fluid" alt="" />
          </div>
        </div>
        <div className="clr height20" />
      </Container>
    </section>
  )

}

export default ReportAccuracy