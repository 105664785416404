
import { NavLink } from "react-router-dom";
import { Container } from "react-bootstrap";

function Booking() {
  return (

      <section className="Booking">

        <div className="login-top-menu">
          <Container>
            <ul className="menu">
              <li>
                <NavLink to="/booking" ><i className="icon-order-placed"></i> Booking</NavLink>
              </li>
              <li>
                <NavLink to="/" ><i className="icon-order-cancelled"></i> Order Cancelled</NavLink>
              </li>
              <li>
                <NavLink to="/" ><i className="icon-pending-approval"></i> Pending Approval</NavLink>
              </li>
              <li>
                <NavLink to="/" ><i className="icon-order-as-hold"></i> Order as hold</NavLink>
              </li>
              <li>
                <NavLink to="/" ><i className="icon-delivered"></i> Order to be delivered</NavLink>
              </li>
            </ul>
          </Container>

        </div>

        <Container>
          <div className="clr height20"></div>
          <div className="card">
            <div className="card-body">
              <h5 className="header-title mb-3">Booking List</h5>
              <div className="chart-content-bg">

                <form className="row">

                  <div className="col-lg-3 md-3">
                    <label for="staticEmail">Booking No</label>
                    <input type="text" className="form-control" placeholder="Booking No" />
                  </div>

                  <div className="col-lg-3 md-3">
                    <label for="staticEmail">Booking Date</label>
                    <input type="date" className="form-control" placeholder="Booking No" />
                  </div>

                  <div className="col-lg-3 mb-3">
                    <label for="staticEmail" className="l-block">&nbsp;</label>
                    <button className="btn btn-outline-primary">Clear Date</button>
                  </div>
                  <div className="clr height5"></div>

                  <div className="col-lg-3 mb-3">
                    <label for="staticEmail">Booking Place Start Date</label>
                    <input type="date" className="form-control" placeholder="Booking No" />
                  </div>

                  <div className="col-lg-3 mb-3">
                    <label for="staticEmail">Booking Place nd Date</label>
                    <input type="date" className="form-control" placeholder="Booking No" />
                  </div>

                  <div className="col-lg-3 mb-3">
                    <label for="staticEmail" className="l-block">&nbsp;</label>
                    <button className="btn btn-outline-primary">Clear Date</button>
                  </div>

                  <div className="clr height5"></div>

                  <div className="col-lg-3 mb-3">
                    <label for="staticEmail">BAppointment select</label>
                    <select className="form-select" aria-label="Default select example">
                      <option selected>Open this select menu</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                  </div>

                  <div className="col-lg-3 mb-3">
                    <label for="staticEmail">Appointment Requested</label>
                    <select className="form-select" aria-label="Default select example">
                      <option selected>Open this select menu</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                  </div>

                  <div className="col-lg-3 mb-3">
                    <label for="staticEmail" className="l-block">&nbsp;</label>
                    <button className="btn btn-primary btn-gap">Search</button>
                    <button className="btn btn-outline-primary">Reset</button>
                  </div>


                </form>

              </div>
            </div>
          </div>
          <div className="clr height20"></div>

          <div className="card table-responsive">
            <table className="table table-bordered">
              <tbody>
                <tr className="bg-h-light">
                  <td>Booking No</td>
                  <td>Customer/Patient</td>
                  <td>Booking Date</td>
                  <td>Appointment Date</td>
                  <td>Booking Amount</td>
                  <td>Pay Status</td>
                  <td>Booking Status</td>
                  <td>Visit Type</td>
                  <td>Prescription</td>
                  <td>Action</td>
                </tr>
                <tr>
                  <td>SS-FYSPPE</td>
                  <td>SUNIL KUMAR DAS (8335933902)</td>
                  <td>19-02-22</td>
                  <td>20-02-22 (07:15- 08:00)</td>
                  <td>1,445.00</td>
                  <td>Cash</td>
                  <td>Appointment Requested</td>
                  <td>Home</td>
                  <td>No Prescnption</td>
                  <td className="action">
                    <a href={'#'} className="btn btn-outline-primary btn-sm"><i className="icon-edit-1"></i></a>
                    <a href={'#'} className="btn btn-outline-success btn-sm"><i className="icon-account"></i></a>
                    <a href={'#'} className="btn btn-outline-primary btn-sm"><i className="icon-edit-1"></i></a>
                    <a href={'#'} className="btn btn-outline-danger btn-sm"><i className="icon-garbage"></i></a>

                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="clr height20"></div>


        </Container>
      </section>

  );
}

export default Booking;