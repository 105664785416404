var loaderCount = 0;

/**
 * 
 * @param {*} apiSegment *REQUIRED string :  API URI ONLY (except domain name) 
 * @param {*} inputData *REQUIRED  if not available please supply {}
 * @param {*} configs *REQUIRED server configs
 * @param {*} showLoader ?OPTIONAL - Default TRUE
 * @returns 
 */
function PostData(apiSegment, inputData, configs, showLoader) {
    let baseURL = configs.server.base_url;
    let flgShowLoader = typeof (showLoader) !== 'undefined' ? showLoader : true;
    let csrf_token = typeof (configs.csrf_token) !== 'undefined' ? configs.csrf_token : (typeof (configs.configData.csrf_token) !== 'undefined' ? configs.configData.csrf_token : '');

    if (inputData.senddata) {
        inputData.senddata.callFrom = 'react';
    } else {
        inputData.callFrom = 'react';
    }

    return new Promise((resolve, reject) => {
        if (flgShowLoader && document.getElementById('genuloader')) {
            loaderCount++;
            //console.log(apiSegment + ' [fetch]>> Loader Count :' + loaderCount);
            document.getElementById('genuloader').style.display = 'block';
        }

        fetch(baseURL + apiSegment, {
            method: 'POST',
            body: JSON.stringify(inputData),
            headers: { 'Content-Type': 'application/json', 'X-CSRF-TOKEN': csrf_token, 'call-from': 'react' }
        })
            .then((response) => {
                if (response?.status === 419) {
                    // window.location.reload;
                    // document.getElementsByClassName('genulogo')[0].click();
                    document.getElementById('btn_refresh').click();
                }
                // console.log('response.status: ', apiSegment, response.status);
                return response.json();
            })
            .then((res) => {
                loaderCount = flgShowLoader ? (loaderCount - 1) : loaderCount;
                //console.log(apiSegment + ' [res]>> Loader Count :' + loaderCount);

                if (loaderCount <= 0 && document.getElementById('genuloader')) {
                    document.getElementById('genuloader').style.display = 'none';
                    loaderCount = 0;
                }

                resolve(res);
            })
            .catch((error) => {
                console.log('error:', apiSegment, error);
                loaderCount = flgShowLoader ? (loaderCount - 1) : loaderCount;
                //console.log(apiSegment + ' [error]>> Loader Count :' + loaderCount);
                if (loaderCount <= 0 && document.getElementById('genuloader')) {
                    document.getElementById('genuloader').style.display = 'none';
                    loaderCount = 0;
                }
                reject(error);
            });
    });
}

/**
 * 
 * @param {*} apiSegment *REQUIRED string :  API URI ONLY (except domain name) 
 * @param {*} configs *REQUIRED configs.server.base_url
 * @param {*} showLoader ?OPTIONAL - Default FALSE
 * @returns 
 */
function GetData(apiSegment, configs, showLoader) {
    let baseURL = configs.server.base_url;
    let flgShowLoader = typeof (showLoader) !== 'undefined' ? showLoader : false;

    //console.log(baseURL + apiSegment);
    apiSegment = apiSegment + (apiSegment.indexOf("?") === -1 ? '?' : '&') + 'callFrom=react';
    return new Promise((resolve, reject) => {
        if (flgShowLoader && document.getElementById('genuloader')) {
            loaderCount++;
            //console.log(apiSegment + ' [fetch]>> Loader Count :' + loaderCount);
            document.getElementById('genuloader').style.display = 'block';
        }

        fetch(baseURL + apiSegment, {
            method: 'GET'
        }).then((response) => {
            if (response?.status === 419) {
                // window.location.reload;
                // document.getElementsByClassName('genulogo')[0].click();
                document.getElementById('btn_refresh').click();
            }
            // console.log('response.status: ', apiSegment, response.status);
            return response.json();
        })
            .then((res) => {
                loaderCount = flgShowLoader ? (loaderCount - 1) : loaderCount;
                //console.log(apiSegment + ' [res]>> Loader Count :' + loaderCount);
                if (loaderCount <= 0 && document.getElementById('genuloader')) {
                    document.getElementById('genuloader').style.display = 'none';
                }
                resolve(res);
            })
            .catch((error) => {
                loaderCount = flgShowLoader ? (loaderCount - 1) : loaderCount;
                //console.log(apiSegment + ' [res]>> Loader Count :' + loaderCount);
                if (loaderCount <= 0 && document.getElementById('genuloader')) {
                    document.getElementById('genuloader').style.display = 'none';
                }
                reject(error);
            });
    });
}

/**
 * 
 * @param {*} method *REQUIRED string : GET / POST 
 * @param {*} apiSegment *REQUIRED string :  API URI ONLY (except domain name) 
 * @param {*} inputData *REQUIRED  if not available please supply {}
 * @param {*} configs *REQUIRED server configs
 * @param {*} showLoader ?OPTIONAL - Default TRUE
 * @param {*} ctrl ?OPTIONAL - new AbortController() to helps to Abort in the midway of the call - Default {}
 * @param {*} fnFeedBack ?OPTIONAL - Default function(){}
 */
function fetchData(method, apiSegment, inputData, configs, showLoader, ctrl, fnFeedBack) {

    //console.log('LOG 1 - method:', method, 'apiSegment:', apiSegment, 'inputData:', inputData, 'configs:', configs, 'showLoader:', showLoader, 'ctrl:', ctrl, 'fnFeedBack:', fnFeedBack);


    let baseURL = configs.server.base_url;
    let csrf_token = typeof (configs.csrf_token) !== 'undefined' ? configs.csrf_token : (typeof (configs.configData.csrf_token) !== 'undefined' ? configs.configData.csrf_token : '');
    method = typeof (method) !== 'undefined' ? method.toUpperCase() : 'POST'
    showLoader = typeof (showLoader) !== 'undefined' ? showLoader : true;

    if (typeof (ctrl) === 'function') {
        fnFeedBack = ctrl;
        ctrl = {};
    } else {
        ctrl = typeof (ctrl) !== 'undefined' ? ctrl : {}
    }

    fnFeedBack = typeof (fnFeedBack) ? fnFeedBack : function () { }


    //console.log('LOG 2 - method:', method, 'apiSegment:', apiSegment, 'inputData:', inputData, 'configs:', configs, 'showLoader:', showLoader, 'ctrl:', ctrl, 'fnFeedBack:', fnFeedBack);

    if (inputData.senddata) {
        inputData.senddata.callFrom = 'react';
    } else {
        inputData.callFrom = 'react';
    }
    //console.log('ctrl:', ctrl);
    if (showLoader && document.getElementById('genuloader')) {
        loaderCount++;
        //console.log(apiSegment + ' [fetch]>> Loader Count :' + loaderCount);
        document.getElementById('genuloader').style.display = 'block';
    }



    try {
        fetch(baseURL + apiSegment, {
            method: method,
            body: JSON.stringify(inputData),
            headers: { 'Content-Type': 'application/json', 'X-CSRF-TOKEN': csrf_token, 'call-from': 'react' },
            signal: (typeof (ctrl.signal) !== 'undefined' ? ctrl.signal : null)
        }).then((response) => {
            if (response?.status === 419) {
                // window.location.reload;
                // document.getElementsByClassName('genulogo')[0].click();
                document.getElementById('btn_refresh').click();
            }
            // console.log('response.status: ', apiSegment, response.status);
            return response.json();
        })
            .then((res) => {
                loaderCount = showLoader ? (loaderCount - 1) : loaderCount;
                //console.log(apiSegment + ' [res]>> Loader Count :' + loaderCount);
                if (loaderCount <= 0 && document.getElementById('genuloader')) {
                    document.getElementById('genuloader').style.display = 'none';
                    loaderCount = 0;
                }
                return fnFeedBack(res);
                //resolve(res);
            })
            .catch((error) => {
                if (error.name == 'AbortError') { // handle abort()
                    console.log("error Aborted!", error);
                }
                loaderCount = showLoader ? (loaderCount - 1) : loaderCount;
                //console.log(apiSegment + ' [error]>> Loader Count :' + loaderCount);
                if (loaderCount <= 0 && document.getElementById('genuloader')) {
                    document.getElementById('genuloader').style.display = 'none';
                    loaderCount = 0;
                }
                return fnFeedBack(error);
                //reject(error);
            });

    } catch (err) {
        if (err.name == 'AbortError') { // handle abort()
            console.log("err Aborted!", err);
        } else {
            throw err;
        }
    }
}

async function getUserData(objConfig, fnCallback) {
    fnCallback = typeof (fnCallback) === 'function' ? fnCallback : function (userData) { return userData; }
    // let objConfig = getConfigData();
    let flgShowLoader = true;
    let myPromise = new Promise((resolve, reject) => {
        if (flgShowLoader && document.getElementById('genuloader')) {
            loaderCount++;
            //console.log(apiSegment + ' [fetch]>> Loader Count :' + loaderCount);
            document.getElementById('genuloader').style.display = 'block';
        }

        fetch(objConfig.server.base_url + '/genulabhome/hasLogedIn', {
            method: 'GET'
        })
            .then((response) => response.json())
            .then((jResponse) => {
                loaderCount = flgShowLoader ? (loaderCount - 1) : loaderCount;
                //console.log(apiSegment + ' [res]>> Loader Count :' + loaderCount);
                if (loaderCount <= 0 && document.getElementById('genuloader')) {
                    document.getElementById('genuloader').style.display = 'none';
                }
                let objUserData = {
                    displayName: "",
                    mobileNo: "",
                    emailId: "",
                    authTokenId: null,
                    isLoggedIn: false,
                    IsSmartReportExists: false,
                    hasMinInfo: false,
                    HasMinInfo: 0
                };

                console.log('jResponse hasMinInfo', jResponse);
                if (jResponse.status) {
                    objUserData.displayName = jResponse?.data?.DisplayName;
                    objUserData.mobileNo = jResponse?.data?.MobileNo;
                    objUserData.emailId = jResponse?.data?.EmailId;
                    objUserData.isLoggedIn = true;
                    objUserData.IsSmartReportExists = parseInt(jResponse?.data?.IsSmartReportExists || '0') === 1 ? true : false;
                    objUserData.hasMinInfo = (jResponse?.data?.HasMinInfo && parseInt(jResponse.data.HasMinInfo) >= 15) ? true : false;
                    objUserData.HasMinInfo = jResponse?.data?.HasMinInfo;
                    window.sessionStorage.setItem('userData', JSON.stringify(objUserData));
                } else {
                    // objUserData.displayName = '';
                    // objUserData.mobileNo = '';
                    // objUserData.isLoggedIn = false;
                    // objUserData.IsSmartReportExists = false;
                    // objUserData.hasMinInfo = false;
                    // objUserData.HasMinInfo = 0;
                    window.sessionStorage.setItem('userData', JSON.stringify(objUserData));
                }

                resolve(objUserData);
            })
            .catch((error) => {
                loaderCount = flgShowLoader ? (loaderCount - 1) : loaderCount;
                //console.log(apiSegment + ' [res]>> Loader Count :' + loaderCount);
                if (loaderCount <= 0 && document.getElementById('genuloader')) {
                    document.getElementById('genuloader').style.display = 'none';
                }
                reject(error);
            });
    });

    return await myPromise;
}
function promiseState(p) {
    const t = {};
    return Promise.race([p, t])
        .then(v => (v === t) ? "pending" : "fulfilled", () => "rejected");
}

/**
 * 
 * @param {*} apiSegment *REQUIRED string :  API URI ONLY (except domain name) 
 * @param {*} inputData *REQUIRED  if not available please supply {}
 * @param {*} fnCallback *REQUIRED
 * @param {*} configs *REQUIRED server configs
 * @param {*} showLoader ?OPTIONAL - Default TRUE
 * @returns 
 */

function getAjaxPage(apiSegment, inputData, fnCallback, configs, showLoader) {

    let baseURL = configs.server.base_url;
    let flgShowLoader = typeof (showLoader) !== 'undefined' ? showLoader : false;
    let csrf_token = typeof (configs.csrf_token) !== 'undefined' ? configs.csrf_token : (typeof (configs.configData.csrf_token) !== 'undefined' ? configs.configData.csrf_token : '');
    if (typeof (fnCallback) !== 'function') {
        fnCallback = function (result) { console.log('fnCallback::', result); }
    }
    //    const fnCallbackEx = typeof (fnCallback) === 'function' ? fnCallback : function (result) { console.log( 'fnCallback::',result); }

    if (flgShowLoader) {
        console.log('show loader...getHTML');
    }
    // console.log('show loader...getAjaxPage',apiSegment, inputData, fnCallback, configs,  showLoader);
    inputData = typeof (inputData) !== 'undefined' ? inputData : {}
    if (inputData.senddata) {
        inputData.senddata.callFrom = 'react';
    } else {
        inputData.callFrom = 'react';
    }


    let post = JSON.stringify(inputData)
    // const url = "https://jsonplaceholder.typicode.com/posts"
    let xhr = new XMLHttpRequest()
    xhr.open('POST', baseURL + apiSegment, true)
    xhr.setRequestHeader('Content-type', 'application/json; charset=UTF-8');
    xhr.setRequestHeader('X-CSRF-TOKEN', csrf_token);
    xhr.setRequestHeader('call-from', 'react');
    xhr.onload = function () {
        if (this.status === 201) {
            console.log("Post successfully created!")
        }
    }
    xhr.onreadystatechange = function () {
        if (this.readyState === 4 && this.status === 200) {
            // Inserting the response from server into an HTML element
            //document.getElementById("result").innerHTML = this.responseText;
            fnCallback(this.responseText);
        }
    }

    xhr.send(post);


}

export { PostData, GetData, fetchData, getUserData, promiseState, getAjaxPage }