import { NavLink } from "react-router-dom";
import { scroller } from "react-scroll";
import { useNavigate } from "react-router-dom";

const d = new Date();
const dYear = d.getFullYear();

function Footer(props) {
    //const userData = props.userData;
    const configData = props.configData;
    const server = props.server;
    const navigate = useNavigate();

    const scrollToSection = (href) => {
        //event.preventDefault();
        var arrHref = href.split("scrollTo/");
        if (arrHref.length === 2) {
            arrHref = arrHref[1].split("/");
        }

        var offset = typeof arrHref[2] !== "undefined" ? arrHref[2] : -5;
        //    offset

        window.setTimeout(function () {
            scroller.scrollTo(arrHref[1], {
                duration: 400,
                delay: 0,
                smooth: "easeInOutQuart",
                offset: offset,
            });
        }, 200);

        if (arrHref[0] === "home") {
            navigate("/");
        }
    };

    window.setTimeout(function () {
        //scrollToSection()
        //alert(window.location);

        var arrHref = window.location.href.split("/#");
        if (arrHref.length === 2) {
            //console.log('arrHref', arrHref);
            // arrHref = arrHref[1].split('/#');
            // console.log('arrHref', arrHref);
            switch (arrHref[1]) {
                case "WhyGenu":
                    scrollToSection(
                        "scrollTo/home/Why-Choose-GENU-Pathlab/-100"
                    );
                    break;
                case "equipments":
                    scrollToSection(
                        "scrollTo/home/TEST-EQUIPMENTS-AT-GENU-PATHLABS"
                    );
                    break;
            }
        }

        //http://localhost:3000/#WhyGenu
    }, 2000);

    return (
        <footer>
            <div
                className="genuloader"
                id="genuloader"
                style={{ display: "none" }}
            >
                <div className="genuinner">
                    <img src={server.cdn_url + "/images/testLoad.gif"} alt="" />
                </div>
                <div className="genuloaderstyle"></div>
            </div>

            <div className="clr height80" />
            <div className="container">
                <div className="clr height20" />
                <div className="row">
                    <div className="col-lg-3">
                        <h2 className="footer-heading">POLICIES</h2>
                        <ul className="footermenu">
                            <li>
                                <a href={server.base_url + "/privacy-policy"}>
                                    Privacy Policy
                                </a>
                            </li>
                            {/*<li><a href="/">Disclaimer</a></li>
              <li><a href="/scrollTo/F-A-Q"  onClick={scrollToSection} >FAQ</a></li>*/}
                            <li>
                                <a href={server.base_url + "/terms-condition"}>
                                    Terms &amp; Conditions
                                </a>
                            </li>
                            {/*<li><a href="/">Return, Cancellation &amp; Refund Policy</a></li>*/}
                        </ul>
                    </div>
                    <div className="col-lg-3">
                        <h2 className="footer-heading">CUSTOMER SERVICES</h2>
                        <ul className="footermenu">
                            <li>
                                <a href={server.base_url + "/about"}>
                                    About Us
                                </a>
                            </li>
                            <li>
                                <a href={server.base_url + "/contact"}>
                                    Contact Us
                                </a>
                            </li>
                            <li>
                                <a href={"/#WhyGenu"}>Why GENU pathlabs ?</a>
                            </li>
                            <li>
                                <a href={server.base_url + "/diagnosis/list"}>
                                    Booking
                                </a>
                            </li>
                            {/*<li><a href="/">About Media</a></li>*/}
                        </ul>
                    </div>
                    <div className="col-lg-3">
                        <h2 className="footer-heading">VALUE PROPOSITIONS</h2>
                        <ul className="footermenu">
                            {/*<li><a href="/">Health Articles</a></li>
              <li><a href="/scrollTo/TESTIMONIALS"  onClick={scrollToSection}    >Testimonials</a></li>*/}
                            <li>
                                <a href="/#equipments">
                                    Advanced automated equipments
                                </a>
                            </li>
                            {/*<li><a href="/">External service partners</a></li>
              <li><a href="/">Home Collection</a></li>*/}
                            <li>
                                <NavLink to="career">Career</NavLink>
                            </li>

                            <li>
                                <NavLink to="directory-of-services">Directory of Services</NavLink>
                            </li>
                        </ul>
                    </div>
                    <div className="col-lg-3 sec-feedback">
                        <h2 className="footer-heading">
                            COMPLAINTS / FEEDBACK
                        </h2>
                        <p className="co-about">
                            Have any complaints or suggestions? Just tell us. We
                            are here to help you with everything.
                        </p>
                        <div className="contactlist">
                            <a href={"tel:"+configData.customer_care._phone} >
                                <i className="icon-old-phone" /> {configData.customer_care.phone}
                            </a>
                        </div>
                        <div className="contactlist">
                            <a href={"mailto:"+configData.customer_care.email}>
                                <i className="icon-envelope-fill" />{" "}
                                {configData.customer_care.email}
                            </a>
                        </div>
                        <div className="clr height20" />
                        <div className="genusocial">
                            <a
                                href="https://twitter.com/GenuPathlabs"
                                title="Twitter"
                                target={"_blank"}
                            >
                                <img src={server.cdn_url +"/images/social/twtter-x.png"} alt="Twitter-X" />
                                {/* <i className="icon-twitter"></i> */}
                            </a>
                            <a
                                href="https://www.facebook.com/genupathlabs"
                                title="Facebook"
                                target={"_blank"}
                            >
                                <img src={server.cdn_url + "/images/social/facebook.png"} alt="Facebook" />
                                {/* <i className="icon-facebook"></i> */}
                            </a>
                            <a
                                href="https://www.linkedin.com/company/genupathlabs/"
                                title="LinkedIn"
                                target={"_blank"}
                            >
                                <img src={server.cdn_url + "/images/social/linkedin.png"} alt="Linkedin" />
                                {/* <i className="icon-linkedin"></i> */}
                            </a>
                            <a
                                href="https://www.youtube.com/@genupathlabs"
                                title="Youtube"
                                target={"_blank"}
                            >
                                 <img src={server.cdn_url + "/images/social/youtube.png"} alt="Youtube" />
                                {/* <i className="icon-youtube"></i> */}
                            </a>
                        </div>
                    </div>
                    <div className="clr height30" />
                </div>
            </div>
            <div className="copyright">
                © Copyright 2018-{dYear} &nbsp;
                <a href="http://genupathlabs.com/">Genupathlabs.com</a> All
                rights reserved.
            </div>
        </footer>
    );
}

export default Footer;
