import React from "react";
import { Link } from "react-router-dom";

function SectionTitle({ subtitle, title, viewAllLink }) {
  return (
    <>
      <div className="clr height30" />
      {!!subtitle && <div className="smallertex"> {subtitle}</div>}
      {!!title && <h2 className="heading-style list-inline-item">{title}</h2>}
      {!!viewAllLink &&
        <Link to="/diagnostic-test" className="btn btn-viewall-right float-end">
          View All <i className="icon-thin-right"></i>
        </Link>
      }
      <div className="clr height20" />
    </>
  );
}

export default SectionTitle;
