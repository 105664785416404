import React, {useState} from 'react';

const GoTo = () =>{
  const [visible, setVisible] = useState(false)
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300){
      setVisible(true)
    } 
    else if (scrolled <= 300){
      setVisible(false)
    }
  };
  const scrollToTop = () =>{
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };
  
  window.addEventListener('scroll', toggleVisible);
  
  return (
    <>
     <div className="move-up" onClick={scrollToTop} style={{display: visible ? 'inline' : 'none'}}>
          <i className="icon-up-arrow-1"></i>
      </div>
    </>
  );
}
  
export default GoTo;