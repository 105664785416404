import { createContext } from 'react';


function getInitialState() {
  var jUserData = {};
  let jsUserData = window.sessionStorage.getItem('userData');
  // console.log('getInitialState.session jsUserdarta', jsUserData);
  if (jsUserData) {
    jUserData = JSON.parse(jsUserData);
  } else {
    jUserData = {
      displayName: "",
      emailId: "",
      mobileNo: "",
      authTokenId: null,
      isLoggedIn: false

    }
  }
  //console.log('getInitialState.session jsUserdarta jUserData', jUserData);
  return jUserData;
}

function getInitialCartState() {
  var jUserCartData = {};
  jUserCartData = {
    CartAmount: 0,
    CartId: null,
    CartItemDiscount: 0,
    CartVal: 0,
    CouponDiscount: null,
    CouponId: null,
    CouponDiscountType: null,
    CouponDiscountValue: null,  
    CouponPromoCode: null,
    CouponPromoName: null,
    PromoId: null,
    PromoName: null,    
    PromoDiscount: null,
    PromoDiscountType: null,
    PromoDiscountValue: null,
    PatientId:null,
    ServiceDeliveryAddressId:null,
    RoundOffVal: 0,
    ScheduleType: null,
    ServiceDeliveryAddressId: null,
    StateId: null,
    TotalCartItems: 0,
    TotalSavings:0,
    IsHomeCollectionCart:true,
    CartItem: []
  }

  return jUserCartData;
}




export const initialState = getInitialState();
export const initCartState = getInitialCartState();

export const userContext = createContext();
export const cartContext = createContext();

export function reducer(state, action) {

  if (action.type === 'CART') {

    action.payload.CartItemDiscount = action.payload.CartItemDiscount ? action.payload.CartItemDiscount : 0;
    action.payload.CouponDiscount = action.payload.CouponDiscount ? action.payload.CouponDiscount : 0;
    action.payload.PromoDiscount = action.payload.PromoDiscount ? action.payload.PromoDiscount : 0;
    action.payload.TotalSavings = action.payload.CartItemDiscount+action.payload.CouponDiscount+action.payload.PromoDiscount;



    return action.payload;
  }

  if (action.type === "USER") {

    action.payload.CartItemDiscount = action.payload.CartItemDiscount ? action.payload.CartItemDiscount : 0;
    action.payload.CouponDiscount = action.payload.CouponDiscount ? action.payload.CouponDiscount : 0;
    action.payload.PromoDiscount = action.payload.PromoDiscount ? action.payload.PromoDiscount : 0;
    action.payload.TotalSavings = action.payload.CartItemDiscount+action.payload.CouponDiscount+action.payload.PromoDiscount;



    return action.payload;
  }
  return state;

}




