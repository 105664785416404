import axios from "axios";
import { getConfigData } from "./../../services/Helper";

const configData = getConfigData();

const baseURL = configData.server.api_node;

export const HomePageApiUrl = {
    webBannerImages: "/home/banner-list",
    popularTest: "/home/popular-test-package",
    organsList: "/home/organ-list",
    popularPackage: "/home/package-list",
    bookingCount: "/home/booking-count",
};

const axiosConfig = axios.create({
    baseURL,
    headers: { "browser-id":  "web-desktop-" + configData.csrf_token  },
});

export default axiosConfig;
