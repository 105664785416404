import { userContext } from '../../services/userHandler';
import { useContext } from 'react';
import { Navigate } from "react-router-dom";
import { getConfigData } from "../../services/Helper";
import { GetData } from '../../services/SendData';


export default function Logout() {
  //  const context = useContext(contextValue);
  // ----------------- set common code for session checking : start -------------
  const { userState, userDispatch } = useContext(userContext);
  const configData = getConfigData();
  // ----------------- set common code for session checking : end ------------





  GetData('/genulabhome/logoutJs', configData, true).then((result) => {
    sessionStorage.removeItem('userData');
    sessionStorage.clear();
    localStorage.removeItem("token");
    //window.location.reload();
    window.location.herf = configData.server.base_url + "/genulabhome/logout";

  });

  if (!userState.isLoggedIn) {
    return (<Navigate from={'/Login'} to={'/'} />);

  }

  return (
    <>
      <h2>Thank you for visiting www.GenuPathLabs.com</h2>
      <div className="stap-heading"><span>Releasing sessions! Please Wait...</span></div>
    </>


  );

}
