import Slider from "react-slick";
import { Link } from "react-router-dom";
import { settings4 } from "../HomeConstants";
import { Container } from "react-bootstrap";
import { useEffect, useState } from "react";
import { PostData } from "../../../services/SendData";
import BookNowButton from "../../../sections/BookNowButton";
import { getNewPermalink } from "../../../services/Helper";


var queryStarted = {
    getBookAgain: { list: false, timeout: null }

}
function HomeBookAgain({ configData, server }) {

    const [services, setServices] = useState([]);

    const getBookAgain = (param) => {
        // ------------ initialization ------------
        param = typeof param !== "undefined" ? param : {};
        param.showLoder = typeof param.showLoder !== "undefined" ? param.showLoder : false;
        param.force = typeof param.force !== "undefined" ? param.force : false;
        param.fnFeedBack = typeof param.fnFeedBack !== "undefined" ? param.fnFeedBack : null;
        // param.pageNumber = typeof param.pageNumber !== "undefined" ? param.pageNumber : 1;
        // param.userInput = typeof param.userInput !== "undefined" ? param.userInput : clone(userInput);
        //param.healthCodition = typeof (param.healthCodition) !== 'undefined' ? param.healthCodition : [];
        //console.log('healthArticles:-' ,'listBooking.isDarty:', listBooking.isDarty, 'force', param.force);

        // console.log("services.length !== 0 && !param.force" , services.length !== 0,!param.force , (services.length !== 0 && !param.force))

        if (services.length !== 0 && !param.force) {
            return false;
        }
        // ------------ handeling force &&  ------------
        if (queryStarted.getBookAgain.list) {
            if (param.force) {
                queryStarted.getBookAgain.timeout = window.setTimeout(
                    function () {
                        getBookAgain(param);
                    },
                    1500
                );
            }
            return false;
        }

        if (queryStarted.getBookAgain.timeout) {
            window.clearTimeout(queryStarted.getBookAgain.timeout);
        }

        var apiSegment = "/loadData/submitPage/bookAgainServices";
        queryStarted.getBookAgain.list = true;
        PostData(
            apiSegment,
            { healthArticles: true },
            configData,
            param.showLoder
        ).then((result) => {
            var objServices = [];
            objServices = [...result];
            // console.log('objServices',objServices);
            setServices(objServices);
            if (typeof param.fnFeedBack === "function") {
                param.fnFeedBack();
            }
            window.setTimeout(function () {
                queryStarted.getBookAgain.list = false;
            }, 1000);
        });
        return false;
    }

    useEffect(() => {
        // queryStarted.initCall = false;
        getBookAgain({ force: false, showLoder: true });
    }, []);


    console.log('services', services, services.length);


    return (services.length > 0 && <Container>
        <div className="clr height30" />
        <h2 className="heading-style list-inline-item">Book Again</h2>

        <Slider {...settings4} className='slick-arrows-style'>
            {services.map((item, ndx) => <div className="genu-book-again">
                <div key={ndx} className="book-again-box">
                    <div className="tag">{item.TestType}</div>
                    <div className="heading" >
                        <Link title={item.ServiceName} to={getNewPermalink(item, true, "self")}>{item.ServiceName}</Link>
                        </div>
                    {item?.NoOfParameters > 0 && <div className="included">Included <span className="rt">{item.NoOfParameters}</span></div>}
                    <div>Booked on: {item?.BookingDate}</div>
                    <div className="bookuser">Booked for:<span className="b-user" title={item?.PatientName}>{item?.PatientName}</span></div>
                   
                    <div className="bookAgain">
                        <div>
                            {item?.Fees && item?.OfferFees && item?.Fees > item?.OfferFees && <del>₹{item?.Fees}</del>}
                            <div className="priceSec">
                            <small>₹</small>{item?.OfferFees}
                            </div>
                        </div>
                        <BookNowButton product={item} btnBookNow={'Book Again'}  className="btn btnagain" />
                        {/* <a href={"#"} className="btn btnagain">BOOK AGAIN <i className="icon-right-arrow" /></a> */}

                    </div>
                </div>
            </div>)}
        </Slider>
    </Container>)
}

export default HomeBookAgain;