import { useState, useContext } from 'react'
import { cartContext } from "../services/userHandler";
import { getConfigData, clone, countCartItems } from '../services/Helper'
import { PostData } from '../services/SendData';


const UseFetchCart = () => {
    const configData = getConfigData();
    const { cartState, cartDispatch } = useContext(cartContext);
    const [isLoading, setIsLoading] = useState(false);
    var timeOutForLoading;

    function fetchCart() {

        if (timeOutForLoading) {
            window.clearTimeout(timeOutForLoading);
        }

        if (isLoading) {
            timeOutForLoading = window.setTimeout(() => {
                fetchCart();
            }, 500);
            return false;
        }


        var apiSegment = '/loadData/submitPage/fetchCart';

        //alert(window.location.href);
        var showLoader = window.location.href.search("\/cart") !== -1;
        setIsLoading(true);
        PostData(apiSegment, { option: 'fullCart' }, configData, showLoader).then((result) => {
            setIsLoading(false);
            let jResponse = result;
            //console.log('jResponse',jResponse);
            if (jResponse.status) {
                let resData = jResponse.data;
                if (resData.CartItem.length > 0) {
                    resData.CartItem.map(function (item) {
                        item.Permalink = item.PermalinkNew ? item.PermalinkNew : item.Permalink;
                    });
                }

                var objCartList = clone(resData);
                objCartList.TotalCartItems = countCartItems(resData.CartItem);
                //console.log('TotalCartItems',objCartList.TotalCartItems);
                //setSelectedO-rCartItems(objCartList);
                //console.log('SelectedO-rCartItems',selectedO-rCartItems);
                //setElementVal([{ id: "total-cart-items", html: objCartList.TotalCartItems }]);
                cartDispatch({ type: 'CART', payload: objCartList });
            }
        });
    }

    return fetchCart


}

export default UseFetchCart;