import { PostData, fetchData } from "../services/SendData";
import { getConfigData, getNewPermalink } from "../services/Helper";
import { cartContext } from '../services/userHandler';
import InitSearchTest from "../services/InitSearchTest.json";
import { setElementVal, clone, VerticallyCenteredModal, IsThisItemSelected, countCartItems } from "../services/Helper";
import { useState, useContext, useRef } from 'react';
import { Link } from "react-router-dom";
import UseFetchCart from "../Hook/useFetchCart";

let ctrlSearchTest;// = new AbortController();
var hasInitiated_SearchTest = false;
var _searchTestTmrId = false;
var _searchTestTmrId2 = false;
var _searchTestTmrId3 = null;

function SearchTest(props) {
    const fetchCart = UseFetchCart();
    const configData = getConfigData();
    const server = configData.server;

    const [userInput, setUserInput] = useState({
        txtTestDataSearch: "",
        validation: {
            isValid: { txtTestDataSearch: false }
        }
    });

    //const [selectedOrCartItems, setSelectedOrCartItems] = useState(InitSearchTest.CartList);
    const [inFocus, setInFocus] = useState(false);
    const inputRef = useRef(null);
    const [searchTestItems, setSearchTestItems] = useState(InitSearchTest.SearchList);
    const [modalShow, setModalShow] = useState(false);
    const { cartState, cartDispatch } = useContext(cartContext);
    const [activeIndex, setActiveIndex] = useState(-1);
    //resData.CartItem.length

    const handleKeyDown = (e) => {
        if (e.key === "ArrowDown" && activeIndex + 1 < searchTestItems.length) {
            setActiveIndex(activeIndex + 1);
            //setSearchQuery(searchTestItems[activeIndex + 1].text);
        } else if (e.key === "ArrowUp") {
            e.preventDefault();
            if (activeIndex - 1 >= 0) {
                setActiveIndex(activeIndex - 1);
                //setSearchQuery(searchTestItems[activeIndex - 1].text);
            }
        } else if (e.key === "Escape") {
            setActiveIndex(-1);
            setInFocus(false);
            inputRef.current.blur();
        }
    };


    function clearSearchResult() {
        //setElementVal([{ id: "txtTestDataSearch", value: '' }]);
        userInput.txtTestDataSearch = '';
        userInput.validation = {
            isValid: { txtTestDataSearch: false }
        };
        setUserInput(userInput);
        setSearchTestItems(InitSearchTest.SearchList);
        //document.getElementById("showSearchDiv").style.display = "none";
        setInFocus(false);
    }

    function lnkBookATest(event) {
        event.preventDefault();
        if (_searchTestTmrId3) {
            window.clearTimeout(_searchTestTmrId3);
            document.getElementById("txtTestDataSearch").focus();
        }
        //event.stopPropagation();
        var apiSegment = null;
        var arrHref = Array();
        var actionType = null;

        if (event.target.getAttribute("href").search('addToCart') !== -1) {
            actionType = 'addToCart';
            arrHref = event.target.getAttribute("href").split('addToCart/');
            apiSegment = '/loadData/submitPage/addToCart';
        } else if (event.target.getAttribute("href").search('removeFromCart') !== -1) {
            actionType = 'removeFromCart';
            arrHref = event.target.getAttribute("href").split('removeFromCart/');
            apiSegment = '/loadData/submitPage/delCart';
        } else {
            alert("Error");
            return false;
        }


        //console.log(arrHref);
        var dataHref = arrHref[1].split('/');
        let LabId = parseInt(dataHref[0]);
        let ServiceId = parseInt(dataHref[1]);
        let Fees = parseFloat(dataHref[2]);
        let VisitType = dataHref[3];
        let ndxItem = dataHref[4];
        //console.log('LabId:', LabId, 'ServiceId:', ServiceId, 'VisitType:', VisitType);
        if (ServiceId === 0) {
            return false;
        }

        // checking patient pincode : data-patient_pincode
        // let patient_pincode = document.getElementById('elePatientLocation').getAttribute('data-patient_pincode');
        // if (typeof (patient_pincode) === 'undefined' || !patient_pincode || patient_pincode === '') {
        //     setModalShow(true);
        //     // setElementVal([{ id: "VerticallyCenteredModal-message", html: 'Sample Collection Service is not available in your area' }]);
        //     setElementVal([{ id: "VerticallyCenteredModal-message", html: 'Please select your location for Sample Collection' }]);
        //     return false;
        // }

        //POST >> http://localhost:8081/loadData/submitPage/addToCart >> {senddata: {ServiceId: 5,
        // Fees: 600, LabId: "2"}}
        //var apiSegment = '/loadData/submitPage/addToCart';
        PostData(apiSegment, {
            senddata: {
                LabId: LabId,
                ServiceId: ServiceId,
                Fees: Fees
            }
        }, props)
            .then((result) => {
                let jResponse = (actionType === 'addToCart') ? result.response : result;
                //console.log(jResponse);
                if (jResponse.status && jResponse.status === 1) {
                    let resData = (actionType === 'removeFromCart') ? jResponse.data.data : jResponse.data;
                    // -- update CartData (show selected ) and update cart-count 
                    var objCartList = typeof (resData.Cart[0]) !== 'undefined' ? clone(resData.Cart[0]) : InitSearchTest.CartList;
                    //console.log(actionType, objCartList , resData);
                    if (resData.CartItem.length > 0) {
                        objCartList.CartItem = clone(resData.CartItem);
                        objCartList.TotalCartItems = countCartItems(resData.CartItem); //resData.CartItem.length;
                    }
                    cartDispatch({ type: 'CART', payload: objCartList });

                    var objTestItems = clone(searchTestItems);
                    if (typeof (objTestItems.Items[ndxItem]) !== 'undefined') {
                        objTestItems.Items[ndxItem].IsSelected = (actionType === 'addToCart') ? true : false;
                    }
                    setSearchTestItems(objTestItems);

                } else if (jResponse.message) {
                    setModalShow(true);
                    setElementVal([{ id: "VerticallyCenteredModal-message", html: jResponse.message }]);
                }

                window.setTimeout(function () {
                    //alert('hi');
                    if (document.getElementById("eleBestPackages")) {
                        document.getElementById("eleBestPackages").click();
                    }

                    // if (window.location.href.search("\/cart") !== -1) {
                    //     document.getElementsByName('call-cart-init-functions')[0].click();
                    // }

                    fetchCart();
                }, 1000);

                // window.setTimeout(function () {
                //     //fetchCart();
                // }, 200);

            });




    }



    function doSearchTestData(objInput) {
        var apiSegment = '/loadData/submitPage/diagnosisList';
        //console.log('doSearchTestData', apiSegment);
        var objResult = clone(InitSearchTest.SearchList);
        objResult.IsNotFound = false;
        objResult.TotalCount = 0;
        //setSearchTestItems(objResult);
        if (objInput.txtTestDataSearch.length < 2) {
            return false;
        }

        document.getElementById('genuloader-search').style.display = 'block';
        //props.ctrl = ctrlSearchTest;

        fetchData('POST', apiSegment, { searchText: objInput.txtTestDataSearch }, props, false, ctrlSearchTest, function (result) {
            let jResponse = result;
            objResult.IsNotFound = true;
            //console.log(jResponse.length);
            if (jResponse && jResponse.length > 0) {
                objResult.IsNotFound = false;
                objResult.TotalCount = jResponse.length;
                objResult.Items = clone(jResponse);
                objResult.Items.forEach(function (objItem, ndxItem, arr) {
                    let permalink = objItem.PermalinkNew ? objItem.PermalinkNew : objItem.permalink;
                    permalink = permalink.replace("https://staging.genupathlabs.com/", "").replace("https://www.genupathlabs.com/", "");
                    arr[ndxItem].Permalink = server.base_url + "/" + permalink;
                    arr[ndxItem].ndx = ndxItem;
                });
            }

            if (_searchTestTmrId2) {
                clearTimeout(_searchTestTmrId2);
                document.getElementById('genuloader-search').style.display = 'block';
            }

            _searchTestTmrId2 = window.setTimeout(function () {
                setSearchTestItems(objResult);
                document.getElementById('genuloader-search').style.display = 'none';
            }, 700);
        });
    }


    function searchTestData(event) {
        event.preventDefault();
        let obj = clone(userInput);
        obj[event.target.name] = event.target.value;

        if (obj.txtTestDataSearch && obj.txtTestDataSearch.length >= 2) {
            obj.validation.isValid.txtTestDataSearch = true;
        } else {
            obj.validation.isValid.txtTestDataSearch = false;
            setInFocus(false);
            setSearchTestItems(InitSearchTest.SearchList);
        }

        setUserInput(obj);
        //console.log('SearchText', obj.txtTestDataSearch.length, obj, userInput);
        if (obj.validation.isValid.txtTestDataSearch) {
            window.setTimeout(function () {
                //console.log('doSearchTestData');
                setUserInput(obj);
                focusToSearch(event, obj);
            }, 40);
            //  doSearchTestData(obj);
        }
        //return false;
    }

    function focusToSearch(event, uInput) {
        event.preventDefault();
        //document.getElementById("showSearchDiv").style.display = "block";
        setInFocus(true);
        window.setTimeout(function () { document.getElementById("txtTestDataSearch").focus(); }, 200);


        if (typeof (uInput) === 'undefined') {
            uInput = clone(userInput);
        }
        /* off on 23-Nov-2022 
                if (_searchTestTmrId) {
                    clearTimeout(_searchTestTmrId);
                }
                _searchTestTmrId = window.setTimeout(function () { doSearchTestData(uInput); }, 1000);
        */
        /* On 23-Nov-2022 :start */
        if (ctrlSearchTest && ctrlSearchTest.signal) {
            //console.log('call for abort');
            ctrlSearchTest.abort();
        }

        window.setTimeout(function () {
            ctrlSearchTest = new AbortController();
            doSearchTestData(uInput);
        }, 1000);
        /* On 23-Nov-2022 :else */
        //doSearchTestData(uInput);
        //console.log('focusToSearch');
    }




    // ---------------- call on initial : start ------------
    if (!hasInitiated_SearchTest) {
        hasInitiated_SearchTest = true;
        window.setTimeout(function () {
            fetchCart();
        }, 1000);

        //var element ;
        //if() ;



        // if (element && element[0].getAttribute('listener') !== 'true' && document.getElementsByClassName('clear-search-result')) {
        //     element[0].addEventListener("click", function () {
        //         document.getElementsByClassName('clear-search-result')[0].click();
        //     });
        //     element[0].setAttribute('listener', 'true');
        // }


    }
    // ---------------- call on initial : end ------------

    return (
        <div className="search-block">
            <div className="input-group " > {/* contentEditable="true" */}
                <input type="text" className="form-control searchBoxStyle" name="txtTestDataSearch" id="txtTestDataSearch"
                    value={userInput.txtTestDataSearch}
                    autoComplete="off"
                    onChange={searchTestData}
                    onFocus={() => {
                        setActiveIndex(-1);
                        setInFocus(true);
                    }}
                    onBlur={() => {
                        setActiveIndex(-1);
                        if (_searchTestTmrId3) {
                            window.clearTimeout(_searchTestTmrId3);
                        }
                        _searchTestTmrId3 = window.setTimeout(function () { setInFocus(false); }, 500);
                    }}
                    ref={inputRef}
                    onKeyDown={handleKeyDown}
                    placeholder="Book for Tests, Checkups and Packages (e.g., HbA1c, X-Ray, CBC etc.)" />
                <button className="btn btn-search" type="button" id="btnSearchTest" onClick={focusToSearch}  >
                    <i className="icon-focus-lens" />
                </button>
                <div className='genuloader searchloader' id="genuloader-search" style={{ display: 'none' }}>
                    <div className="genuinner genuinner-search genuinner-search-fetchCart" onClick={fetchCart} ><img src={server.cdn_url + "/images/testLoad.gif"} alt="" /></div>
                    <div className='genuloaderstyle genuloadersearch'></div>
                </div>
            </div>

            <div className={"search-dropdown" + (inFocus && (searchTestItems.TotalCount > 0 || searchTestItems.IsNotFound) ? '' : ' d-none')} id="showSearchDiv" style={{display: "block"}}  >
                <div className="search-body">
                    <div className="search-option">
                        <div className="search-result">
                            <div className="showing-result">Showing Search Results {searchTestItems.TotalCount > 0 ? <span>({searchTestItems.TotalCount})</span> : ''} </div>
                            <div className={"clear-block clear-search-result"} onClick={clearSearchResult} >Clear All <i className="icon-close-fill" /></div>
                        </div>
                        <ul className="search-list">
                            {searchTestItems.Items.map((item, key) =>
                                <li key={key} className={IsThisItemSelected(item, cartState) ? (IsThisItemSelected(item, cartState) === 2 ? "selected included" : "selected") : ""}>
                                    <div className="search-result">
                                        <div className="search-name"  ><Link to={getNewPermalink(item, false, 'self')} className="search-name"  >{item.ServiceName} </Link></div>
                                        <div className="collection">
                                            {item.IsHomeCollectionAvailable ? <>Home Collection Available</> : <>Lab Visit Only</>}
                                            {item?.ServiceText ? <> &nbsp;|&nbsp; {item.ServiceText}</> : <></>}
                                        </div>


                                    </div>
                                    <div className="search-value">
                                        {item.Fees > item.OfferFees ? <del>(₹ {item.Fees})</del> : ''}

                                        <span className="mrp"> ₹ {item.OfferFees}</span>
                                        <a href={IsThisItemSelected(item, cartState) ? "removeFromCart/" + item.LabId + "/" + item.ServiceId + "/0.00/A/-1" : "addToCart/" + item.LabId + "/" + item.ServiceId + "/" + item.OfferFees + "/" + (item.IsHomeCollectionAvailable ? "A" : "L") + "/" + item.ndx + '/' + (item.IsPackage ? 'allPackages' : 'allDiagnosticTests')}
                                            onClick={lnkBookATest}
                                            className="btn btn-booktest" >{IsThisItemSelected(item, cartState) ? (IsThisItemSelected(item, cartState) === 1 ? "SELECTED" : <span>You have already added a test of this package</span>) : "BOOK TEST"}</a>
                                    </div>
                                </li>
                            )}
                        </ul>
                        {searchTestItems.IsNotFound ? <div className="no-result-found">Sorry! No result found with "{userInput.txtTestDataSearch}" </div> : <></>}
                    </div>
                </div>
                <div className="d-footer searchblock">
                    <span>Trusted Labs</span> + <span>Advanced Automated Equipments</span> + <span>Guaranteed Accuracy</span> + <span className="textred">Upto 70% Off</span> <span>On Healthcare Packages</span>
                </div>
            </div>

            <VerticallyCenteredModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />

        </div>


    );
}



export default SearchTest;