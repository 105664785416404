import Modal from "react-bootstrap/Modal";
import jConfigData from "../config.json";

// import FingerprintJS from "@fingerprintjs/fingerprintjs" 

/* ---------------------- Core and Utility Components : start  -------------------- */
export function clone(obj) {
    var copy;

    // Handle the 3 simple types, and null or undefined
    if (null === obj || "object" !== typeof obj) return obj;

    // Handle Date
    if (obj instanceof Date) {
        copy = new Date();
        copy.setTime(obj.getTime());
        return copy;
    }

    // Handle Array
    if (obj instanceof Array) {
        copy = [];
        for (var i = 0, len = obj.length; i < len; i++) {
            copy[i] = clone(obj[i]);
        }
        return copy;
    }

    // Handle Object
    if (obj instanceof Object) {
        copy = {};
        for (var attr in obj) {
            if (obj.hasOwnProperty(attr)) copy[attr] = clone(obj[attr]);
        }
        return copy;
    }

    throw new Error("Unable to copy obj! Its type isn't supported.");
}

export function alertifyOptions(message, fnAccept, fnDeny, optCustom) {

    var optInit = {
        title: 'GenuPathLabs',
        message: <></>,
        buttons: [
            {
                label: 'Yes',
                onClick: () => alert('Click Yes')
            },
            {
                label: 'No',
                onClick: () => alert('Click No')
            }
        ],
        closeOnEscape: true,
        closeOnClickOutside: true,
        keyCodeForClose: [8, 32],
        willUnmount: () => { },
        afterClose: () => { },
        onClickOutside: () => { },
        onKeypress: () => { },
        onKeypressEscape: () => { },
        overlayClassName: "overlay-custom-class-name"
    };

    message = typeof (message) === 'undefined' ? 'Welcome to GenuPathLabs' : message;
    fnAccept = typeof (fnAccept) === 'function' ? fnAccept : function () { };
    fnDeny = typeof (fnDeny) === 'function' ? fnDeny : function () { };

    optCustom = typeof (optCustom) === 'undefined' ? {} : optCustom;
    if (optCustom.buttons) {
        optCustom.buttons[0] = optCustom.buttons[0] ? optCustom.buttons[0] : {};
        optCustom.buttons[1] = optCustom.buttons[1] ? optCustom.buttons[1] : {};
        optCustom.buttons[0].label = optCustom.buttons[0].label ? optCustom.buttons[0].label : 'Yes';
        optCustom.buttons[1].label = optCustom.buttons[1].label ? optCustom.buttons[1].label : 'No';
    } else {
        optCustom.buttons = clone(optInit.buttons);
    }

    optInit.overlayClassName = optCustom?.overlayClassName ? optCustom?.overlayClassName : optInit.overlayClassName

    optInit.buttons = [];
    optCustom.message = message;
    optCustom.buttons[0].onClick = fnAccept;
    optCustom.buttons[1].onClick = fnDeny;

    return { ...optInit, ...optCustom };
}

export function getConfigData() {

    var serverId = '';
    if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
        serverId = 'local';
    } else if (window.location.hostname === 'staging.genupathlabs.com') {
        serverId = 'staging';
    } else if (window.location.hostname === 'www.genupathlabs.com' || window.location.hostname === 'prod-replica.genupathlabs.com') {
        serverId = 'prod';
    } else {
        serverId = 'dev';
    }

    const configData = clone(jConfigData);
    var server = configData.servers.filter(function (item) {
        return item.id === serverId
    })[0];

    // if (serverId === 'dev') {
    server.banner_url = server.banner_url.replace('[[HOST-NAME]]', window.location.hostname);
    server.base_url = server.base_url.replace('[[HOST-NAME]]', window.location.hostname);
    server.cdn_url = server.cdn_url.replace('[[HOST-NAME]]', window.location.hostname);
    // }


    // console.log('Helper::server:', window.location.hostname, serverId, server);
    configData.csrf_token = document.head.querySelector('meta[name="csrf-token"]').getAttribute('content');
    configData.server = server;
    configData.servers = undefined;
    delete configData.servers;
    //console.log('Helper::configData:', configData);
    return configData;

}


export function initSessionData() {
    var jInitData = {};

    // {
    //   popularTests: { Item: [] },
    //   popularPackages: { Item: [], TotalTestCount: 150, allPackages: [], isDarty:false },
    //   healthConditions: { Items: [] },
    //   organs: { Item: [] },
    //   homePageBanners: []
    // }

    let jsInitData = window.sessionStorage.getItem('initData');
    var session = jsInitData ? JSON.parse(jsInitData) : {};

    //popularTests:
    jInitData.popularTests = (session.popularTests) ? clone(session.popularTests) : { Item: [] };
    //jInitData.popularTests.Item = (session.popularTests.Item) ? clone(session.popularTests.Item) : [];

    //popularPackages: 
    jInitData.popularPackages = (session.popularPackages) ? clone(session.popularPackages) : { Item: [], TotalTestCount: 150, TotalPackageCount: 50, allPackages: [], isDarty: false };
    //jInitData.popularPackages.Item = (session.popularPackages && session.popularPackages.Item) ? clone(session.popularPackages.Item) : [];
    //jInitData.popularPackages.TotalTestCount = (session.popularPackages &&  session.popularPackages.TotalTestCount) ? clone(session.popularPackages.TotalTestCount) : 150;
    //jInitData.popularPackages.allPackages = (session.popularPackages &&  session.popularPackages.allPackages) ? clone(session.popularPackages.allPackages) : [];
    jInitData.popularPackages.isDarty = (session.popularPackages && session.popularPackages.isDarty) ? session.popularPackages.isDarty : false;

    //healthConditions:
    jInitData.healthConditions = (session.healthConditions) ? clone(session.healthConditions) : { Items: [] };
    //jInitData.healthConditions.Items = (session.healthConditions && session.healthConditions.Items) ? clone(session.healthConditions.Items) : [];

    //homePageBanners:
    jInitData.homePageBanners = (session.homePageBanners) ? clone(session.homePageBanners) : [];

    //organs:
    jInitData.organs = (session.organs) ? clone(session.organs) : { Item: [] };
    //jInitData.organs.Item = (session.organs.Item) ? clone(session.organs.Item) : [];

    return jInitData;
}

export function logOut() {
    //alert('HI');
    sessionStorage.removeItem('userData');
    sessionStorage.clear();
    localStorage.removeItem("token");
    window.location.reload();
    //return (<Navigate  to={'/'} />);

}

export function bytesToSize(bytes) {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Byte';
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
}

export function redirectUrl(goDesireUrl, defaultUrl) {
    goDesireUrl = typeof (goDesireUrl) !== 'undefined' ? goDesireUrl : false;
    defaultUrl = typeof (defaultUrl) !== 'undefined' ? defaultUrl : (goDesireUrl ? '/' : '/login');
    let objConfig = getConfigData();
    let returnUrl = window.location.href;
    //  alert(returnUrl);
    returnUrl = returnUrl.replace(objConfig.server.base_url, '').replace('http://localhost:3000', '');
    if (goDesireUrl) {
        let io = returnUrl.indexOf('return-url=');
        // alert(io)
        // alert(returnUrl.substring(io + 11))
        returnUrl = io !== -1 ? decodeURIComponent(returnUrl.substring(io + 11)) : defaultUrl;
        // alert(returnUrl)
        if (returnUrl.indexOf('logout') !== -1) {
            returnUrl = '/';
        }
    } else {
        // alert("indexOf('return-url=')"+ defaultUrl.indexOf('return-url'));
        if (returnUrl === '/' || returnUrl.search('login') === -1 || returnUrl.search('logout') === -1 || defaultUrl.indexOf('return-url=') === -1) {
            //   alert( '2#returnUrl:'+ returnUrl )
            //   alert( '2#defaultUrl: '+ defaultUrl )
            defaultUrl = defaultUrl + (defaultUrl?.indexOf("?") === -1 ? "?" : "&")
            //   alert( '3#returnUrl:'+ returnUrl )
            returnUrl = defaultUrl + 'return-url=' + encodeURIComponent(returnUrl);
            //   alert( '4#returnUrl:'+ returnUrl )
        } else {
            //   alert( 'X#returnUrl:'+ returnUrl )
            returnUrl = defaultUrl;
        }
    }
    return returnUrl;
}


export function loadPaytmScript(txnToken, orderId, amount, notifyUrl) {
    // alert(typeof (notifyUrl))
    var myNotifyUrl = typeof (notifyUrl) !== 'undefined' ? notifyUrl : '/cart?paymentfail=1&rnd=' + (Math.random() * 1000);
    var config = {
        "root": "",
        "flow": "DEFAULT",
        "merchant": {
            "name": "Genu Path Labs",
            "logo": "https://res.genupathlabs.com/genu_path_lab/live/customer_V2/images/genu-logo-transparent-mark2.png" // "https://stage-res.genupathlabs.com/genu_path_lab/live/customer_V2/images/genu-logo-transparent.png"
        },
        "style": {
            "headerBackgroundColor": "#f8e6d5",
            "headerColor": "#262e57"
        },
        "data": {
            "orderId": orderId,
            "token": txnToken,
            "tokenType": "TXN_TOKEN",
            "amount": amount
        },
        "handler": {
            "notifyMerchant": function (eventName, data) {
                // alert(eventName); alert(myNotifyUrl);
                if (eventName === 'SESSION_EXPIRED' || eventName === 'APP_CLOSED') {
                    //alert("Your session has expired!!");
                    window.location.href = myNotifyUrl; // '/cart?paymentfail=1&rnd=' + (Math.random() * 1000);
                }
                //eventName--APP_CLOSED if user closed the page 
            }
        }
    };

    if (window.Paytm && window.Paytm.CheckoutJS) {
        // initialze configuration using init method
        window.Paytm.CheckoutJS.init(config).then(function onSuccess() {
            //console.log('Before JS Checkout invoke');
            // after successfully update configuration invoke checkoutjs
            window.Paytm.CheckoutJS.invoke();
        }).catch(function onError(error) {
            //console.log("Error => ", error);
        });
    }
}



/* ---------------------- Core and Utility Components : end  -------------------- */



/* ---------------------- Cart and Session Components : start  -------------------- */
export function IsThisItemSelected(item, cartState, debug) {
    //if (debug)
    //console.log('IsThisItemSelected:item/cartState', item.IsPackage, item.PackageServices, cartState, debug);
    if (item?.ServiceId === '') {
        return 0;
    }

    var isFound = 0;
    /* if(item.IsSelected){
       isFound = 1;
     }else */
    if (cartState?.CartItem && cartState?.CartItem.length > 0) {
        cartState.CartItem.forEach(function (cart) {
            //console.log('IsThisItemSelected:ServiceId/item.ServiceId', ServiceId, item.ServiceId);
            if (cart?.ServiceId === item?.ServiceId) {
                isFound = 1;
            }
            if (!isFound && item?.IsPackage && item?.PackageServices && item?.PackageServices.length > 0) {
                item.PackageServices.forEach(function (packItem) {
                    // if(cart.ServiceId===69) //console.log(cart.ServiceId, typeof(cart.ServiceId) ,packItem.ServiceId , typeof(packItem.ServiceId), typeof(parseInt(packItem.ServiceId)), packItem);
                    if (cart?.ServiceId === packItem?.ServiceId) {
                        isFound = 2;
                    }
                });
            }

        });
    }
    return isFound;
}

export function countCartItems(CartItems) {
    var totalItems = 0;
    //console.log('CartItems.length', CartItems.length );
    if (CartItems && CartItems.length > 0) {
        CartItems.forEach(item => {
            totalItems += (item.PkgServiceId === null || parseInt(item.PkgServiceId) === 0) ? 1 : 0;
        });
    }
    return totalItems;
}


/* ---------------------- Cart and Session Components : end  -------------------- */
/* ---------------------- cookies : start  -------------------- */
export function getCookie(name) {
    let cookie = {};
    document.cookie.split(';').forEach(function (el) {
        let [k, v] = el.split('=');
        cookie[k.trim()] = v;
    })
    return cookie[name];
}

/* ---------------------- cookies : end  -------------------- */


/* ---------------------- Date & Time and validation Functions : start  -------------------- */

export function dateAnsi(dt) {
    let ndt = new Date(dt);

    let ndtyyyy = ndt.getFullYear();
    let ndtmm = ndt.getMonth() + 1; // Months start at 0!
    let ndtdd = ndt.getDate();



    if (ndtdd < 10) ndtdd = '0' + ndtdd;
    if (ndtmm < 10) ndtmm = '0' + ndtmm;

    //console.log('dateAnsi', dt, ndtyyyy + '-' + ndtmm + '-' + ndtdd);
    return ndtyyyy + '-' + ndtmm + '-' + ndtdd;

}
/**
 * 
 * @param {'YYYY-MM-DD H:I:S'} dt date 
 * @param {  '1:Short Name|2 : Long Name || ?1 Short Name Weekdays || ?2 Long Name Weekdays' } monthType  integer
 * @param {'Seperator Default - '} separator char
 * @param {'Need Full 4 digit year '} fullYear  bolean
 * @returns {'dd-mm-yyyyy'} date
 */

export function dateBritish(dt, monthType, separator, fullYear) {
    if (typeof (dt) === 'undefined' || !dt || dt === '') {
        return '--/--/----';
    }

    let sep = typeof (separator) === 'undefined' ? '/' : separator;
    fullYear = typeof (fullYear) === 'undefined' ? true : fullYear;
    let mnt = typeof (monthType) === 'undefined' ? 0 : parseInt(monthType);
    let ndt = new Date(dt);

    let ndtyyyy = fullYear ? ndt.getFullYear() : (ndt.getYear() - 100);
    let ndtmm = ndt.getMonth() + 1; // Months start at 0!
    let ndtdd = ndt.getDate();
    let wDay = ndt.getDay();
    let ndtmnt = '';
    let ndtwday = '';

    let monthShortNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    let monthLongNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    let dayShortNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thr', 'Fri', 'Sat'];
    let dayLongNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thrusday', 'Friday', 'Saturday'];

    if (ndtdd < 10) ndtdd = '0' + ndtdd;
    if (ndtyyyy < 10) ndtyyyy = '0' + ndtyyyy;

    switch (mnt) {
        case 1:
        case 11:
        case 12:
        case 13:
        case 14:
            ndtmnt = monthShortNames[(ndtmm - 1)];
            ndtwday = mnt === 11 || mnt === 13 ? dayShortNames[wDay] : dayLongNames[wDay];
            break;
        case 2:
        case 21:
        case 22:
            ndtmnt = monthLongNames[(ndtmm - 1)];
            ndtwday = mnt === 21 ? dayShortNames[wDay] : dayLongNames[wDay];
            break;

        default:
            ndtmnt = (ndtmm < 10) ? '0' + ndtmm : ndtmm

    }

    let retVal = '';
    if (mnt > 10) {
        if (mnt == 13) {
            retVal = ndtdd + ' ' + ndtmnt + '`' + ndtyyyy;
        } else if (mnt == 14) {
            retVal = ndtdd + ' ' + ndtmnt + ', ' + ndtyyyy;
        } else {
            retVal = ndtwday + ', ' + ndtdd + ' ' + ndtmnt + ' ' + ndtyyyy
        }
    } else {
        retVal = ndtdd + sep + ndtmnt + sep + ndtyyyy;
    }

    return retVal;


}

export function time12Hours(dt) {
    let ndt = new Date(dt);
    let hrs = ndt.getHours();
    let mnt = ndt.getMinutes();
    let meridiem = hrs >= 12 ? 'p.m.' : 'a.m.';

    if (hrs > 12) hrs = hrs - 12;
    if (hrs < 10) hrs = '0' + hrs;
    if (mnt < 10) mnt = '0' + mnt;
    return hrs + ':' + mnt + ' ' + meridiem;

}

/**
 * 
 * @param {DateString} dateString   (YYYY-MM-DD)
 * @param {Boolean} isFull Boolean 
 * @param {Strng} suffix Suffix String
 * @returns 
 */
export function calcAge(dateString, isFull, suffix) {
    isFull = typeof (isFull) !== 'undefined' ? isFull : false;
    suffix = typeof (suffix) !== 'undefined' ? suffix : '';

    if (!dateString || dateString.trim() == '') {
        return '--';
    }

    var today = new Date();
    var birthDate = new Date(dateString);

    var dy0 = birthDate.getDate();
    var mn0 = birthDate.getMonth();
    var yr0 = birthDate.getFullYear();

    var dy1 = today.getDate();
    var mn1 = today.getMonth();
    var yr1 = today.getFullYear();


    // console.log('Age-1 ', dateString, [dy0, mn0, yr0], [dy1, mn1, yr1]);

    // ------ calc days ----
    var dy = dy1 >= dy0 ? dy1 - dy0 : (dy1 + 30) - dy0; // (dy1 + 30) carry from a month
    var sDy = dy > 1 ? 'Days' : 'Day';
    mn0 = dy1 >= dy0 ? mn0 : mn0 + 1; //adjust carry 
    // console.log('Age-2 ', dateString, [dy0, mn0, yr0], [dy1, mn1, yr1], []);
    // ------ calc months ----

    var mn = mn1 >= mn0 ? mn1 - mn0 : (mn1 + 12) - mn0; // (mn1 + 12) carry from a year
    var sMn = mn > 1 ? 'Months' : 'Month';
    yr0 = mn1 >= mn0 ? yr0 : yr0 + 1;//adjust carry 
    var yr = yr1 >= yr0 ? yr1 - yr0 : 0;
    var sYr = yr > 1 ? 'Years' : 'Year';
    var age = '--';

    // console.log('Age ',dateString , dy , mn,  yr);

    if (isFull) {
        age = `${yr}${sYr} ${mn}${sMn} ${dy}${sDy}`;
    } else {
        mn = dy > 28 ? mn + 1 : mn;
        yr = (mn > 12) || (mn === 12 && dy >= 28) ? yr + 1 : yr;
        if (yr === 0 && mn === 0) {
            age = `${dy} ${sDy}${suffix}`;
        } else if (yr === 0 && mn > 0) {
            sMn = mn > 1 ? 'Months' : 'Month';
            age = ` ${mn} ${sMn}${suffix}`;
        } else {
            sYr = yr > 1 ? 'Years' : 'Year';
            age = `${yr} ${sYr}${suffix}`;
        }
    }
    return age;
}

export function dateDiff(d1, d2) {
    let date1 = new Date(d1).getTime();
    let date2 = new Date(d2).getTime();
    return date1 - date2;
};

/**
 * 
 * @param {DateLater} date1 YYYY-MM-DD H:i:s [Default NOW()]
 * @param {DateEarlier} date2 YYYY-MM-DD H:i:s [Default NOW()]
 * @param {String} interval years,months,weeks,days,hours,minutes,seconds [Default seconds]
 * @returns 
 */

export function dateDiffV2(date1, date2, interval) {
    var second = 1000, minute = second * 60, hour = minute * 60, day = hour * 24, week = day * 7;
    date1 = date1 ? new Date(date1) : new Date();
    date2 = date2 ? new Date(date2) : new Date();
    // return `${date1} ${date2}`;
    var timediff = date2 - date1;
    if (isNaN(timediff)) return NaN;
    switch (interval) {
        case "years": return date2.getFullYear() - date1.getFullYear();
        case "months": return (
            (date2.getFullYear() * 12 + date2.getMonth())
            -
            (date1.getFullYear() * 12 + date1.getMonth())
        );
        case "weeks": return Math.floor(timediff / week);
        case "days": return Math.floor(timediff / day);
        case "hours": return Math.floor(timediff / hour);
        case "minutes": return Math.floor(timediff / minute);
        case "seconds":
        default: return Math.floor(timediff / second);
    }
}


/**
 * 
 * @param {'Cusotomer Name'} customerName string 
 * @param {  'Minmum Length - Default 0' } minLength  number
 * @param {  'Minmum Length - Default 254' } maxLength   number
 * @returns {'True/False'} boolean
 */
export function validateName(customerName, minLength, maxLength) {
    let retVal = false;
    minLength = typeof minLength !== 'undefined' ? minLength : 0;
    maxLength = typeof maxLength !== 'undefined' ? maxLength : 255;

    if (typeof customerName != 'undefined' && customerName && customerName.trim() !== '') {
        customerName = customerName.trim();
        // retVal = (/^(([a-zA-Z])|([a-zA-Z][\w _]+))$/.test(customerName)) ? true : false;
        retVal = (/^[a-zA-Z][\w _]+$/.test(customerName)) ? true : false;
    } else {
        retVal = false;
    }
    retVal = retVal && (customerName.length > minLength) && (customerName.length < maxLength)
    return retVal;
}

export function validateEmail(emailAddr) {
    return (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailAddr)) ? true : false;
}


export function validateMobile(phone) {
    // const regex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    // //console.log(regex.test(phone))
    return (/^([1-9]{1})([0-9]{9})$/.test(phone)) ? true : false;

}

/**
 * 
 * @param {'Address Line'} addressLine string 
 * @param {  'Minmum Length - Default 0' } minLength  number
 * @param {  'Minmum Length - Default 254' } maxLength   number
 * @returns {'True/False'} boolean
 */
export function validateAddrLine(addressLine, minLength, maxLength) {
    let retVal = false;
    minLength = typeof minLength !== 'undefined' ? minLength : 0;
    maxLength = typeof maxLength !== 'undefined' ? maxLength : 255;


    if (typeof addressLine != 'undefined' && addressLine && addressLine.trim() !== '') {
        addressLine = addressLine.trim(); // \w _\/\.\(\)#
        retVal = (/^([a-zA-Z0-9][\w _\/\.\(\)#\,]+)$/.test(addressLine)) ? true : false;
    } else {
        retVal = false;
    }
    retVal = retVal && (addressLine.length > minLength) && (addressLine.length < maxLength)
    return retVal;
}

export function validatePinCode(phone) {
    // const regex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    // //console.log(regex.test(phone))
    return (/^([1-9]{1})([0-9]{5})$/.test(phone)) ? true : false;

}

export function isExitFamilyMember(name, familyMembers) {
    return familyMembers.find(o => o.name === name);
    // undefined
}


export function getToday(days) {
    days = typeof (days) !== 'undefined' ? days : 0
    var dt = new Date();
    dt.setDate(dt.getDate() + days);
    return dateAnsi(dt);
}






/* ---------------------- Date & Time Functions : end  -------------------- */

/* ---------------------- String Functions : end  -------------------- */

export function toTitleCase(str) {
    return str.replace(
        /\w\S*/g,
        function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
}
/* ---------------------- String Functions : end  -------------------- */
/* ---------------------- Html Components : start  -------------------- */

export function hideDropdown(ddClass) {
    document.getElementsByClassName(ddClass)[0].classList.remove("show");
}

export function setElementVal(arrEle) {
    if (typeof (arrEle) != 'undefined' && arrEle.length >= 1) {

        window.setTimeout(function () {

            arrEle.map(function (ele) {

                if (typeof (ele.id) != 'undefined') {

                    if (typeof (ele.value) != 'undefined' && document.getElementById(ele.id)) {
                        document.getElementById(ele.id).value = ele.value;
                    } else if (typeof (ele.html) != 'undefined' && document.getElementById(ele.id)) {
                        document.getElementById(ele.id).innerHTML = ele.html;
                    }

                    if (typeof (ele.attr) != 'undefined') {

                        ele.attr.map(function (attribute) {
                            //console.log('attribute', ele.id, attribute);
                            if (typeof (attribute.name) != 'undefined' && typeof (attribute.value) != 'undefined') {
                                document.getElementById(ele.id).setAttribute(attribute.name, attribute.value);
                            }
                            return false;
                        });


                    }

                }
                return false;
            });

        }, 20);


    }
}



export function toggleMenu() {

    if (document.getElementsByClassName('genu-menu open').length) {
        document.getElementsByClassName('genu-menu open')[0].setAttribute('class', 'genu-menu');
        document.getElementsByClassName('menu-overlay open')[0].setAttribute('class', 'menu-overlay');
        document.getElementsByClassName('mob-menu open')[0].setAttribute('class', 'mob-menu');
    } else {
        document.getElementsByClassName('genu-menu')[0].setAttribute('class', 'genu-menu open');
        document.getElementsByClassName('menu-overlay')[0].setAttribute('class', 'menu-overlay open');
        document.getElementsByClassName('mob-menu')[0].setAttribute('class', 'mob-menu open');
    }

}

/**
 * 
 * @param { * } { message : Display Message,  onHide : CallBackFunction, Call on hide / close of dialog,  [dlgSize] : In pixcel(set dilog size), [txtCloseButton] : Custome name for close button}
 * @returns 
 */
export function VerticallyCenteredModal(props) {
    //console.log('VerticallyCenteredModal-props', props);
    const dlgSize = typeof (props.dlgSize) === 'undefined' ? "md" : props.dlgSize;
    const txtCloseButton = (typeof (props.txtCloseButton) !== 'undefined' && props.txtCloseButton) ? props.txtCloseButton : 'Close';
    const bodyClassName = props?.BodyClassName ? props?.BodyClassName : "text-center";
    return (
        <Modal
            {...props}
            size={dlgSize}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Genupathlabs.com
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {typeof props.message === 'string' ?
                    <div id="VerticallyCenteredModal-message" className={bodyClassName}
                        // className="p-padding" / "text-center"
                        dangerouslySetInnerHTML={{ __html: props.message ? props.message : '' }} >
                    </div> : <div>{props.message}</div>
                }

            </Modal.Body>
            <Modal.Footer>
                <button className="btn close-btn" onClick={props.onHide}>{txtCloseButton}</button>
            </Modal.Footer>
        </Modal>
    );
}

export function getNewPermalink(item, isNew, base_url) {
    //console.log([item, isNew, base_url]);
    var pLink = '';
    isNew = typeof (isNew) !== 'undefined' ? isNew : false;
    base_url = typeof (isNew) !== 'undefined' ? base_url + '/' : '';
    pLink = item.permalink ? item.permalink : pLink;
    pLink = item.Permalink ? item.Permalink : pLink;
    pLink = isNew && item.PermalinkNew ? item.PermalinkNew : pLink;

    if (base_url && base_url !== '') {

        pLink = pLink.replace("https://staging.genupathlabs.com/", "").replace("https://www.genupathlabs.com/", "").replace("https://prod-replica.genupathlabs.com/", "").replace("http://localhost:8081/", "");
        if (base_url !== 'self/') {
            pLink = base_url + pLink
        } else {
            pLink = '/' + pLink
        }

        //pLink = base_url + pLink;
    }
    return pLink;
}

export function sanitizeUrl(value) {
    value = typeof (value) !== 'undefined' ? value.toLowerCase() : '';
    return value.replace(/\W/, '-');
}

export const uriSegment = (url, idx) => {
    url = typeof (url) !== 'undefined' ? url : '';
    idx = typeof (idx) !== 'undefined' ? idx : 0;
    const parts = url.split('/');

    return typeof (parts[idx]) != 'undefined' ? parts[idx] : '';
}

export function removeBaseUrl(fullLink) {
    const regex = /^https?:\/\/[^/]+/;
    const pathWithoutBaseUrl = fullLink.replace(regex, '');
    return pathWithoutBaseUrl;
}

export function stringGender(str) {
    let gender = '';
    switch (str) {
        case 'M':
        case 'm':
            gender = 'Male';
            break;
        case 'F':
        case 'f':
            gender = 'Female';
            break;
        case 'U':
        case 'u':
            gender = 'Other';
            break;

        default: gender = '';
    }
    return gender;
}


/* ---------------------- Html Components : end  -------------------- */






// export { logOut, dateAnsi, dateBritish, time12Hours, clone, initSessionData, setElementVal, VerticallyCenteredModal, IsThisItemSelected, countCartItems };