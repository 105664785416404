import { getConfigData } from "../services/Helper";

import React from 'react';
import { NavLink } from "react-router-dom";

const ProfileMenu = () => {
  const configData = getConfigData();
  return (
    <div className="other-menu">
      <ul>
        <li><NavLink to="/dashboard/bookedservicelist" >My Bookings</NavLink></li>
        {/* <li><NavLink to="/healthrecords">Smart Reports</NavLink></li>
    <li><NavLink to="/notifications">Notifications</NavLink></li> */}
        <li><NavLink to="/my-profile/my-family">My Family</NavLink></li>
        <li><NavLink to="/my-profile/change-password">Change Password</NavLink></li>
        <li><NavLink to="/logout">Logout</NavLink></li>
      </ul>
    </div>
  )
}

export default ProfileMenu