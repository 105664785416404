// Slider Settings

export const settings1 = {
  arrows: false,
  dots: true,
  infinite: true,
  speed: 1000,
  autoplay: true,
  //autoplaySpeed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  swipeToSlide: true,
};

export const settings2 = {
  arrows: true,
  speed: 1000,
  //autoplay: true,
  slidesToShow: 9,
  slidesToScroll: 1,
  infinite: false,
  swipeToSlide: true,
  responsive: [
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 8,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 6,
      },
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 5,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 400,
      settings: {
        slidesToShow: 3,
      },
    },
  ],
};

export const settings3 = {
  arrows: true,
  speed: 1000,
  //autoplay: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  infinite: false,
  swipeToSlide: true,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 400,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

export const settings4 = {
  arrows: true,
  speed: 1000,
  //autoplay: true,
  slidesToShow: 4,
  slidesToScroll: 1,
  infinite: false,
  swipeToSlide: true,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 4
      }
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 3
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2
      }
    },
    {
      breakpoint: 400,
      settings: {
        slidesToShow: 1
      }
    }
  ]
};