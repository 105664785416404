import React, { useState, useContext, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Row } from "react-bootstrap";
import { initSessionData, alertifyOptions, getConfigData, clone, sanitizeUrl, uriSegment, IsThisItemSelected, setElementVal, countCartItems, redirectUrl, VerticallyCenteredModal } from "../services/Helper";
import { GetData, PostData } from '../services/SendData';
import { cartContext } from '../services/userHandler';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import InitSearchTest from "../services/InitSearchTest.json";

var queryStarted = {
    healthConditions: {
        getList: false,
        timeout: null
    },
    radiologyCategories: {
        getList: false,
        timeout: null
    }
}

var objUserInput = {
    SearchText: ''
}

var objSelectedRadioCat = {
    CategoryName: '',
    CatId: 0,
    SubDeptId: 0
}


export default function TestAndPackageHead(props) {
    const configData = getConfigData();
    const initData = initSessionData();
    const { cartState, cartDispatch } = useContext(cartContext);
    const [isHealth, setIsHealth] = useState(false);


    // var tmFeedback;
    let healthClick = () => {
        setIsHealth(current => !current);
    };

    const [healthConditions, setHealthConditions] = useState(initData.healthConditions);
    const [selectedHC, setSelectedHC] = useState([]);

    const [radiologyCategories, setRadiologyCategories] = useState({ Items: [] });
    const [selectedRadioCat, setSelectedRadioCat] = useState(objSelectedRadioCat);

    const [userInput, setUserInput] = useState(objUserInput);
    const [myModal, setMyModal] = useState({ show: false, message: '' });
    const { pathname } = useLocation();
    //console.log(healthConditions.Items);
    const handelCustomerLocation = (event) => {
        setMyModal({ show: false, message: '' });
        document.getElementById("elePatientLocation").click();
    }


    const actOnEnter = (event) => {
        event.preventDefault();
        if (event.keyCode === 13) {
            // switch (event.target.name) {
            //     case 'SearchTestAndPackages':
            //     default:
            //     //submitUserLogin();
            // }
            event.selectedHC = selectedHC;
            event.userInput = userInput;
            event.selectedRadioCat = selectedRadioCat;
            if (typeof (props.onChangeConditions) === 'function') {
                props.onChangeConditions(event);
            }


        }
        return false;
    }

    const onChangeUserInput = (event) => {
        let mibileFormat = /^[1-9]\d{9}$/;
        let obj = clone(userInput);
        if (event.target.name === 'SearchTestAndPackages') {
            obj.SearchText = event.target.value;
        } else {
            obj[event.target.name] = event.target.value;
        }
        setUserInput(obj);
    }

    const hideMoreHealthList = (type) => {
        //console.log(type);
        var retClass = '  d-none';
        var overSpan = false;
        var parentWidth = 1000;
        var childWidth = 851;
        var maxWidhtPercent = 0.85; //in percent
        var currentWidth = 851;

        if (document.getElementById("health_habits_01") && document.getElementById("health_list_01").offsetWidth) {
            parentWidth = parseFloat(document.getElementById("health_habits_01").offsetWidth);
        }
        if (document.getElementById("health_list_01") && document.getElementById("health_list_01").offsetWidth) {
            childWidth = parseFloat(document.getElementById("health_list_01").offsetWidth);
        }

        currentWidth = parentWidth * maxWidhtPercent;
        if (Math.round(childWidth) >= Math.round(currentWidth)) {
            retClass = ' ';
        }
        //console.log('hideMoreHealthList : parentWidth:', parentWidth, 'childWidth:', Math.round(childWidth), 'currentWidth:', Math.round(currentWidth), 'retClass:', retClass);
        return retClass;
    }



    const getHelthConditionData = () => {
        queryStarted.healthConditions.getList = true;
        var apiSegment = '/loadData/healthConditionList';
        GetData(apiSegment, configData)
            .then((result) => {
                let jResponse = result;
                var objInitData = initSessionData(); //clone(initData);
                objInitData.healthConditions = jResponse;
                window.sessionStorage.setItem('initData', JSON.stringify(objInitData));
                setHealthConditions(objInitData.healthConditions);
                queryStarted.healthConditions.getList = false;
                //console.log('initData:healthConditions', initData);
            });

    }

    const getRadiologyCategoryData = () => {
        queryStarted.radiologyCategories.getList = true;
        var apiSegment = '/loadData/loadPage/radiologyCategories/read';
        //console.log('getRadiologyCategoryData:', apiSegment);
        //console.log(props);
        GetData(apiSegment, configData)
            .then((result) => {
                var objRadioCat = { Items: [] };
                objRadioCat.Items = result.data && result.data.Items ? result.data.Items : [];
                setRadiologyCategories(objRadioCat);
                queryStarted.radiologyCategories.getList = false;
                //console.log('initData:healthConditions', initData);
            });

    }

    const getInitData = () => {

        //console.log('getInitData >> props.listType:', props.listType, '\t radiologyCategories.Items.length:', radiologyCategories.Items.length, '\t queryStarted.radiologyCategories.getList:', queryStarted.radiologyCategories.getList, '\n\t pathname:', pathname, '\t urisegment:2', uriSegment(pathname, 2), "\t selectedRadioCAt", selectedRadioCat)

        if (props.listType === 'diagnostic-tests' && healthConditions?.Items?.length === 0 && !queryStarted.healthConditions.getList) {
            getHelthConditionData();
        } else if (props.listType === 'radiology-tests' && radiologyCategories?.Items?.length === 0 && !queryStarted.radiologyCategories.getList) {
            getRadiologyCategoryData();
        }

    }

    const isSelectedHC = (item) => {
        var retStatus = false;
        if (selectedHC.length === 0) return retStatus;
        selectedHC.map(function (selItem) {
            if (selItem.hcid === item.HCId) {
                retStatus = true;
            }
        });
        return retStatus;
    }

    const lnkBookATest = (event) => {
        event.preventDefault();
        //event.stopPropagation();
        var apiSegment = null;
        var arrHref = Array();
        var actionType = null;
        var serviceName = event.target.getAttribute("data-service-name");
        //alert('hi 1 ');
        if (event.target.getAttribute("href").search('addToCart') !== -1) {
            actionType = 'addToCart';
            arrHref = event.target.getAttribute("href").split('addToCart/');
            apiSegment = '/loadData/submitPage/addToCart';
        } else if (event.target.getAttribute("href").search('removeFromCart') !== -1) {
            actionType = 'removeFromCart';
            arrHref = event.target.getAttribute("href").split('removeFromCart/');
            apiSegment = '/loadData/submitPage/delCart';
            //alert('hi 2');
        } else {
            //alert("Error");
            return false;
        }


        //console.log(arrHref);
        var dataHref = arrHref[1].split('/');
        let LabId = parseInt(dataHref[0]);
        let ServiceId = parseInt(dataHref[1]);
        let Fees = parseFloat(dataHref[2]);
        let ndxItem = dataHref[4];
        let pktItem = typeof (dataHref[5]) !== 'undefined' ? dataHref[5] : 'Item';
        //console.log('LabId:', LabId, 'ServiceId:', ServiceId, 'VisitType:', VisitType, 'ndxItem', ndxItem);

        if (ServiceId === 0) {
            alert(ServiceId);
            return false;
        }

        // checking patient pincode : data-patient_pincode
        // let patient_pincode = document.getElementById('elePatientLocation').getAttribute('data-patient_pincode');
        // if (typeof (patient_pincode) === 'undefined' || !patient_pincode || patient_pincode === '') {
        //     setMyModal({ show: true, message: 'Please select your location for Sample Collection', fnCallBack: function () { handelCustomerLocation(); } });
        //     return false;
        // }


        // alert(serviceName);


        confirmAlert(alertifyOptions("Are you sure to remove '" + serviceName + "'?", function () {
            PostData(apiSegment, {
                senddata: {
                    LabId: LabId,
                    ServiceId: ServiceId,
                    Fees: Fees
                }
            }, configData)
                .then((result) => {
                    let jResponse = (actionType === 'addToCart') ? result.response : result;
                    //console.log(actionType, jResponse);
                    if (jResponse.status && jResponse.status === 1) {
                        let resData = (actionType == 'removeFromCart') ? jResponse.data.data : jResponse.data;
                        var objCartList = typeof (resData.Cart[0]) !== 'undefined' ? clone(resData.Cart[0]) : InitSearchTest.CartList;
                        if (resData?.CartItem?.length > 0) {
                            objCartList.CartItem = clone(resData.CartItem);
                            objCartList.TotalCartItems = countCartItems(resData.CartItem); // resData.CartItem.length;
                        }
                        cartDispatch({ type: 'CART', payload: objCartList });
                    } else if (jResponse.message) {
                        setMyModal({ show: true, message: jResponse.message });
                    }

                    if (jResponse.status === -5) {
                        window.setTimeout(function () {
                            window.location.reload(true);
                        }, 5000)
                    }
                    return true;
                });
        }));
        return false;
    }


    const selectUriRC = (item) => {
        let objSel = clone(objSelectedRadioCat);
        if (typeof (item) !== 'undefined') {
            objSel.CategoryName = item.CategoryName;
            objSel.CatId = item.CatId;
            objSel.SubDeptId = item.SubDeptId;
            setSelectedRadioCat(objSel);
        } else {
            let selRC = uriSegment(pathname, 2);
            // console.log('selectedRadioCat:',selectedRadioCat, 'selectUriRC:',selRC);
            if (radiologyCategories?.Items?.length > 0 && selRC !== '' && sanitizeUrl(selectedRadioCat.CategoryName) !== sanitizeUrl(selRC)) {
                radiologyCategories.Items.map(cat => {
                    if (sanitizeUrl(cat.CategoryName) === sanitizeUrl(selRC)) {
                        objSel.CategoryName = cat.CategoryName;
                        objSel.CatId = cat.CatId;
                        objSel.SubDeptId = cat.SubDeptId;
                    }
                })
                setSelectedRadioCat(objSel);
            }
        }
    }
    // window.setTimeout(function () { getInitData(); }, 80);

    // props.listType === 'diagnostic-tests'

    useEffect(() => {
        //console.log('has changed:[userInput]');

        if (typeof (userInput.selectedHC) !== 'undefined' || typeof (userInput.selectedRadioCat) !== 'undefined') {
            let objInput = { SearchText: userInput.SearchText }
            if (typeof (userInput.selectedHC) !== 'undefined') {
                setSelectedHC(userInput.selectedHC);
            }
            if (typeof (userInput.selectedRadioCat) !== 'undefined') {
                setSelectedRadioCat(userInput.selectedRadioCat);
            }
            setUserInput(objInput)
        }

    }, [userInput]);


    useEffect(() => {
        //console.log('has changed:[selectedHC, selectedRadioCat]');
        // console.log('selectedRadioCat:',selectedRadioCat)
        if (props.onChangeConditions && typeof (props.onChangeConditions) === 'function') {
            //setUserInput(objUserInput);
            //console.log('selectedHC', selectedHC);
            // event.selectedHC = objHc;
            // event.userInput = userInput;
            let objInput = {};
            objInput.selectedHC = selectedHC;
            objInput.selectedRadioCat = selectedRadioCat;
            objInput.userInput = userInput;
            props.onChangeConditions(objInput);
        }

    }, [selectedHC, selectedRadioCat]);


    useEffect(() => {
        //getInitData();
        selectUriRC();

    }, [radiologyCategories]);





    useEffect(() => {
        getInitData();
    }, []);

    //console.log(props.listType);

    return (
        <>
            <Row>
                <div className="col-lg-12">
                    <div className={"selected-test" + (cartState.TotalCartItems > 0 ? '' : ' d-none')}>
                        <div className={"selected-heading" + (cartState.TotalCartItems > 0 ? '' : ' d-none')}>SELECTED PACAKGES & TESTS:</div>
                        <ul className='selected-list'>
                            {cartState.TotalCartItems > 0 ?
                                cartState.CartItem.map((item, idx) =>
                                    (item.PkgServiceId === null || parseInt(item.PkgServiceId) === 0) ?
                                        <li key={'CITM' + idx}>{item.ServiceName}
                                            <i className="icon-close"
                                                data-service-id={item.ServiceId} data-service-name={item.ServiceName}
                                                href={"removeFromCart/" + item.LabId + "/" + item.ServiceId + "/0.00/A/-1"} onClick={lnkBookATest}
                                            ></i></li>
                                        : <></>
                                )
                                : <></>
                            }
                            <div className="clr height20" />
                        </ul>
                    </div>
                    <div className="clr" />
                </div>
            </Row>
            <Row>
                <div className="col-lg-9">
                    {(props.listType === 'diagnostic-tests' || props.listType === 'radiology-tests') && (selectedHC?.length > 0 || (selectedRadioCat?.CategoryName !== '')) ?
                        <div className={"selected-test"}>
                            <div className={"selected-heading" + ((selectedHC?.length > 0 || selectedRadioCat.CategoryName !== '') ? '' : ' d-none')}>YOU SELECTED:</div>
                            <ul className='selected-list'>
                                {selectedHC?.length > 0 ?
                                    selectedHC.map((item, idx) =>
                                        <li key={'HCS' + idx}>{item.hcname}
                                            <i data-hcid={item.hcid} data-hcname={item.hcname} className="icon-close"
                                                onClick={function (event) {
                                                    event.preventDefault();
                                                    //console.log(event);
                                                    let objHc = clone(selectedHC);
                                                    objHc = objHc.filter(function (obj) {
                                                        return obj.hcid !== parseInt(event.target.dataset.hcid);
                                                    });
                                                    setSelectedHC(objHc);
                                                    // let hasChanged = JSON.stringify(objHc) !== JSON.stringify(selectedHC) ? true : false;
                                                    // setSelectedHC(objHc);
                                                    // if (hasChanged && props.onChangeConditions && typeof (props.onChangeConditions) === 'function') {
                                                    //     //setUserInput(objUserInput);
                                                    //     event.selectedHC = objHc;
                                                    //     event.userInput = userInput;
                                                    //     props.onChangeConditions(event);
                                                    // }
                                                }}
                                            ></i></li>
                                    )
                                    : <></>


                                }
                                {(selectedRadioCat.CategoryName !== '') ? <>
                                    {/* YOU SELECTED (CATEGORY): */}
                                    <li key={'RC1'}>{selectedRadioCat.CategoryName}
                                        <NavLink to={'/radiology-test/'} activeClassName="active" onClick={() => setSelectedRadioCat(objSelectedRadioCat)}  ><i data-hcid={'RC1'} data-cname={selectedRadioCat.CategoryName} className="icon-close" ></i></NavLink>
                                    </li>
                                </> : <></>}


                                <div className="clr height20" />
                            </ul>
                        </div>
                        : <></>
                    }
                </div>

                <div className="col-lg-3 mt-10">

                    <button className={"btn btn-block btn-block-filter" + (selectedHC && selectedHC?.length > 0 ? '' : ' d-none')} onClick={(event) => {
                        event.preventDefault()
                        //let objHc = [];
                        let objInput = clone(objUserInput);
                        objInput.selectedHC = [];
                        objInput.selectedRadioCat = objSelectedRadioCat;
                        setUserInput(objInput);

                        //setSelectedHC(objHc); selectedHC
                        // let hasChanged = JSON.stringify(objHc) !== JSON.stringify(selectedHC) ? true : false;
                        // setSelectedHC(objHc);
                        // if (hasChanged && props.onChangeConditions && typeof (props.onChangeConditions) === 'function') {
                        //     setUserInput(objUserInput); //Reset Search criteria
                        //     event.selectedHC = objHc;
                        //     event.userInput = objUserInput;
                        //     props.onChangeConditions(event);
                        // }
                    }} ><i className="icon-filter-clear"></i>Clear all Filters</button>
                </div>
                <div className="clr" />
                <div className="col-lg-12">
                    {props.listType === 'diagnostic-tests' ?
                        <div id="health_habits_01" className="health-habits">
                            <div className="health-heading"><i className="icon-filter-fill"></i> HEALTH CONDITIONS:</div>
                            <ul id="health_list_01" className={isHealth ? 'health-list health-ellipsis-none' : 'health-list'}>
                                {healthConditions?.Items?.length > 0 && healthConditions.Items.map((item, idx) =>
                                    <li key={'HC' + idx}  >
                                        <a href={'#'} className={isSelectedHC(item) ? ' active' : ''} data-hcid={item.HCId} data-hcname={item.HealthCondition} onClick={function (event) {
                                            event.preventDefault();
                                            //console.log(event); 
                                            let objHc = clone(selectedHC);
                                            let found = objHc.some(el => parseInt(el.hcid) === parseInt(event.target.dataset.hcid));
                                            //console.log('found',selectedHC,found);
                                            if (!found) objHc.push({ 'hcid': parseInt(event.target.dataset.hcid), 'hcname': event.target.dataset.hcname });
                                            setSelectedHC(objHc);
                                            // let hasChanged = JSON.stringify(objHc) !== JSON.stringify(selectedHC) ? true : false;
                                            // setSelectedHC(objHc);
                                            // if (hasChanged && props.onChangeConditions && typeof (props.onChangeConditions) === 'function') {
                                            //     //setUserInput(objUserInput);
                                            //     event.selectedHC = objHc;
                                            //     event.userInput = userInput;
                                            //     props.onChangeConditions(event);
                                            // }
                                        }}>{item.HealthCondition}</a></li>
                                )}
                                <li key={"less-more-"+Math.random()} className={(isHealth ? 'less' : 'more') + hideMoreHealthList('more-less')} onClick={healthClick}><span className="more-text">More..</span><span className="less-text">Less..</span></li>
                            </ul>

                        </div>
                        : <></>}
                </div>

                <div className="col-lg-12">
                    {props.listType === 'radiology-tests' ?
                        <div id="health_habits_01" className="health-habits">
                            <div className="health-heading"><i className="icon-filter-fill"></i> CATEGORIES:</div>
                            <ul id="health_list_01" className={isHealth ? 'health-list health-ellipsis-none' : 'health-list'}>
                                {radiologyCategories?.Items?.length > 0 && radiologyCategories.Items.map((item, idx) =>

                                    <li key={'RC' + idx}  >
                                        <NavLink to={'/radiology-test/' + sanitizeUrl(item.CategoryName)} activeClassName="active" onClick={() => selectUriRC(item)}  >{item.CategoryName}</NavLink>
                                    </li>
                                )}

                                <li  key={"less-more2-"+Math.random()} className={(isHealth ? 'less' : 'more') + hideMoreHealthList('more-less')} onClick={healthClick}><span className="more-text">More..</span><span className="less-text">Less..</span></li>
                            </ul>

                        </div>
                        : <></>}
                </div>


            </Row>

            <div className="clr height10" />
            <h4>{props.listType === 'packages' ? <>All Packages</> : (props.listType === 'top-offers' ? <>Top Offers</> : (props.listType === 'radiology-tests' ? <>All Radiology Tests</> : <>All Tests</>))}</h4>
            <div className="clr height10" />
            <div className="coustom-tabstyle">
                <ul className="tablist">
                    <li key={'tablist1'} ><NavLink to="/health-packages" activeClassName="active">Health Packages</NavLink></li>
                    <li key={'tablist2'}><NavLink to="/diagnostic-test" activeClassName="active">Diagnostic Tests</NavLink></li>
                    <li key={'tablist3'}><NavLink to="/radiology-test" activeClassName="active">Radiology Tests</NavLink></li>
                    {props.showTopOffers ? <li key={'tablist4'}><NavLink to="/top-offers" activeClassName="active">Top Offers <i className='icon-top-offers'></i></NavLink></li> : <></>}
                </ul>
                {props.listType !== 'top-offers' ? <>
                    <div className="input-group mb-3 search-style searchwidth">
                        <input type="text" className="form-control" name="SearchTestAndPackages" id="SearchTestAndPackages" placeholder="Search" aria-label="Search" aria-describedby="button-addon2"
                            value={userInput.SearchText} onKeyUp={actOnEnter} onChange={onChangeUserInput}
                        />
                        <button className="btn btn-outline-secondary" type="button" id="btnSearchTestAndPackages"
                            onClick={function (event) {
                                event.preventDefault();
                                let objParam = {};
                                objParam.selectedHC = selectedHC;
                                objParam.selectedRadioCat = selectedRadioCat;
                                objParam.userInput = userInput;
                                //console.log(" objParam ", objParam);
                                if (props.onChangeConditions && typeof (props.onChangeConditions) === 'function') {
                                    props.onChangeConditions(objParam);
                                }
                            }}
                        ><i className='icon-focus-lens' /></button>
                    </div>
                </> : <>
                    <button className="btn btn-outline-secondary d-none" type="button" id="btnSearchTestAndPackages"
                        onClick={function (event) {
                            event.preventDefault();
                            let objParam2 = {};
                            objParam2.selectedHC = selectedHC;
                            objParam2.selectedRadioCat = selectedRadioCat;
                            objParam2.userInput = userInput;
                            if (props.onChangeConditions && typeof (props.onChangeConditions) === 'function') {
                                props.onChangeConditions(objParam2);
                            }
                        }}
                    ><i className='icon-focus-lens' /></button>
                </>}
                <div className="clr" />
            </div>

            <VerticallyCenteredModal
                show={myModal.show}
                message={myModal.message}
                onHide={
                    () => {
                        if (typeof (myModal.fnCallBack) === 'function') {
                            myModal.fnCallBack();
                        } else {
                            setMyModal({ show: false, message: '' });
                        }
                    }
                }
            />
        </>
    )
}