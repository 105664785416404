import { useQuery } from "react-query";
import axiosConfig from "../pages/Home/HomeApiConfig";

function useFetch(request, payload, options) {
  //  Have to Share the Payload with an Object {payload: {Something: string}}
  const fetchFunction = async () => {
    const { data } = await  axiosConfig.get(Array.isArray(request) ? request[0] : request, payload);
    return data;
  };

  const { data, isLoading, isError, error, isFetching, refetch } = useQuery(
    // request.toString() + id.toString(),
    [Array.isArray(request) ? request[0].toString() : request.toString(), request[1]],
    fetchFunction,

    {
      staleTime: 1000,
      refetchOnWindowFocus: false,
      ...options,
    },
  );

  return { data, isLoading, isFetching, isError, error, refetch };
}

export default useFetch;
