import React from "react";
import { Container } from "react-bootstrap";
import { NavLink } from "react-router-dom";

function HomeStaticContent({ server }) {
  return (
    <>
      <Container>
        <div className="clr height30" />
        <div className="smallertex">HOW IT WORKS</div>
        <h2 className="heading-style">Simple Easy Steps</h2>
        <div className="clr height20" />
        <ul className="simple-easy-step">
          <li>
            <div className="step-block">
              <i className="icon-select-your-test" />
            </div>
            <div className="stap-heading">
              <span>Select your tests</span>
            </div>
            <p>Search Tests or Select from Popular Tests/Packages</p>
          </li>
          <li>
            <div className="step-block">
              <i className="icon-schedule" />
            </div>
            <div className="stap-heading">
              <span>Schedule</span>
            </div>
            <p>Schedule a Date and Time for the phlebotomist &amp; you for the Sample collection</p>
          </li>
          <li>
            <div className="step-block">
              <i className="icon-payment-process" />
            </div>
            <div className="stap-heading">
              <span>Process Payment</span>
            </div>
            <p>Do your payment in a Safe and Trusted Online Payment Environment. You get multiple options to pay.</p>
          </li>
          <li>
            <div className="step-block">
              <i className="icon-sample-collection" />
            </div>
            <div className="stap-heading">
              <span>Sample Collection</span>
            </div>
            <p>
              You may visit the Laboratory or our phlebotomist will visit your home to collect sample following all
              safety and hygiene protocols.
            </p>
          </li>
          <li>
            <div className="step-block">
              <i className="icon-download-report" />
            </div>
            <div className="stap-heading">
              <span>Download Reports</span>
            </div>
            <p>
              Reports at anytime, anywhere. Download Blood reports quickly and seamlessly in the same day or the other.
            </p>
          </li>
        </ul>
      </Container>

      <Container>
        <a name="equipments"></a>
        <div className="clr height30" />
        <div className="smallertex TEST-EQUIPMENTS-AT-GENU-PATHLABS">TEST EQUIPMENTS AT GENU PATHLABS</div>
        <h2 className="heading-style">Advanced Automated Equipments</h2>
        <div className="clr height20" />
        <ul className="equipments">
          <li>
            <div className="equipment-block">
              <div className="equipment-img">
                <img src="../images/equipment-01.png" className="img-fluid" alt="" />
              </div>
              <div className="equipment-content">
                <h3>COBAS Integra 400 plus of Roche</h3>
                <p>
                  Fully automated clinical chemistry analyser with extensive assay menu along with proven measurement
                  technologies and sophisticated software.
                </p>
              </div>
              <NavLink to="/equipments" className="btn btn-equipment">
                LEARN MORE <i className="icon-thin-right" />
              </NavLink>
            </div>
          </li>
          <li>
            <div className="equipment-block">
              <div className="equipment-img">
                <img src="../images/equipment-02.png" className="img-fluid" alt="" />
              </div>
              <div className="equipment-content">
                <h3>COBAS e41 1 of Roche</h3>
                <p>
                  Fully automated immunoassay analyser that uses latest technology providing the most accurate assay
                  results.
                </p>
              </div>
              <NavLink to="/equipments" className="btn btn-equipment">
                LEARN MORE <i className="icon-thin-right" />
              </NavLink>
            </div>
          </li>
          <li>
            <div className="equipment-block">
              <div className="equipment-img">
                <img src="../images/equipment-03.png" className="img-fluid" alt="" />
              </div>
              <div className="equipment-content">
                <h3>D10 of Bio-Rad</h3>
                <p>
                  Works on the principle of HPLC for highly accurate &amp; precise measurement of HBA1C and different
                  fractions of haemoglobin.
                </p>
              </div>
              <NavLink to="/equipments" className="btn btn-equipment">
                LEARN MORE <i className="icon-thin-right" />
              </NavLink>
            </div>
          </li>
          <li>
            <div className="equipment-block">
              <div className="equipment-img">
                <img src="../images/equipment-04.png" className="img-fluid" alt="" />
              </div>
              <div className="equipment-content">
                <h3>Easylyte Plus of Medica</h3>
                <p>
                  Electrolytes (Sodium, Potassium, Chloride) can be tested in less than 60 seconds using ISE method.
                </p>
              </div>
              <NavLink to="/equipments" className="btn btn-equipment">
                LEARN MORE <i className="icon-thin-right" />
              </NavLink>
            </div>
          </li>
        </ul>
        <div className="clr height20" />
      </Container>

      <Container>
        <div className="row content-text">
          <div className="col-lg-12 mb-2">
            <h2 className="heading-style">Diagnostic Center and Pathology Lab for Blood Test</h2>
            <div className="height10 clr" />
            <p>
              Welcome to Genu Path Labs, The trusted and reliable diagnostic center & pathology lab near you to book
              blood test online. Our pathology lab provides a comprehensive range of testing services, such as blood
              tests, radiology tests, and other diagnostic examinations that are used to identify and treat a number of
              medical disorders.{" "}
            </p>
          </div>
          <div className="col-lg-8">
            <h3 className="text-dark fw-normal">Genuine & Trusted Pathology Lab in WestBengal</h3>

            <p>
              Our pathology lab has several patient service centers across West Bengal. There are a number of qualified
              and experienced professionals associated with us who are dedicated to giving you the best services
              possible. All our testing procedures are automated and state-of-the-art, assuring reliable and accurate
              findings that are important to direct the course of your treatment along with improving your health
              outcomes.
            </p>

            <p>
              At Genu Path Labs, we are dedicated to providing the best level of care and attention to each and every
              one of our patients. This is why we provide same-day appointments and walk-in services, ensuring that you
              can receive the testing you require when it is most convenient for you. Also, we provide free home
              collections of your blood, urine, and stool samples. It gives you respite from traveling all the way to
              our diagnostic center.
            </p>

            <p>
              Your health and well-being are our main priorities at Genu Path Labs. To book blood test or to find out
              more about our services, get in touch with our diagnostic center right now.{" "}
            </p>
          </div>
          <div className="col-lg-4">
            <img
              src={server.cdn_url + "/images/home-pathology-image1.jpg"}
              alt="diagnostic center"
              title="diagnostic center"
              className="w-100 rounded-4"
            />
          </div>
          <div className="clr height10" />
          <div className="col-lg-4">
            <img
              src={server.cdn_url + "/images/home-pathology-image2.jpg"}
              alt="book online blood test"
              title="book online blood test"
              className="w-100 rounded-4"
            />
          </div>
          <div className="col-lg-8">
            <h3 className="text-dark fw-normal">What Makes Genu Path Labs the Right Choice for Your Home Lab Tests?</h3>

            <p>
              Too preoccupied or having time issues to visit the lab for your medical tests? With Genu Path Labs home
              lab testing, now you can easily get your required pathology tests simply sitting in the comfort of your
              own home.
            </p>

            <p>
              Our lab testing at home service is simple, feasible, and absolutely affordable. Once you book our test
              slot, a qualified and experienced phlebotomist from our team of skilled medical experts will visit your
              house to collect the necessary blood samples and bring them to our lab for examination. At Genu Path Labs,
              we provide a variety of lab tests, including blood tests, urine tests, stool tests. We make use of modern
              and advanced equipment and 200+ quality check procedures to guarantee the accuracy and reliability of all
              of our diagnostic test results.
            </p>
          </div>
          <div className="clr height10" />
          <div className="col-lg-12">
            <h3 className="text-dark fw-normal">
              Avail Home Collection and Accurate Report Delivery with Genu Path Labs
            </h3>

            <p>
              We at Genu Path Labs are aware of how difficult and time-consuming it may be to wait for your medical test
              results. We ensure proper sample collection services and blood test online report delivery system so that
              you can get your diagnostic test results properly and on time. While we deliver most of the test results
              on the same day, some may take up to 48 hours.{" "}
            </p>

            <p>
              Our team of skilled experts including qualified doctors, efficient lab professionals, and experienced{" "}
              <a
                href={
                  "https://main.mohfw.gov.in/sites/default/files/Short%20Term%20Training%20Curriculum%20Handbook_Phlebotomist_1%20June%202017_0.pdf"
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                phlebotomists
              </a>{" "}
              are all committed to providing you with top-quality pathology testing services that are of the highest
              caliber, accuracy, and reliability. To make sure that our diagnostic testing procedures are precise and
              credible, we conduct quality checks and employ state-of-the-art equipment and processes. Once your samples
              have been received, we work thoroughly on that to analyze them and send the test findings to you.
            </p>

            <h3 className="text-dark fw-normal">Genu Path Labs Offers Great Value For Money</h3>

            <p>
              We at Genu Path Labs are aware that paying for healthcare services can be challenging and burdensome for
              many people in general. We are dedicated to offering our patients first-class, reasonably priced
              diagnostic pathology testing services that actually deliver high quality healthcare at low cost.
            </p>

            <p>
              We provide cost transparency and affordable rates for all of our services. We assure you of the right and
              transparent fees for each and every service since we do not believe in hidden fees or unexpected expenses.
              Additionally, you may save money on your testing services by taking advantage of our range of test
              packages and discounts. To assist you in selecting the right package that satisfies your healthcare needs
              at the most affordable price our team is always readily available.
            </p>

            <p>
              By opting for Genu Path Labs, you are selecting a lab that provides high-quality diagnostic services,
              outstanding customer service, and true value for your money. Book an appointment with us right now to see
              the difference for yourself.{" "}
            </p>

            <h3 className="text-dark fw-normal">Qualified & Skilled Lab Technicians with Years of Experience</h3>
            <p>
              At Genu Path Labs, we take great pleasure in our group of very talented and well-trained lab
              professionals. Our team of specialists has received extensive training and education to ensure that they
              are up to date on the latest techniques and advancements in the field of medical testing.
            </p>

            <p>
              The phlebotomists, trained and qualified by our NABL-accredited Genu Path labs, go to your home and
              collect the samples in a clean, sanitized, and hygienic way. The best-in-class pathology services are
              delivered to your house by Genu Path Labs for all of your diagnostic needs. They put forth a lot of effort
              to make sure that all samples are collected, prepared, and examined quickly and effectively
            </p>

            <p>
              Being a best diagnostic center, our team of lab experts has the skills and understanding to provide
              reliable and accurate findings whether you require a simple blood test or a more extensive diagnostic
              procedure.{" "}
            </p>

            <h3 className="text-dark fw-normal">Need Your Lab Test? Genu Path Labs are Always at Your Service</h3>

            <p>
              Healthcare today is not complete without proper diagnosis as it is important to check all our vital
              parameters from time to time. Lab tests offer important health-related information and direct your
              treatment strategy. Lab tests are the primary stage in a preventative condition to identify early signs of
              adverse health conditions that may later become serious health difficulties.
            </p>

            <h3 className="text-dark fw-normal">You could require a lab test for the following typical causes:</h3>
            <div className="height5 clr" />
            <div className="d-center">
              <h4 className="textorange">1. To identify a medical condition:</h4>
              <p>
                {" "}
                Lab tests can assist your doctor in identifying a variety of illnesses, including diabetes, infections,
                high cholesterol, etc.
              </p>
            </div>

            <div className="d-center">
              <h4 className="textorange">2. To keep track of a chronic condition:</h4>
              <p>
                {" "}
                If you suffer from a chronic health condition like hypertension or renal disease, lab testing can help
                your doctor keep track of it and modify your treatment plan as necessary.
              </p>
            </div>

            <div className="d-center">
              <h4 className="textorange">3. To assess your general health:</h4>
              <p>
                {" "}
                Lab tests can offer valuable information about your general health, even if you feel in good shape.
                Regular examinations, such as a complete blood count (CBC) or lipid panel, can aid in spotting potential
                health issues before they worsen.
              </p>
            </div>

            <div className="d-center">
              <h4 className="textorange">4. Medication monitoring:</h4>
              <p>
                If you are taking medication to treat a medical issue, lab testing can assist your healthcare
                practitioner in monitoring the medication's effects and reactions in your body.
              </p>
            </div>
            <div className="d-center">
              <h4 className="textorange">5. To determine your risk for certain health conditions:</h4>
              <p>
                {" "}
                Some lab tests can be used to determine your risk for specific ailments like cancer or heart disease. If
                you possess other risk factors, such as a family history of health adversities, your doctor may advise
                you to have these tests.
              </p>
            </div>

            <p>
              We provide a wide range of laboratory tests under one roof, making it simple and convenient for you to get
              all the testing services you want. We provide a variety of diagnostic pathological services, such as:
            </p>
            <ul>
              <li className="mb-2 ">
                <h2 className="textdark">Blood Tests:</h2> We provide a variety of blood tests, such as{" "}
                <a
                  href={"https://www.genupathlabs.com/test/COMPLETE-BLOOD-COUNT-CBC"}
                  target="_blank"
                  title="Complete Blood Count"
                  rel="noopener noreferrer"
                >
                  Complete Blood Count
                </a>
                , Glucose Fasting & PP,{" "}
                <a href={"/test/lipid-profile"} target="_blank" title="Lipid Profile" rel="noopener noreferrer">
                  Lipid Profile
                </a>
                ,{" "}
                <a
                  href={"https://www.genupathlabs.com/test/cholesterol"}
                  target="_blank"
                  title="Cholesterol Tests"
                  rel="noopener noreferrer"
                >
                  Cholesterol Tests
                </a>
                , etc.
              </li>
              <li className="mb-2 ">
                <h2 className="textdark">Urine Tests:</h2> We provide urine tests to check for a number of health
                conditions, including kidney problems, urinary tract infections, etc.
              </li>
              <li className="mb-2 ">
                <h2 className="textdark">Radiology Tests:</h2> To diagnose and track a variety of medical disorders, we
                provide a range of radiology tests, like{" "}
                <a
                  href={"https://www.genupathlabs.com/radiology-test/x-ray"}
                  target="_blank"
                  title="X-rays"
                  rel="noopener noreferrer"
                >
                  X-rays
                </a>
                , ultrasounds, ECG, 2D ECHO Cardiography, Colour Doppler, etc.
              </li>
            </ul>
            <p>
              At Genu Path Labs, we recognize that every patient is different, which is why we provide individualized
              testing services catered to your particular requirements. Our team of specialists is dedicated to giving
              you results that are accurate, precise, fast, and effective. Whether you want a simple blood test or a
              more intricate diagnostic procedure, we have the sufficient equipment and expertise needed to offer you
              all of your testing services.{" "}
            </p>
          </div>
        </div>
      </Container>
    </>
  );
}

export default HomeStaticContent;
