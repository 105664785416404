import React, { useState, useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import { NavLink, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import BreadCrumb from "./BreadCrumb";
import { getConfigData, clone, sanitizeUrl } from "../services/Helper";
import { getAjaxPage, PostData } from "../services/SendData";

const queryStarted = {
    pageContent: { list: false, timeout: null },
    pageDetails: { list: false, timeout: null },
    initCall: true,
};

var initData = {
    pageDetails: { data: {}, seoInfo: {}, isDarty: true, altMessage: "" },
};

function StaticBlankPage() {
    const configData = getConfigData();
    const { pathname } = useLocation();
    const [pageDetails, setPageDetails] = useState(initData.pageDetails);

    //additionalPageContent

    const getPageContent = (param) => {
        //fnFeedBack, showLoder, force
        // ------------ initialization ------------
        param = typeof param !== "undefined" ? param : {};
        param.showLoder = false; // typeof (param.showLoder) !== 'undefined' ? param.showLoder : false;
        param.force = typeof param.force !== "undefined" ? param.force : false;
        param.pageNumber =
            typeof param.pageNumber !== "undefined" ? param.pageNumber : 1;
        param.fnFeedBack = function (result) {
            queryStarted.pageContent.list = false;
            //console.log('param.fnFeedBack/result::', result);
            document.getElementById("additional-page-content").innerHTML =
                result;
        };
        // ------------ handeling force calling ------------
        if (queryStarted.pageContent.list) {
            if (param.force) {
                queryStarted.pageContent.timeout = window.setTimeout(
                    function () {
                        getPageContent(param);
                    },
                    1500
                );
            }
            return false;
        }

        if (queryStarted.pageContent.timeout) {
            window.clearTimeout(queryStarted.pageContent.timeout);
        }

        var apiSegment = "/additionalPageContent" + pathname;
        // apiSegment += '?page=' + param.pageNumber;

        // queryStarted.pageContent.list = true;
        getAjaxPage(apiSegment, {}, param.fnFeedBack, configData);
        return false;
    };

    const getServiceData = (param) => {
        //fnFeedBack, showLoder, force
        // ------------ initialization ------------
        param = typeof param !== "undefined" ? param : {};
        param.showLoder =
            typeof param.showLoder !== "undefined" ? param.showLoder : false;
        param.force = typeof param.force !== "undefined" ? param.force : false;
        param.fnFeedBack =
            typeof param.fnFeedBack !== "undefined" ? param.fnFeedBack : null;
        param.pageNumber =
            typeof param.pageNumber !== "undefined" ? param.pageNumber : 1;
        //param.healthCodition = typeof (param.healthCodition) !== 'undefined' ? param.healthCodition : [];
        //console.log('packageDetails:-' ,'listBooking.isDarty:', listBooking.isDarty, 'force', param.force);
        if (!pageDetails.isDarty && !param.force) {
            return false;
        }
        //  listBooking, setListBooking
        // ------------ haldeling force &&  ------------

        if (queryStarted.pageDetails.list) {
            if (param.force) {
                queryStarted.pageDetails.timeout = window.setTimeout(
                    function () {
                        getServiceData(param);
                    },
                    1500
                );
            }
            return false;
        }

        if (queryStarted.pageDetails.timeout) {
            window.clearTimeout(queryStarted.pageDetails.timeout);
        }

        var apiSegment = "/additionalPageContent" + pathname;
        queryStarted.pageDetails.list = true;

        //console.log('packageDetails:-', 'apiSegment ', apiSegment);
        PostData(apiSegment, { DataOnly: 1 }, configData, param.showLoder).then(
            (result) => {
                var objPkgs = clone(pageDetails);
                objPkgs = result?.data?.data ? result?.data?.data : {}; //clone(result);
                objPkgs.seoInfo = result?.data?.seoInfo;
                objPkgs.isDarty = false;
                objPkgs.altMessage = objPkgs?.ServiceDetails
                    ? ""
                    : "No Health Package details to display.";
                setPageDetails(objPkgs);

                // "": {
                //   "metaDescription": "Book Family-Health-Package online from NABL accredited lab near you. Online report available.",
                //   "pageTitle": "Book Family-Health-Package Online at Low Price Near You",
                //   "canonical": "https:\/\/www.genupathlabs.com\/health-packages\/Family-Health-Package",

                if (typeof param.fnFeedBack === "function") {
                    param.fnFeedBack();
                }
            }
        );
        return false;
    };

    useEffect(() => {
        getPageContent();
        getServiceData();
    }, []);

    return (
        <>
            {pageDetails?.seoInfo && (
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta
                        name="description"
                        content={pageDetails?.seoInfo?.metaDescription || 'Book online blood tests from the best diagnostic center and pathology labs in West Bengal. Get  blood tests, health packages, etc  at attractive prices &amp; discounts'}
                    ></meta>
                    <meta property="og:image" data-rh="true" content={pageDetails?.seoInfo?.og_image || 'https://res.genupathlabs.com/genu_path_lab/live/customer_V2/images/genu-logo.png'} />
                    <meta name="twitter:card" data-rh="true" content={pageDetails?.seoInfo?.og_image || 'https://res.genupathlabs.com/genu_path_lab/live/customer_V2/images/genu-logo.png'} />
                    <title>{pageDetails?.seoInfo?.pageTitle || 'Book Online Blood Test | NABL Accredited | Genu Path Labs'}</title>
                    <link
                        rel="canonical"
                        href={pageDetails?.seoInfo?.canonical || 'https://www.genupathlabs.com/'}
                    />
                </Helmet>
            )}
            &nbsp;
        </>
    );
}

export default StaticBlankPage;
