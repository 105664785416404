import { useContext, useState } from "react";
import { cartContext } from "../services/userHandler";
import { IsThisItemSelected } from "../services/Helper";
import { PostData } from "../services/SendData";
import { getConfigData, clone, countCartItems, VerticallyCenteredModal } from "../services/Helper";
import InitSearchTest from "../services/InitSearchTest.json";

/**
 * @param {product} productInfo array (productInfo)
 * @param {className} className string (Name of the Class)
 * @param {btnBookNow} btnBookNow string (title of BookNow)
 * @returns component
 */
const BookNowButton = ({ product, className, btnBookNow }) => {
  const configData = getConfigData();
  const { cartState, cartDispatch } = useContext(cartContext);
  const [myModal, setMyModal] = useState({
    show: false,
    message: "",
    fnCallBack: null,
  });

  // const handelCustomerLocation = (event) => {
  //   setMyModal({
  //     show: false,
  //     message: "",
  //     fnCallBack: function () {
  //       document.getElementById("elePatientLocation").click();
  //     },
  //   });
  // };

  const lnkBookATest = ({ actionType, product }) => {
    let apiSegment = "/loadData/submitPage/" + actionType;

    let LabId = product?.LabId;
    let ServiceId = product?.ServiceId;
    let Fees = product?.Fees;
    let RefBookingId = product?.RefBookingId;

    if (!ServiceId) {
      return false;
    }

    // let patient_pincode = document.getElementById("elePatientLocation").getAttribute("data-patient_pincode");
    // if (typeof patient_pincode === "undefined" || !patient_pincode || patient_pincode === "") {
    //   setMyModal({
    //     show: true,
    //     message: "Please select your location for Sample Collection",
    //     fnCallBack: function () {
    //       handelCustomerLocation();
    //     },
    //   });
    //   return false;
    // }

    PostData(
      apiSegment,
      {
        senddata: {
          LabId: LabId,
          ServiceId: ServiceId,
          Fees: Fees,
          RefBookingId: RefBookingId
        },
      },
      configData,
    ).then((result) => {
      let jResponse = actionType === "addToCart" ? result.response : result;
      console.log(actionType, jResponse);
      if (jResponse.status && jResponse.status === 1) {
        let resData = actionType === "delCart" ? jResponse.data.data : jResponse.data;
        // -- update CartData (show selected ) and update cart-count
        var objCartList = typeof resData.Cart[0] !== "undefined" ? clone(resData.Cart[0]) : InitSearchTest.CartList;
        if (resData.CartItem && resData.CartItem.length > 0) {
          objCartList.CartItem = clone(resData.CartItem);
          objCartList.TotalCartItems = countCartItems(resData.CartItem); // resData.CartItem.length;
        }
        cartDispatch({ type: "CART", payload: objCartList });
      } else if (jResponse.message && jResponse.status === -5) {
        setMyModal({
          show: true,
          message: jResponse.message,
          fnCallBack: () => {
            window.setTimeout(function () {
              window.location.reload(true);
            }, 5000);
          },
        });
      }else if (jResponse.message) {
        setMyModal({
          show: true,
          message: jResponse.message

        });
      }
      return true;
    });
  };


  return (
    <>
      <button
        onClick={() => {
          let actionType = IsThisItemSelected(product, cartState) ? "delCart" : "addToCart";
          lnkBookATest({ actionType, product });
        }}
        className={className ? IsThisItemSelected(product, cartState, 1) ? (
          IsThisItemSelected(product, cartState, 2) === 1 ? (
            ` ${className} selected`
          ) : (
            `${className} already-added`
          )
        ) : (
          className
        ) : `btn book-btn ${IsThisItemSelected(product, cartState, 1) ? (
          IsThisItemSelected(product, cartState, 2) === 1 ? (
            "selected"
          ) : (
            "already-added"
          )
        ) : (
          " "
        )
        }`}
      >
        {IsThisItemSelected(product, cartState, 1) ? (
          IsThisItemSelected(product, cartState, 2) === 1 ? (
            "SELECTED"
          ) : (
            <span>You have already added a test of this package</span>
          )
        ) : (
          btnBookNow || "Book Now"
        )}
      </button>
      <VerticallyCenteredModal
        show={myModal.show}
        message={myModal.message}
        onHide={() => {
          if (typeof myModal.fnCallBack === "function") {
            myModal.fnCallBack();
          } else {
            setMyModal({ show: false, message: "" });
          }
        }}
      />
    </>
  );
};

export default BookNowButton;
