import * as mnu from "../sections/NavSections";
import { toggleMenu } from "../services/Helper";
function Menu(props) {

  //const userDataEx =  useContext(userContext); // props.userData;
  //const [userData, setUserData] = useReducer(reducer, props.userData);
  const userData = {};
  const configData = props.configData;
  const server = props.server;
  const initData = props.initData;


  return (
    <section className="Menu">
      <div className="menu-overlay" onClick={toggleMenu} />
      <ul className="genu-menu">
        {/* <li><NavLink to="/">Home </NavLink></li> */}
        <mnu.PopularTests userData={userData} configData={configData} server={server} initData={initData} />
        {/* <mnu.PopularPackages userData={userData} configData={configData} server={server} initData={initData} /> */}
        <mnu.HealthConditions userData={userData} configData={configData} server={server} initData={initData} />
        <mnu.BookByPrescription />
        <mnu.Reports userData={userData} configData={configData} server={server} initData={initData} />
        <li><a href={'/health-article'}>Health Article</a></li>
        <mnu.Social />
      </ul>
    </section>
  );
}

export default Menu;