import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getNewPermalink } from "../../../services/Helper";
import BookNowButton from "../../../sections/BookNowButton";
import SectionTitle from "./SectionTitle";
import useFetch from "../../../Hook/useFetch";
import { HomePageApiUrl } from "../HomeApiConfig";

function HomeAllPackages({ server, subtitle, title, viewAllLink }) {
  const maxIncludesItems = 5;
  const { popularPackage } = HomePageApiUrl;
  const { data: { data: popularPackages, TotalPackageCount } = {} } = useFetch(popularPackage);

  return (
    <Container>

      <SectionTitle subtitle={subtitle} title={title} viewAllLink={viewAllLink} />

      <div className="row pad10m">
        <div className="col-lg-3 margin-b20">
          <div className="tests-block more-pkg">
            <div className="more-test">
              <div className="more-test-block">
                {(TotalPackageCount - popularPackages?.length) > 0 ?
                  <div className={popularPackages?.length > 0 ? "test-value " : "test-value fuzzy"}>
                    {TotalPackageCount - popularPackages?.length}
                  </div>
                  : null
                }
                <p>More packages</p>
                <Link to="/health-packages" className="btn btn-viewall">
                  VIEW ALL <i className="icon-thin-right" />
                </Link>
              </div>
            </div>
          </div>
        </div>
        {popularPackages?.length > 0 ? (
          popularPackages?.map((item, ndx) => (
            <div  key={"allPackages-" + item.ServiceId} className="col-lg-3 margin-b20">
              {/* <div className={IsThisItemSelected(item, cartState) ? (IsThisItemSelected(item, cartState) === 2 ? "tests-block selected included" : "tests-block selected") : "tests-block"} > */}
              <div className="tests-block">
                <div className="pkg-img">
                  {typeof item.IsYana !== "undefined" && item.IsYana ? (
                    <div className="free-diet">Diet Consultation Included</div>
                  ) : (
                    <></>
                  )}
                  <div
                    className={"proff" + (item.DiscPercent || item.DiscAmount || item.DiscountPercent ? "" : " d-none")}
                  >
                    {item.DiscPercent ? (
                      <>
                        {item.DiscPercent}% <span>Off</span>
                      </>
                    ) : item.DiscountPercent ? (
                      <>
                        {item.DiscountPercent}% <span>Off</span>
                      </>
                    ) : item.DiscAmount ? (
                      <>
                        ₹{item.DiscAmount} <span>Off</span>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  <img src={item.serviceImageUrl || item.NewServiceShortHomeImage} alt={item.ServiceName} className="w-100" />
                </div>
                <div className="pkg-body">
                  <div className="rating-box" title="4.45 stars">
                    <div className="rating" style={{ width: "90%" }} />
                    &nbsp;
                  </div>
                  <div className="pkg-heading">
                    <Link to={getNewPermalink(item, true, "self")}>{item.ServiceName}</Link>
                  </div>
                  {/* <div className="total-test">TOTAL - {item.PackageServices.length} TESTS</div> */}
                  {/* <div className="including">including {item.includes}</div> */}
                  {item?.PackageServices?.length > 0 && <div className="including">including &nbsp;
                    {item?.PackageServices?.map((service, i) => <span skey={"including-" + i} key={"including-" + i}>{i <= maxIncludesItems ? <>{i > 0 ? <>,&nbsp; </> : ''}{service?.ServiceName}</> : ''}</span>)}
                    {/* {item?.PackageServices?.length > maxIncludesItems ? <strong> &nbsp;  and {item?.PackageServices?.length - maxIncludesItems} more test{item?.PackageServices?.length - maxIncludesItems > 1 ? 's' : ''}.</strong> : ''} */}
                    {item?.PackageServices?.length > maxIncludesItems ? <strong> &nbsp;  and more...</strong> : ''}
                  </div> || <></>}
                  <div className="clr height10" />
                  {/* {item?.IsHomeCollectionAvailable ? <div className="service-text"><i className="icon-home2" /> {item?.HomeCollectionText ? item.HomeCollectionText : <>Home Collection Available</>}</div> : <></>} */}
                  {item?.ServiceText && <div className="service-text">&#x2733; {item.ServiceText}</div>}
                </div>
                <div className="price-button-section">
                  <div className="priceblock">
                    <del className={item.Fees <= item.OfferFees ? " d-none" : ""}>₹{item.Fees}</del>{" "}
                    <span className="mrp">₹{item.OfferFees}</span>
                  </div>

                  <BookNowButton product={item} className="btn booktest" />

                  <div className="clr height10" />
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="col-lg-9">
            <div className="row">
              {[...Array(3)].map((item, index) => (
                <LoadingBlock key={"allPackages-Loading" + index} server={server} />
              ))}
            </div>
          </div>
        )}
      </div>
    </Container>
  );
}

export default HomeAllPackages;

const LoadingBlock = ({ server }) => {
  return (
    <div className="col-lg-4 margin-b20 fuzzy">
      <div className="tests-block">
        <div className="pkg-img">
          <div className="proff">
            70% <span>Off</span>
          </div>
          <img
            src={
              server.cdn_url.replace("live/customer_V2", "live/customer_V1_2018_04_13/images/health-package/") +
              "/General-Health-Check-Up-Package-Home.jpg"
            }
            alt="General Health Check Up Package - Home Collection"
            className="w-100"
          />
        </div>
        <div className="pkg-body">
          <div className="rating-box" title="4.45 stars">
            <div className="rating" style={{ width: "90%" }} />
            &nbsp;
          </div>
          <div className="pkg-heading">General Health Check Up Package - Home Collection</div>
          <div className="total-test">TOTAL - 11 TESTS</div>
          <div className="including">including BILIRUBIN TOTAL, CHOLESTEROL, CREATININE +more</div>
          <div className="clr height20" />
          <div className="priceblock">
            <del>₹2260</del> <span className="mrp">₹700</span>
          </div>
          <button className="btn booktest"> Book Now </button>
          <div className="clr height10" />
        </div>
      </div>
    </div>
  );
};
