import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ".././../assets/scss/Home.scss";
import { useState, useContext } from 'react';
import { Helmet } from "react-helmet";
import { VerticallyCenteredModal } from "../../services/Helper";
import { userContext } from "../../services/userHandler";

// Local Components
import HomePageBannersArea from "./HomeComponents/HomePageBanners";
import HomePopularTests from "./HomeComponents/HomePopularTests";
import HomeRadiologyTestBanner from "./HomeComponents/HomeRadiologyTestBanner";
import HomeBestPackages from "./HomeComponents/HomeBestPackages";
import HomeStaticContent from "./HomeComponents/HomeStaticContent";
import HomeTestByOrgan from "./HomeComponents/HomeTestByOrgan";
import HomeAllPackages from "./HomeComponents/HomeAllPackages";
import HomeBookAgain from "./HomeComponents/HomeBookAgain";


function Home(props) {
  const server = props.server;
  const configData = props.configData;
  const [myModal, setMyModal] = useState({ show: false, message: "" });
  const { userState, userDispatch } = useContext(userContext);

  return (
    <section className="Home section-home">
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Book online blood tests from the best diagnostic center and pathology labs in West Bengal. Get  blood tests, health packages, etc  at attractive prices &amp; discounts"
        ></meta>
        <meta property="og:image" data-rh="true" content={'https://res.genupathlabs.com/genu_path_lab/live/customer_V2/images/genu-logo.png'} />
        <meta name="twitter:card" data-rh="true" content={'https://res.genupathlabs.com/genu_path_lab/live/customer_V2/images/genu-logo.png'} />
        <title>Book Online Blood Test | NABL Accredited | Genu Path Labs</title>
      </Helmet>
      <a name="WhyGenu"> </a>

      <HomePageBannersArea configData={configData} server={server} />

      {userState.isLoggedIn && <HomeBookAgain configData={configData} server={server} />}


      <HomePopularTests subtitle="CATEGORIES" title="Popular Tests" server={server} viewAllLink="/diagnostic-test" />

      <HomeRadiologyTestBanner server={server} />

      <HomeBestPackages subtitle="PACKAGES" title="Best Packages" server={server} />

      <HomeTestByOrgan subtitle="ORGANS" title="Tests by Organ" server={server} />

      <HomeAllPackages subtitle="PACKAGES" title="All Packages" server={server} />

      <HomeStaticContent server={server} />


      <VerticallyCenteredModal
        show={myModal.show}
        message={myModal.message}
        onHide={() => {
          if (typeof myModal.fnCallBack === "function") {
            myModal.fnCallBack();
          } else {
            setMyModal({ show: false, message: "" });
          }
        }}
      />
    </section>
  );
}

export default Home;
